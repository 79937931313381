import React, { useContext, useState } from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ReactComponent as ArrowCornerBottom } from "../../constants/assets/shared/arrowCornerBottomBlue.svg";
import {
  PremiosItems,
  PremiosItemss,
} from "../HomeContainers/styles/PremiosContainerStyles";
import COLORS from "../../styles/global/colors";
import { ButtonComponent } from "../../components/globals/Button";
import { orderObjectsArrayByNumber } from "../../utils/arrays";
import { LanguageContext } from "../../contexts/LanguageContext";

export const PremiosContainer = () => {

  const { dictionary } = useContext(LanguageContext);
  const {
    premiosContent
  } = dictionary.sharedContent;
  const [currentArrayIndex, setCurrentArrayIndex] = useState(0);

  if (!premiosContent) return null;

  const { textContent, items } = premiosContent;

  const handleNextArrayClick = () => {
    setCurrentArrayIndex((currentArrayIndex + 1) % Math.ceil(items.length / 6));
  };

  const startItemIndex = currentArrayIndex * 6;
  const endItemIndex = Math.min(startItemIndex + 6, items.length);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ background: COLORS.white, width: "100%", padding: "40px 0px" }}
    >
      <Hidden mdDown>
        <Grid
          item
          xs={10}
          style={{
            color: COLORS.blueTan,
            height: "78px",
            width: "100%",
            marginLeft: "-6%",
          }}
        >
          <Typography style={{ fontSize: "18px" }}>
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          item
          xs={10}
          style={{ color: COLORS.blueTan, height: "78px", width: "100%" }}
        >
          <Typography style={{ fontSize: "18px" }}>
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid
          item
          xs={5}
          style={{ width: "100%", marginBottom: "20px", marginLeft: "-55%" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%" }}
          >
            <Grid item xs={12} style={{ display: "flex" }}>
              {orderObjectsArrayByNumber(items, "position")
                .slice(startItemIndex, endItemIndex)
                .map((particularItem) => (
                  <Grid
                    item
                    xs={12}
                    md={1}
                    key={`Premios_${startItemIndex + particularItem.position}`}
                    style={{
                      height: "100px",
                      margin: "25px",
                      marginLeft: "18%",
                    }}
                  >
                    <a
                      href={particularItem.url}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <PremiosItems>
                        <img
                          src={particularItem.source}
                          alt={particularItem.alt}
                        ></img>
                      </PremiosItems>
                      <PremiosItems
                        variant="body1"
                        style={{ color: COLORS.blueTan }}
                      >
                        {particularItem.title}
                      </PremiosItems>
                      <PremiosItemss
                        variant="body1"
                        style={{ color: COLORS.blueTan }}
                      >
                        {particularItem.description}
                      </PremiosItemss>
                    </a>
                  </Grid>
                ))}
              <Grid
                item
                sx={8}
                md={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "130px",
                  marginBottom: "150px",
                  marginLeft: "10%",
                }}
              >
                <ButtonComponent
                  type="secondary"
                  margin="10px"
                  colorText={COLORS.blueTan}
                  showIconRight
                  callToAction={() => {
                    handleNextArrayClick(currentArrayIndex);
                  }}
                >
                  <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
                </ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};
