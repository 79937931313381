import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { ImageStyleds } from "../styles/AboutViewStyles";

const mediaChildren = ({ source, alt }) => {
  return (
    <ImageStyleds>
      <img src={source} alt={alt} />
    </ImageStyleds>
  );
};

const textBoxChildren = ({ upperTitle, description, iconUpperTitle }) => {
  const colorText = COLORS.blueTan;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      style={{ margin: "20px 0px" }}
    >
      <Grid item xs={10} md={11} style={{ marginBottom: 30 }}>
        <Typography
          variant="body1"
          style={{
            fontSize: "20px",
            color: colorText,
          }}
        >
          {upperTitle}
          {iconUpperTitle}
        </Typography>
      </Grid>
      <Grid item xs={10} md={11}>
        <Typography
          variant="body1"
          style={{
            color: colorText,
            fontSize: 22,
            lineHeight: 1.5,
            width: '95%'
          }}
        >
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const AboutBody = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
    />
  );
};
