import React from "react";
import { Hidden } from "@material-ui/core";

export const InstaPost = ({ link, backgroundimg }) => {
  return (
    <>
      <Hidden mdDown>
        <a href={link} target="_blank" rel="noreferrer" style={{width: '100%'}}>
          <div
            style={{
              height: '25vw',
              background: `url(${backgroundimg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </a>
      </Hidden>
      <Hidden mdUp>
      <a href={link} target="_blank" rel="noreferrer">
          <div
            style={{
              height: 250,
              width: 250,
              background: `url(${backgroundimg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </a>
      </Hidden>
    </>
  );
};
