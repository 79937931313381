import React from "react";
import { ProyectBody } from "./ProyectBody";
import { ProyectTitle } from "./ProyectTitle";

export const ProyectContainer = ({content = undefined}) => {
  if(!content) return null;

  const {titleContent, bodyContent } = content
  return (
    <>
      <ProyectTitle content={titleContent} />
      <ProyectBody content={bodyContent} />
    </>
  );
};
