import COLORS from "../../../styles/global/colors";
import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import FotoPressPeru from "../../../constants/assets/shared/news/FotoPressPeru.png";
import News1 from "../../../constants/assets/shared/news/News1.jpeg";
import FotoPaisCircular from "../../../constants/assets/shared/news/FotoPaisCircular.jpeg";
import FotoNoticiaPaz from "../../../constants/assets/shared/news/FotoNoticiaPaz.jpeg";
import Fotonoticiac13 from "../../../constants/assets/shared/news/Fotonoticiac13.png";
import FotomuralesparaChile from "../../../constants/assets/shared/news/FotomuralesparaChile.webp";
import FotoLaTercera from "../../../constants/assets/shared/news/FotoLaTercera.jpeg";
import FotoInversionista from "../../../constants/assets/shared/news/FotoInversionista.jpeg";
import FotoInnovaspain from "../../../constants/assets/shared/news/FotoInnovaspain.webp";
import FotoInfogate from "../../../constants/assets/shared/news/FotoInfogate.jpeg";
import FotoDiarioFinanciero from "../../../constants/assets/shared/news/FotoDiarioFinanciero.jpeg";

const newsTitle = {
  textContent: {
    upperTitle: "Noticias",
    iconUpperTitle: (
      <ArrowCornerBottom
        style={{ height: 12, marginLeft: 10, color: COLORS.white }}
      />
    ),
  },
};

const news = {
  NoticiasOne: [
    {
      description: "Photio en mayor mural descontaminante de Perú",
      link: "https://pressperu.com/street-art-conoce-el-mural-descontaminante-mas-grande-de-peru-en-el-ibis-lima-larco-miraflores/",
      backgroundimg: FotoPressPeru,
    },
    {
      description: "Photio ganador de premio Avonni 2022",
      link: "https://portalinnova.cl/ganadores-premios-avonni-2022/",
      backgroundimg: News1,
    },
    {
      description: "Photio ganador de desafío emprendedor",
      link: "https://www.paiscircular.cl/industria/freshwater-y-photio-las-tecnologias-sustentables-para-producir-agua-y-descontaminar-ciudades-ganadoras-del-6-concurso-nacional-desafio-emprendedor/",
      backgroundimg: FotoPaisCircular,
    },
  ],
  NoticiasTwo: [
    {
      description: "Photio en alianza con Inmobiliaria Paz",
      link: "https://g5noticias.cl/2023/01/15/paz-corp-incorpora-pintura-purificadora-de-aire-en-fachada-de-sus-proyectos/",
      backgroundimg: FotoNoticiaPaz,
    },
    {
      description:
        "Startup chilena busca combatir la contaminación mediante aditivo nanotecnológico",
      link: "https://www.t13.cl/noticia/emprendedores/startup-chilena-lanzo-innovador-aditivo-incorporar-diferentes-materiales-y-capturar-gases",
      backgroundimg: Fotonoticiac13,
    },
    {
      description: "Photio en Murales para Chile",
      link: "https://www.diariosustentable.com/2023/01/murales-para-chile-cierra-un-positivo-2022-con-10-obras-inauguradas-desde-su-puesta-en-marcha/",
      backgroundimg: FotomuralesparaChile,
    },
  ],
  NoticiasThree: [
    {
      description: "Chilenos crean tecnología descontaminante innovadora",
      link: "https://www.latercera.com/que-pasa/noticia/como-mil-arboles-chilenos-crean-pintura-usando-nanotecnologia-que-imita-la-fotosintesis/A5EZTJWMSVFVTJHQGXZX5PIZII/",
      backgroundimg: FotoLaTercera,
    },
    {
      description: "Guillermo Maripán apuesta por Photio",
      link: "https://www.futuro360.com/desafiotierra/futbol-maripan-photio-nanotecnologia_20230125/",
      backgroundimg: FotoInversionista,
    },
    {
      description: "Photio, aditivo descontaminante",
      link: "https://www.innovaspain.com/photio-chile-descontamina-aire-con-pintura/",
      backgroundimg: FotoInnovaspain,
    },
  ],
  NoticiasFour: [
    {
      description: "Photio apoya a Adidas en proyecto social",
      link: "https://infogate.cl/2022/08/30/adidas-restaura-multicancha-junto-a-vecinos-de-la-florida/",
      backgroundimg: FotoInfogate,
    },
    {
      description: "Conoce qué es Photio",
      link: "https://www.df.cl/df-lab/innovacion-y-startups/photio-la-startup-que-descontamina-el-aire-con-nanotecnologia",
      backgroundimg: FotoDiarioFinanciero,
    },
  ],
  NewsPaper: [
    {
      description: "Photio en mayor mural descontaminante de Perú",
      link: "https://pressperu.com/street-art-conoce-el-mural-descontaminante-mas-grande-de-peru-en-el-ibis-lima-larco-miraflores/",
      backgroundimg: FotoPressPeru,
    },
    {
      description: "Photio ganador de premio Avonni 2022",
      link: "https://portalinnova.cl/ganadores-premios-avonni-2022/",
      backgroundimg: News1,
    },
    {
      description: "Photio ganador de desafío emprendedor",
      link: "https://www.paiscircular.cl/industria/freshwater-y-photio-las-tecnologias-sustentables-para-producir-agua-y-descontaminar-ciudades-ganadoras-del-6-concurso-nacional-desafio-emprendedor/",
      backgroundimg: FotoPaisCircular,
    },
    {
      description: "Photio en alianza con Inmobiliaria Paz",
      link: "https://g5noticias.cl/2023/01/15/paz-corp-incorpora-pintura-purificadora-de-aire-en-fachada-de-sus-proyectos/",
      backgroundimg: FotoNoticiaPaz,
    },
    {
      description: "Startup chilena busca combatir la contaminación",
      link: "https://www.t13.cl/noticia/emprendedores/startup-chilena-lanzo-innovador-aditivo-incorporar-diferentes-materiales-y-capturar-gases",
      backgroundimg: Fotonoticiac13,
    },
    {
      description: "Photio en Murales para Chile",
      link: "https://www.diariosustentable.com/2023/01/murales-para-chile-cierra-un-positivo-2022-con-10-obras-inauguradas-desde-su-puesta-en-marcha/",
      backgroundimg: FotomuralesparaChile,
    },
    {
      description: "Chilenos crean tecnología descontaminante",
      link: "https://www.latercera.com/que-pasa/noticia/como-mil-arboles-chilenos-crean-pintura-usando-nanotecnologia-que-imita-la-fotosintesis/A5EZTJWMSVFVTJHQGXZX5PIZII/",
      backgroundimg: FotoLaTercera,
    },
    {
      description: "Guillermo Maripán apuesta por Photio",
      link: "https://www.futuro360.com/desafiotierra/futbol-maripan-photio-nanotecnologia_20230125/",
      backgroundimg: FotoInversionista,
    },
    {
      description: "Photio, aditivo descontaminante",
      link: "https://www.innovaspain.com/photio-chile-descontamina-aire-con-pintura/",
      backgroundimg: FotoInnovaspain,
    },
    {
      description: "Photio apoya a Adidas en proyecto social",
      link: "https://infogate.cl/2022/08/30/adidas-restaura-multicancha-junto-a-vecinos-de-la-florida/",
      backgroundimg: FotoInfogate,
    },
    {
      description: "Conoce qué es Photio",
      link: "https://www.df.cl/df-lab/innovacion-y-startups/photio-la-startup-que-descontamina-el-aire-con-nanotecnologia",
      backgroundimg: FotoDiarioFinanciero,
    },
  ],
};

const buttons = {
  more: "Ver más",
  less: "Ver menos",
  read: 'Ir a leer'
}

export const newsContent = {
  newsTitle,
  news,
  buttons,
};
