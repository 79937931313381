import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import FotoPaisCircular from "../../../constants/assets/aboutView/about/teamphotio.jpg";

const about = {
  positionText: "left",
  textContent: {
    description: (
      <p>
        We are a <strong>disruptive startup</strong> that uses <strong>nanotechnology</strong> as a tool to
        contribute in <strong>solving the climate problems that afflict us today</strong>. We are
        a group of people concerned about our planet, who understand that
        pollution is a danger to everyone. We base our developments on
        <strong>collaboration</strong>, as we believe it is the fundamental pillar to address
        this and other environmental problems.
      </p>
    ),
    upperTitle: "About Us",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  mediaContent: {
    source: FotoPaisCircular,
    alt: "Foto",
  },
};

export const aboutContent = {
  about,
};
