import styled from "styled-components";

const FirstBackgroundComponent = styled.div`
  background: #3040e380;
  width: 90%;
  height: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 960px) {
    height: 45%;
  }
`;

const SecondBackgroundComponent = styled.div`
  background: #3040e380;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 960px) {
    width: 90%;
  }
`;

const MainBackgroundComponent = styled.div`
  background: #3040e3;
  height: 100%;
  width: 95%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  h1 {
    @media (max-width: 960px) {
      font-size: 1.4em;
      line-height: 1.2em;
    }
  }

  @media (min-width: 960px) {
    justify-content: space-evenly;
    padding: 30px 5% 0px 15%;
    width: 90%;
  }
`;
export {
  FirstBackgroundComponent as FirstBackground,
  SecondBackgroundComponent as SecondBackground,
  MainBackgroundComponent as MainBackground,
};
