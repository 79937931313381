import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import LOGO1 from "../../../constants/assets/homeView/premios/LOGO1.png";
import LOGO2 from "../../../constants/assets/homeView/premios/LOGO2.png";
import LOGO3 from "../../../constants/assets/homeView/premios/LOGO3.png";
import LOGO4 from "../../../constants/assets/homeView/premios/LOGO4.png";
import LOGO5 from "../../../constants/assets/homeView/premios/LOGO5.png";
import LOGO6 from "../../../constants/assets/homeView/premios/LOGO6.png";
import LOGO7 from "../../../constants/assets/homeView/premios/LOGO7.png";
import LOGO8 from "../../../constants/assets/homeView/premios/LOGO8.png";
import LOGO9 from "../../../constants/assets/homeView/premios/LOGO9.png";
import LOGO10 from "../../../constants/assets/homeView/premios/LOGO10.png";
import LOGO11 from "../../../constants/assets/homeView/premios/LOGO11.png";
import LOGO12 from "../../../constants/assets/homeView/premios/LOGO12.png";
import LOGO13 from "../../../constants/assets/homeView/premios/LOGO13.png";
import LOGO14 from "../../../constants/assets/homeView/premios/LOGO14.png";
import LOGO15 from "../../../constants/assets/homeView/premios/LOGO15.png";
import LOGO16 from "../../../constants/assets/homeView/premios/LOGO16.png";
import LOGO17 from "../../../constants/assets/homeView/premios/LOGO17.png";
import LOGO18 from "../../../constants/assets/homeView/premios/LOGO18.png";
import LOGO19 from "../../../constants/assets/homeView/premios/LOGO19.png";
import LOGO20 from "../../../constants/assets/homeView/premios/LOGO20.png";
import LOGO21 from "../../../constants/assets/homeView/premios/LOGO21.png";
import LOGO22 from "../../../constants/assets/homeView/premios/LOGO22.png";
import LOGO23 from "../../../constants/assets/homeView/premios/LOGO23.png";
import LOGO24 from "../../../constants/assets/homeView/premios/LOGO24.png";

const textContent = {
  upperTitle: "Premios y Reconocimientos",
  iconUpperTitle: <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />,
};

const items = [
  {
    source: LOGO1,
    alt: "Premio AVONNI",
    title: "Premio AVONNI",
    description: "Categoría Medioambiente 2022",
    position: 1,
    url: "https://avonni.cl/ganadores/",
  },
  {
    source: LOGO2,
    alt: "Premio de Innovación",
    title: "Premio de Innovación",
    description: "PwC 2023",
    position: 2,
    url: "https://www.pwc.com/cl/es/acerca-de-nosotros/encuentros-y-webinars/2022/Premio-PwC-Chile-Innovacion-2022-elebro-su-10ma-version.html",
  },
  {
    source: LOGO3,
    alt: "Premios Verdes",
    title: "Premios Verdes",
    description: "Categoría Green Tech Startups 2023",
    position: 3,
    url: "https://premiosverdes.org/es/x-edicion/#ganadores",
  },
  {
    source: LOGO4,
    alt: "100K Latam",
    title: "100K Latam",
    description: "Categoría Launch 2021",
    position: 4,
    url: "https://100klatam.org/launch/",
  },
  {
    source: LOGO5,
    alt: "100K Latam",
    title: "100K Latam",
    description: "Categoría Pitch 2019",
    position: 5,
    url: "https://100klatam.org/pitch/",
  },
  {
    source: LOGO6,
    alt: "1° Concurso Nacional Desafío Emprendedor 2022",
    title: "1° Concurso Nacional Desafío Emprendedor 2022",
    description: "",
    position: 6,
    url: "https://www.youtube.com/watch?v=9IRxof3ABS4&pp=ygUVYmFuY28gZGUgY2hpbGUgcGhvdGlv",
  },
  {
    source: LOGO7,
    alt: "Startup Chile",
    title: "Startup Chile",
    description: "Growth BIG 5 2022",
    position: 7,
    url: "https://startupchile.org/",
  },
  {
    source: LOGO8,
    alt: "Startup Ciencia",
    title: "Startup Ciencia",
    description: "Batch 2 2021",
    position: 8,
    url: "https://www.startupciencia.cl/",
  },
  {
    source: LOGO9,
    alt: "Ganador Final Mundial Creative Business Cup 2021",
    title: "Ganador Final Mundial Creative Business Cup 2021 ",
    description: "",
    position: 9,
    url: "https://www.cbnet.com/creative-business-cup",
  },
  {
    source: LOGO10,
    alt: "Innovación Ambiental",
    title: "Innovación Ambiental",
    description: "RIGA 2022",
    position: 10,
    url: "https://britcham.cl/es/premiacion-xiii-reconocimiento-en-innovacion-ambiental-riga-2022/",
  },
  {
    source: LOGO11,
    alt: "Iniciativas Sustentables",
    title: "Iniciativas Sustentables",
    description: "LT Pulso 2022",
    position: 11,
    url: "http://especiales.pulso.cl/sustentabilidad/iniciativas.html",
  },
  {
    source: LOGO12,
    alt: "AntofaEmprende",
    title: "AntofaEmprende",
    description: "Categoría Expansión 2022",
    position: 12,
    url: "https://antofaemprende.cl/",
  },
  {
    source: LOGO13,
    alt: "Talento Emprendedor",
    title: "Talento Emprendedor",
    description: "Categoría Internacionalización 2023",
    position: 13,
    url: "https://www.cajalosandes.cl/tecla",
  },
  {
    source: LOGO14,
    alt: "Talento Emprendedor",
    title: "Talento Emprendedor",
    description: "Categoría Consolidación 2021",
    position: 14,
    url: "https://www.cajalosandes.cl/tecla",
  },
  {
    source: LOGO15,
    alt: "Know Hub Ignition",
    title: "Know Hub Ignition",
    description: "Batch 1 2019",
    position: 15,
    url: "https://knowhub.cl/programa-kh-ignition/",
  },
  {
    source: LOGO16,
    alt: "Ecodisruptive",
    title: "Ecodisruptive",
    description: "Vertical de Cambio Climático 2021",
    position: 16,
    url: "https://www.bupa.com/sustainability/sustainability-strategy/eco-disruptive/eco-disruptive-programa",
  },
  {
    source: LOGO17,
    alt: "Premio Henri Nestlé",
    title: "Premio Henri Nestlé",
    description: "Versión 2020",
    position: 17,
    url: "https://www.nestle.cl/nestleenlasociedad/proyectos_en_chile/premio_henri",
  },
  {
    source: LOGO18,
    alt: "Premio Felipe Álvarez",
    title: "Premio Felipe Álvarez",
    description: "Universidad de Chile 2020",
    position: 18,
    url: "https://openbeauchef.cl/premio-felipe-alvarez-elige-a-las-mejores-iniciativas-de-innovacion-y-emprendimiento-del-pais-en-educacion-superior/",
  },
  {
    source: LOGO19,
    alt: "Mención Honrosa Iniciativas Sustentables",
    title: "Mención Honrosa Iniciativas Sustentables",
    description: "LT Pulso 2019",
    position: 19,
    url: "http://especiales.pulso.cl/sustentabilidad/iniciativas.html",
  },
  {
    source: LOGO20,
    alt: "Consolida y Expande",
    title: "Consolida y Expande",
    description: "2021",
    position: 20,
    url: "https://www.corfo.cl/sites/cpp/consolida-y-expande",
  },
  {
    source: LOGO21,
    alt: "Súmate a Innovar",
    title: "Súmate a Innovar",
    description: "2019",
    position: 21,
    url: "https://www.corfo.cl/sites/cpp/convocatorias/sumate_a_innovar",
  },
  {
    source: LOGO22,
    alt: "Súmate a Innovar",
    title: "Súmate a Innovar",
    description: "Ecodiseño 2019",
    position: 22,
    url: "https://www.corfo.cl/sites/cpp/convocatorias/sumate_ecodiseno",
  },
  {
    source: LOGO23,
    alt: "SSAF – Innovación",
    title: "SSAF – Innovación",
    description: "Innovo USACH",
    position: 23,
    url: "https://innovo.cl/",
  },
  {
    source: LOGO24,
    alt: "SSAF – Desafío",
    title: "SSAF – Desafío",
    description: "Innovo USACH",
    position: 24,
    url: "https://innovo.cl/",
  },
];

export const premiosContent = {
  textContent,
  items,
};
