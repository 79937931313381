import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Hidden,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import COLORS from "../../../styles/global/colors";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
    maxWidth: 420,
    backgroundColor: COLORS.white,
  },
  selectEmpty: {
    marginTop: theme.spacing(3),
    color: COLORS.white,
  },
  margin: {
    margin: theme.spacing(3),
  },
}));

export function PreguntaSearch({
  handleBuscarClick,
  handleChange,
  handleEnterPress,
  busqueda,
  content = undefined,
}) {
  const classes = useStyles();

  if (!content) return null;

  const {inputSearch} = content

  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ display: "flex" }}
        >
          <FormControl className={classes.formControl}>
            <TextField
              label={inputSearch}
              value={busqueda}
              onChange={handleChange}
              onKeyDown={handleEnterPress} // Manejador de evento para la tecla Enter
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleBuscarClick}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { color: COLORS.blueTan },
              }}
              style={{ color: COLORS.blueTan }}
            />
          </FormControl>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          style={{ display: "flex" }}
        >
          <FormControl className={classes.formControl}>
            <TextField
              label={inputSearch}
              value={busqueda}
              onChange={handleChange}
              onKeyDown={handleEnterPress} // Manejador de evento para la tecla Enter
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleBuscarClick}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
                style: { color: COLORS.blueTan },
              }}
              style={{ color: COLORS.blueTan }}
            />
          </FormControl>
        </Grid>
      </Hidden>
    </>
  );
}

export default PreguntaSearch;
