import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import { Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";
import Photio from "../../../constants/assets/applicationView/appNano/Photio.svg";
import Capacitacion from "../../../constants/assets/applicationView/appNano/Capacitacion.svg";
import Certificacion from "../../../constants/assets/applicationView/appNano/Certificacion.svg";
import Gota3 from "../../../constants/assets/applicationView/appNano/Gota3.svg";

const titleContent = {
  textContent: {
    upperTitle: "Our Nanofactory",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  description: (
    <div
      style={{
        display: "flex",
        gap: 20,
        flexDirection: "column",
        margin: "40px 0px",
        color: COLORS.blueTan,
      }}
    >
      <Typography variant="body1" style={{ fontSize: 22 }}>
        Our Nanofactory corresponds to the set of teams, researchers, chemists
        and engineers involved in the process to develop the various adjustments
        and changes of presentation of our technology, to incorporate it into
        different production processes.
      </Typography>
      <Typography variant="body1" style={{ fontSize: 22 }}>
        This concept in turn refers to the complete circuit of activities that
        we carry out to develop new applications.
      </Typography>
      <Typography variant="body1" style={{ fontSize: 22 }}>
        This is where{" "}
        <span style={{ textDecoration: "line-through" }}>magic</span>{" "}
        <strong>science</strong> happens!
      </Typography>
    </div>
  ),
};

const descriptionContent = {
  appShow: [
    {
      source: Photio,
      alt: "step1",
      title: "Step 1",
      text: "The first approach",
      description:
        "Our team approaches the place where the production process under which the material to be additivated with Photio is manufactured. On the spot, our researchers conduct a detailed survey of information, emphasizing the things we should not change, both in the final material and in the process.",
    },
    {
      source: Capacitacion,
      alt: "step2",
      title: "Step 2",
      text: "Compatibility",
      description:
        "Once our specialists understood the process and the product, we will work on the formulation adjustments in our Nanofactory, where we modify and optimize our technology so as not to change the appearance and mechanical properties that define the new material that will contain Photio in its structure.",
    },
    {
      source: Certificacion,
      alt: "step3",
      title: "Step 3",
      text: "We must be efficient",
      description:
        "With the formulation adjustments ready, the next objective is to optimize the new application. To do this, we use photo reactors, gas analyzers and precision equipment to find the best addition process, one that will best transform the new material into an air purifier.",
    },
    {
      source: Gota3,
      alt: "step4",
      title: "Step 4",
      text: "Validation and Production",
      description:
        "Once we have the approval of the company where the material is manufactured, and our laboratory results are the best, we go to the productive process. This is where we manufacture the material with Photio in an industrial way, and then send it to an external laboratory to be validated. And ready, we have a new Photio application!ƒ",
    },
  ],
};

export const appNanoContent = { titleContent, descriptionContent };
