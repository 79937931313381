import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const AboutValoresBody = ({ content }) => {
  const { textContent, items } = content;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      direction="column"
      style={{ margin: "40px 0px 20px" }}
    >
      <Hidden mdDown>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              width: "100%",
              fontSize: "20px",
              color: COLORS.blueTan,
              marginLeft: "20px",
            }}
          >
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              width: "100%",
              fontSize: "20px",
              color: COLORS.blueTan,
              marginLeft: "30px",
            }}
          >
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Grid item xs={12} style={{ width: "100%", marginBottom: "20px" }}>
        <Hidden mdDown>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", margin: "100px", marginLeft: "50px" }}
          >
            {items.map((logoItem, index) => (
              <Grid item xs={5} md={4} key={`AboutValores_${index}`}>
                <Grid item xs={6}>
                  <img
                    src={logoItem.source}
                    alt="img"
                    style={{ marginLeft: "40px" }}
                  ></img>
                </Grid>
                <Grid item xs={8} style={{ width: "100%", height: "100px" }}>
                  <Typography
                    variant="body1"
                    style={{
                      width: "260px",
                      fontSize: "32px",
                      fontWeight: 550,
                      marginTop: "60px",
                      color: COLORS.blueTan,
                    }}
                  >
                    {logoItem.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      width: "300px",
                      height: "185px",
                      fontSize: "20px",
                      fontWeight: 300,
                      marginTop: "30px",
                      color: COLORS.blueTan,
                      justifyContent: "flex-start",
                      marginBottom: "30px",
                      lineHeight: "32px",
                      letterSpacing: "0.02em",
                    }}
                  >
                    {logoItem.description}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", marginTop: "50px" }}
          >
            {items.map((logoItem, index) => (
              <>
                <Grid
                  item
                  xs={9}
                  style={{ width: "100%" }}
                  key={`AboutValores_${index}`}
                >
                  <img
                    src={logoItem.source}
                    alt="img"
                    style={{ height: 50 }}
                  />
                </Grid>
                <Grid item xs={9} style={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "32px",
                      fontWeight: 550,
                      marginTop: "30px",
                      color: COLORS.blueTan,
                    }}
                  >
                    {logoItem.title}
                  </Typography>
                </Grid>
                <Grid item xs={9} style={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "20px",
                      fontWeight: 300,
                      marginTop: "30px",
                      color: COLORS.blueTan,
                      justifyContent: "flex-start",
                      marginBottom: "30px",
                      lineHeight: "32px",
                      letterSpacing: "0.02em",
                    }}
                  >
                    {logoItem.description}
                  </Typography>
                </Grid>
              </>
            ))}
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};
