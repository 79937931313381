import ZapatillaKruza from "../../../constants/assets/applicationView/partners/ZapatillaKruza.png";
import Asfalto from "../../../constants/assets/applicationView/partners/asfalto.png";
import Fotodecueros from "../../../constants/assets/applicationView/partners/Fotodecueros.png";
import Siding from "../../../constants/assets/applicationView/partners/siding.png";
import Hormigon from "../../../constants/assets/applicationView/partners/hormigon.png";

const titleContent = {
  title:
    "Aplicaciones de Photio que hemos desarrollado junto a nuestros partners",
  description:
    "Aquí les presentamos algunas de las aplicaciones que ya hemos logrado desarrollar y validar, y que ¡pronto estarán a la venta!",
};

const descriptionContent = {
  proyectShow: [
    {
      title: "Mezclas asfálticas",
      backgroundimg: Asfalto,
    },
    {
      title: "Zapatillas",
      backgroundimg: ZapatillaKruza,
    },
    {
      title: "Cuero Sintético y Revestimientos técnicos",
      backgroundimg: Fotodecueros,
    },
    {
      title: "Siding de Fibrocemento",
      backgroundimg: Siding,
    },
    {
      title: "Hormigón y Mortero",
      backgroundimg: Hormigon,
    },
  ],
};

export const partnersContent = {
  titleContent,
  descriptionContent,
};
