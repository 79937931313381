import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import COLORS from "../../../styles/global/colors";

const textContent = {
  upperTitle: "Nuestras Aplicaciones",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
};

const buttonContent = {
  nextButton: "Siguiente Proyecto",
  forwardButton: "Proyecto Anterior",
};

export const titleDetailsContent = {
  textContent,
  buttonContent,
};
