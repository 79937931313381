import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { WhatisVideosOneStyled } from "../styles/WhatisVideoOneStyled";
import { ExplanationStyled } from "../styles/WhatisView";

const mediaChildren = ({ iframe, title, iconUpperTitle }) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Hidden mdUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid item xs={10} style={{ fontSize: "19px" }}>
        <Typography
          variant="body1"
          style={{
            color: COLORS.white,
          }}
        >
          {title}
          {iconUpperTitle}
        </Typography>
      </Grid>
      <WhatisVideosOneStyled style={{ justifyContent: "flex-start" }}>
        {iframe}
      </WhatisVideosOneStyled>
    </Grid>
  );
};

const textBoxChildren = ({ description }) => {
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      style={{ width: "100%", height: "100%" }}
    >
      <Grid item xs={10}>
        <Typography
          variant="body1"
          style={{
            fontWeight: 300,
            fontSize: "20px",
            lineHeight: "26px",
            letterSpacing: "0.02em",
            fontStyle: "normal",
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

export const WhatisVideosTwo = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;
  const { description2, description3 } = textContent;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ background: COLORS.greenTitle, padding: "40px 0px" }}
    >
      <Grid item xs={12} md={9}>
        <InformationComponent
          mediaChildren={mediaChildren(mediaContent)}
          textBoxChildren={textBoxChildren(textContent)}
          positionText={positionText}
          backgroundColor={COLORS.greenTitle}
        />
      </Grid>
      <Grid xs={10} md={9}>
        <ExplanationStyled>
          <Typography
            variant="body1"
            style={{
              lineHeight: "26px",
              fontWeight: 300,
              fontSize: "20px",
              letterSpacing: "0.02em",
              fontStyle: "normal",
            }}
          >
            {description2}
          </Typography>
          <Typography
            variant="body1"
            style={{
              lineHeight: "26px",
              fontWeight: 300,
              fontSize: "20px",
              letterSpacing: "0.02em",
              fontStyle: "normal",
              color: COLORS.white,
            }}
          >
            {description3}
          </Typography>
        </ExplanationStyled>
      </Grid>
    </Grid>
  );
};
