const token = process.env.REACT_APP_META_KEY;
const baseUrl = `https://graph.instagram.com/me/media?access_token=${token}`;

export const getLastPost = async () => {
  const url = `${baseUrl}&fields=thumbnail_url,media_url,permalink&limit=20`;

  const response = await fetch(url);
  if (response.status !== 200) {
    return { error: true, description: response.statusText };
  }

  const { data } = await response.json();
  return data;
};
