import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import { Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";
import Photio from "../../../constants/assets/applicationView/appNano/Photio.svg";
import Capacitacion from "../../../constants/assets/applicationView/appNano/Capacitacion.svg";
import Certificacion from "../../../constants/assets/applicationView/appNano/Certificacion.svg";
import Gota3 from "../../../constants/assets/applicationView/appNano/Gota3.svg";

const titleContent = {
  textContent: {
    upperTitle: "Nuestra NanoFactoría",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  description: (
    <div
      style={{
        display: "flex",
        gap: 20,
        flexDirection: "column",
        margin: "40px 0px",
        color: COLORS.blueTan,
      }}
    >
      <Typography variant="body1" style={{ fontSize: 22 }}>
        Nuestra Nanofactoría corresponde al conjunto de equipos, investigadores,
        químicos e ingenieros que participan en el proceso para desarrollar los
        diversos ajustes y cambios de presentación de nuestra tecnología, para
        incorporarla en distintos proceso productivos.
      </Typography>
      <Typography variant="body1" style={{ fontSize: 22 }}>
        Este concepto a su vez hace referencia al circuito completo de
        actividades que realizamos para desarrollar nuevas aplicaciones.
      </Typography>
      <Typography variant="body1" style={{ fontSize: 22 }}>
        ¡Aquí es donde ocurre la{" "}
        <span style={{ textDecoration: "line-through" }}>magia</span>{" "}
        <strong>ciencia!</strong>
      </Typography>
    </div>
  ),
};

const descriptionContent = {
  appShow: [
    {
      source: Photio,
      alt: "paso1",
      title: "Paso 1",
      text: "El primer acercamiento",
      description:
        "Nuestro equipo se acerca al lugar donde está el proceso productivo bajo el cual se fabrica el material que se quiere aditivar con Photio. En el lugar, nuestros investigadores realizan un detallado levantamiento de información, poniendo énfasis en las cosas que no debemos modificar, tanto en el material final como en el proceso.",
    },
    {
      source: Capacitacion,
      alt: "Paso 2",
      title: "Paso 2",
      text: "La compatibilidad",
      description:
        "Una vez que nuestros especialistas entendieron el proceso y el producto, nos vamos a trabajar en los ajuste de la formulación en nuestra Nanofactoría, en donde modificamos y optimizamos nuestra tecnología para no cambiar la apariencia y las propiedades mecánicas que definen el nuevo material que contendrá Photio en su estructura.",
    },
    {
      source: Certificacion,
      alt: "Paso 3",
      title: "Paso 3",
      text: "Debemos ser eficientes",
      description:
        "Al tener listos los ajuste de la formulación, el siguiente objetivo es optimizar la nueva aplicación. Para ello, utilizamos fotorreactores, analizadores de gases y equipo de precisión para dar con el mejor proceso de adición, aquel que transformará de mejor manera el nuevo material en un purificador de aire.",
    },
    {
      source: Gota3,
      alt: "Paso 4",
      title: "Paso 4",
      text: "Validación y a producir",
      description:
        "Una vez tenemos el visto bueno de la empresa en donde se fabrica el material, y nuestros resultados de laboratorio son los mejores, nos vamos al proceso productivo. Es ahí donde fabricamos el material con Photio de manera industrial, para posteriormente enviarlo a un laboratorio externo a validar. Y listo, ¡tenemos una nueva aplicación de Photio! ",
    },
  ],
};

export const appNanoContent = { titleContent, descriptionContent };
