import styled from "styled-components";
import COLORS from "../../../styles/global/colors";

const WhatisBenefitsItems = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 960px) {
    width: 70%;
  }

  img {
    height: 100px;
    margin-bottom: 40px;
  }
  p {
    color: ${COLORS.blueTan};
    margin-bottom: 20px;
    align-text: left;
  }
`;

export { WhatisBenefitsItems };
