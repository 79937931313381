import styled from "styled-components";

const ContainerStyleComponent = styled.div`
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background: url(${(props) =>
    props.backgroundImage
      ? props.backgroundImage
      : "https://www.uchile.cl/.imaging/default/dam/imagenes/Uchile/VEXCOM/MAYO-2022/ALUMNI-01-L.jpeg0/jcr:content.jpeg"}) no-repeat, linear-gradient(rgba(10, 46, 96, 0.8) 63.2%, rgba(47, 128, 237, 0.49) 86.12%, rgba(255, 255, 255, 0) 91.39%);
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;

  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export { ContainerStyleComponent as ContainerStyle };
