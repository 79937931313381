import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import BCV10 from "../../../constants/assets/aboutView/valores/BCV10.svg";
import LogoVersatilidad from "../../../constants/assets/aboutView/valores/LogoVersatilidad.png";
import LogoColaboracion from "../../../constants/assets/aboutView/valores/LogoColaboracion.png";

const aboutValues = {
  textContent: {
    upperTitle: "Our Pillars",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  items: [
    {
      source: BCV10,
      alt: "Scientific basis",
      title: "Scientific basis",
      description:
        "Through science and technology, our team daily develops new applied research and improvements for our technology.",
    },
    {
      source: LogoVersatilidad,
      alt: "Versatility",
      title: "Versatility",
      description:
        "Adaptability and versatility are the basis of our developments, and what has allowed us to transform Photio into a decontamination platform.",
    },
    {
      source: LogoColaboracion,
      alt: "Collaboration",
      title: "Collaboration",
      description:
        "At Photio we believe that through collaboration and teamwork, we are able to address any challenge. Collaboration is the heart of Photio.",
    },
  ],
};

export const valoresContent = { aboutValues };
