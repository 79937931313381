import { createTheme } from "@material-ui/core/styles";
import COLORS, { default as colors } from "./colors";

const theme = createTheme({
  palette: {
    background: {
      default: colors.white,
    },
    error: {
      main: COLORS.orange,
    },
  },
  typography: {
    h1: {
      fontSize: "2.25rem",
      fontWeight: "300",
      fontStyle: "normal",
      letterSpacing: "0.01rem",
      lineHeight: "2.625rem"
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "2.25rem"
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.625rem",
      letterSpacing: "0.02rem",
    },
    body1: {
      fontSize: "1.125rem",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.625rem",
      letterSpacing: "0.02rem",
      fontFamily: 'Roboto',
    },
    body2: {
      fontSize: "1rem",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.625rem",
      letterSpacing: "0.02rem",
    },
    body3: {
      fontSize: "1rem",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.625rem",
      letterSpacing: "0.04rem",
      textTransform: "uppercase",
    },
    button: {
      fontSize: "1.125rem",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.313rem",
      letterSpacing: "0.01rem",
      textTransform: "none",
    },
    caption: {
      fontSize: "0.875rem",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1rem",
      letterSpacing: "0.063rem",
    },
    overline: {
      fontSize: "0.875rem",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1rem",
      letterSpacing: "0.2rem",
      textTransform: "uppercase",
    }
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "min-height": "100vh"
      },
    },
    MuiGrid: {
      root: {
        margin: 0,
        padding: 0,
      },
    },
    MuiCard: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
    MuiCardHeader: {
      root: {
        margin: 0,
        padding: 0,
        paddingLeft: 5,
      },
    },
    MuiCardActions: {
      root: {
        padding: 3,
        margin: 0,
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
        margin: 0,
      },
    },
    MuiCollapse: {
      root: {
        margin: 0,
        padding: 3,
      },
    },
    MuiButton: {
      root: {
        margin: 5,
        textDecorationLine: "none",
      },
      containedPrimary: {
        background: colors.primary,
        color: colors.white,
      },
    },
  },
});

export default theme;
