import React, { useContext, useEffect, useState } from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import COLORS from "../../styles/global/colors";
import Carousel, { CarouselItem } from "../../components/Carousel/Carousel";
import { InstaPost } from "../../components/InstaPost";
import { getLastPost } from "../../services/InstagramService";
import { LanguageContext } from "../../contexts/LanguageContext";

export const Rrss = () => {
  const { dictionary } = useContext(LanguageContext);
  const { rrssContent } = dictionary.sharedContent;

  const [postlist, setPostlist] = useState([]);

  useEffect(() => {
    GetDataInsta();
  }, []);

  if (!rrssContent) return null;

  const { textContent } = rrssContent;

  const GetDataInsta = async () => {
    const response = await getLastPost();
    if (response.error) {
      setPostlist([]);
    } else {
      const array1 = response.slice(0, 4);
      const array2 = response.slice(4, 8);
      const array3 = response.slice(8, 12);
      setPostlist([[...array1], [...array2], [...array3]]);
    }
  };

  if (postlist.length > 0) {
    return (
      <>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ background: COLORS.white, width: "100%" }}
        >
          <Grid
            item
            xs={12}
            style={{
              color: COLORS.blueTan,
              width: "100%",
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body1"
              style={{ fontSize: "18px", marginBottom: "30px" }}
            >
              {textContent.upperTitle}
              {textContent.iconUpperTitle}
            </Typography>
          </Grid>
        </Grid>
        <Hidden mdDown>
          <Carousel>
            {postlist.map((arrayPost, index) => (
              <CarouselItem key={`ArrayItemsCarousel_${index}`}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {arrayPost.map((item, indexItem) => (
                    <InstaPost
                      key={`ItemsCarousel_${indexItem}-${index}`}
                      link={item.permalink}
                      backgroundimg={item.thumbnail_url || item.media_url}
                    />
                  ))}
                </div>
              </CarouselItem>
            ))}
          </Carousel>
        </Hidden>
        <Hidden mdUp>
          <Carousel>
            {postlist.map((arrayPost, index) => {
              return arrayPost.map((item, indexItem) => (
                <CarouselItem key={`ArrayItemsCarousel_${index}`}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <InstaPost
                      key={`ItemsCarousel_${indexItem}-${index}`}
                      link={item.permalink}
                      backgroundimg={item.thumbnail_url || item.media_url}
                    />
                  </div>
                </CarouselItem>
              ));
            })}
          </Carousel>
        </Hidden>
      </>
    );
  }
  return null;
};
