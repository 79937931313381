import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";

export const PreguntasBody = ({ content = undefined }) => {
  if (!content) return null;
  const { textContent } = content;

  return (
    <>
      <Hidden mdDown>
        <Grid
          item
          xs={12}
          style={{ width: "100%", height: "0px", color: "#002061" }}
        >
          <Typography
            variant="body1"
            style={{ fontSize: "20px", marginLeft: "210px" }}
          >
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          style={{
            width: "100%",
            height: "0px",
            margin: "50px",
            color: "#002061",
            marginLeft: "20px",
          }}
        >
          <Typography variant="body1" style={{ fontSize: "20px" }}>
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
    </>
  );
};
