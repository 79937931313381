import styled from "styled-components";

const WhatisVideoTextTwoStyled = styled.div`
  display: flex-start;
  justify-content: center;
  direction: column;
  align-items: flex-start;
  margin: 40px 20px 20px;
  width: 100%;

  p {
    color: ${(props) => props.colorText || "#000"};
    width: 100%;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 3%;
    word-break: break-word;
    white-space: normal;
    font-weight: 300;

    @media (min-width: 960px) {
      word-break: normal;
      width: 500px;
    }

    strong {
      font-weight: 800;
    }
  }
`;

const WhatisVideoTwoStyled = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 30px 0px;
  height: 100%;
  width: 100%;

  @media (min-width: 960px) {
    justify-content: flex-end;
    margin-top: 0px;
  }

  video {
    border-radius: 8px;
    width: 100%;
  }

  strong {
    font-weight: 800;
  }
`;

export { WhatisVideoTextTwoStyled, WhatisVideoTwoStyled };
