import React, { useContext } from "react";
import WhatisAccordion from "./WhatisAccordion";
import { WhatisQuestionBody } from "./WhatisQuestionBody";
import { WhatisQuestionButton } from "./WhatisQuestionButton";
import { LanguageContext } from "../../../contexts/LanguageContext";

export const WhatisQuestionContainer = () => {
  const { dictionary } = useContext(LanguageContext);
  const { questionsContent, bodyContent } = dictionary.faqViewContent;
  const { faq } = questionsContent;
  const { textContent, buttonContent } = bodyContent;

  const resumenFaq = faq.slice(0, 3);

  return (
    <>
      <WhatisQuestionBody content={textContent} />
      <WhatisAccordion arrayFaq={resumenFaq} />
      <WhatisQuestionButton buttonContent={buttonContent} />
    </>
  );
};
