const faq = [
  {
    pregunta: "¿Cómo funciona Photio?",
    respuesta:
      "Photio es un aditivo que es capaz de transformar el lugar de aplicación en un purificador de aire, simulando el proceso de fotosíntesis que llevan a cabo plantas y árboles. Utilizando como fuente de energía cualquier tipo de luz, transformamos gases contaminantes en sustancias que ya no representan un peligro para la salud de las personas o el medio ambiente. Nuestra tecnología es un blend de nanomateriales que en conjunto promueven una serie de reacciones fotoquímicas que son responsables de la captura de gases contaminantes.",
  },
  // {
  //   pregunta: "¿Cuál es el valor del producto?",
  //   respuesta:
  //     "Actualmente estamos comercializando para empresas e instituciones públicas el producto para su aplicación en pinturas. Photio en este caso posee dos formatos. El formato para 1 galón, el cual tiene un valor de $7.990 + IVA (pesos chilenos), y el formato para 1 tineta, cuyo valor es de $39.500 + IVA (pesos chilenos). Si estás pensando en un proyecto de gran envergadura, envíanos un correo para hacerte llegar nuestra tabla de precios, en donde aplicamos descuentos preferenciales desde las 80 unidades.",
  // },
  {
    pregunta: "¿Cómo y dónde puedo adquirir el producto?",
    respuesta:
      "Para cotizar y comprar nuestro producto debes enviarnos un correo con los detalles de tu requerimiento al correo contacto@photio.cl o llenar el formulario de contacto seleccionando la opción correspondiente en el botón que necesitas. Realizamos envíos a todo Chile.",
  },
  {
    pregunta: "¿Cuánto rinde el producto?",
    respuesta:
      "Dependiendo del formato, Photio rinde para 1 galón o 1 tineta de pintura. 1 galón corresponde a 3,78 litros de pintura.",
  },
  {
    pregunta: "¿Cómo utilizar Photio? ",
    respuesta:
      "Abre el sachet de Photio, vierte su contenido en el interior de un galón o tineta dependiendo del formato seleccionado, y revuelve enérgicamente por 1 a 3 minutos. Si vas a utilizar un agitador mecánico, realiza el proceso de mezclado por 30 a 60 segundos.",
  },
  {
    pregunta: "¿Entregan algún documento o certificación?",
    respuesta:
      "En el caso de proyectos para empresas, instituciones públicas o implementaciones de gran envergadura, nuestra startup les hará llegar un Certificado de Trazabilidad y Correcto Uso. En este documento encontrarán eco-equivalencias relevantes en términos de la aplicación, y sus métricas de impacto (kilogramos de gases capturados o kilogramos de CO2 equivalente compensado). Para conocer valores, tiempos de entrega y otros aspectos relevantes sobre este tema, escríbenos a contacto@photio.cl.",
  },
  {
    pregunta: "¿Se puede aplicar Photio en cualquier tipo de pintura?",
    respuesta:
      "Sí! Hasta la fecha Photio ha sido validado en pinturas como látex, esmalte al agua y sintético, óleos, lacas, epóxicos, base poliuretano, poliéster, texturas (martelina, covertina y otras) y distintos tipos de recubrimientos industriales.",
  },
  {
    pregunta: "¿Qué gases degrada? ¿Qué pasa con ellos?",
    respuesta:
      "Con nuestra tecnología degradamos gases contaminantes, no los absorbemos. Esto es muy importante, ya que el gas tratado por la fachada de tu casa no se fijará a la superficie, sino que será devuelto al ambiente, pero esta vez como una sustancia inerte. Específicamente nuestra tecnología promueve la degradación de gases tales como: monóxido de carbono (CO), óxidos de carbono (Nox), compuestos orgánicos volátiles (COV), metano (CH4), dióxido de azufre (SO2), amoniaco (NH3), bacterias y hongos.",
  },
  {
    pregunta:
      "¿Cuánto dura el efecto descontaminante de Photio? ¿Cada cuánto hay que reaplicar la tecnología?",
    respuesta:
      "La durabilidad del efecto descontaminante de Photio está directamente relacionado con la vida útil del material donde es incorporado. Debido a la calidad de las pinturas que se comercializan mayoritariamente en Latam, la vida útil de la tecnología se extiende por 3 a 4 años para esa aplicación, tiempo en el que se recomienda reaplicar pintura con Photio en la superficie. Para que el producto funcione de manera óptima, la superficie pintada debe mantenerse limpia y en contacto directa con el aire.",
  },
  {
    pregunta: "¿Qué estudios avalan su tecnología?",
    respuesta:
      "Después de casi 3 años de investigación y desarrollo, y gracias a las alianzas que generamos con universidades y laboratorios nacionales e internacionales, logramos desarrollar pruebas con las que determinamos el potencial descontaminante de Photio para diversos gases en distintos contextos. Inicialmente cerramos una alianza estratégica con la Pontificia Universidad Católica de Chile, con la cual mediante el uso de equipos especializados, como cromatógrafos de gases y fotorreactores, logramos obtener el máximo potencial descontaminante de Photio para gases como monóxido de carbono, metano, amoniaco, entre otros. ¡Actualmente seguimos trabajando con ellos validando todas nuestras aplicaciones! Posteriormente desarrollamos un proceso de revalidación de la tecnología en el CE-CERT en Estados Unidos, uno de los institutos de calidad del aire más reputados del mundo. Con ellos logramos validar nuevamente el impacto de Photio, y pudimos calcular el impacto de nuestra tecnología en interiores (Porque sí, Photio no sólo se aplica en exteriores). Esto sumado a las múltiples revalidaciones que hemos realizado en Europa. Por otro lado, hemos desarrollado una serie de pilotajes en donde hemos cuantificado el impacto de Photio en estacionamientos o directamente en murales, mediante el uso de sensores de gases, los cuales nos han permitido constatar además el impacto indirecto de Photio para el tratamiento de material particulado.",
  },
  {
    pregunta: "¿Trabajan con factura?",
    respuesta:
      "Por supuesto! Al momento de la compra te solicitaremos tus datos y tu factura llegará a tu mail directamente.",
  },
];

export const questionsContent = {
  faq,
};
