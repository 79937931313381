import React from "react";
import { Grid } from "@material-ui/core";
import { WhatisVideosOne } from "./WhatisVideosOne";
import { WhatisVideosTwo } from "./WhatisVideosTwo";



export const WhatisContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { howWorks, explanation } = content;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <WhatisVideosOne content={howWorks} />
      </Grid>
      <Grid item xs={12}>
        <WhatisVideosTwo content={explanation} />
      </Grid>
    </Grid>
  );
};
