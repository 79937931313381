import { ReactComponent as GotaAmarilla } from "../../../constants/assets/projectView/apply/Pintura.svg";
// import { ReactComponent as Hormigon } from "../../../constants/assets/projectView/apply/IconoTela.svg";
// import { ReactComponent as Asfalto } from "../../../constants/assets/shared/asfaltoblanco.svg";

const apply = {
  textContent: {
    upperTitle: "In this Project, Photio was applied in",
  },
  items: [
    {
      source: <GotaAmarilla />,
      alt: "Paintings",
      title: "Paintings",
      mobiletext: "Paintings",
    },
    // {
    //   source: <Hormigon />,
    //   alt: "Concrete",
    //   title: "Concrete",
    //   mobiletext: "Concrete",
    // },
    // {
    //   source: <Asfalto />,
    //   alt: "Asphalt",
    //   title: "Asphalt",
    //   mobiletext: "Asphalt",
    // },
  ],
};

export const applyContent = { apply };
