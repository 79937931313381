import React from "react";
import { useHistory } from "react-router-dom";
import { ButtonComponent } from "../../../components/globals/Button";
import { Grid, Hidden } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const WhatisQuestionButton = ({ buttonContent }) => {
  const { textButton } = buttonContent;

  const history = useHistory();

  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "54px", marginBottom: "40px" }}
        >
          <ButtonComponent
            type="secondary"
            text={textButton}
            margin={"10px 0px"}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.blueTan}
            showIconRight
            callToAction={() => {
              history.push("/questions");
              window.scrollTo(0, 0);
            }}
          >
            {" "}
          </ButtonComponent>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          justifyContent="center"
          style={{
            marginTop: "40px",
            marginBottom: "40px",
            marginLeft: "20px",
          }}
        >
          <ButtonComponent
            type="secondary"
            text={textButton}
            margin={"10px 0px"}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.blueTan}
            showIconRight
            callToAction={() => {
              history.push("/questions");
              window.scrollTo(0, 0);
            }}
          >
            {" "}
          </ButtonComponent>
        </Grid>
      </Hidden>
    </>
  );
};
