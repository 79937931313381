import COLORS from '../../../styles/global/colors';
import { ReactComponent as ArrowCornerBottom } from '../../../constants/assets/shared/arrowCornerBottomBlue.svg';

const textContent = {
  upperTitle: "Preguntas Frecuentes",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
};

const buttonContent = {
  textButton: "Ver más preguntas frecuentes",
}

const inputSearch = '¿Que necesitas?';

export const bodyContent = {
  textContent,
  buttonContent,
  inputSearch,
};
