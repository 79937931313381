import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { ButtonComponent } from "../../../components/globals/Button";
import { NewsCaja } from "../../../components/NewsCaja";
import COLORS from "../../../styles/global/colors";
import arrowDown from "../../../constants/assets/shared/arrowdown.svg";
import arrowUp from "../../../constants/assets/shared/arrowUp.svg";
import CarouselMobileNews, {
  CarouselNews,
} from "../../../components/CarouselNews/CarouselMobileNews";

export const NewsBody = ({ content }) => {
  const {
    news: { NoticiasOne, NoticiasTwo, NoticiasThree, NoticiasFour, NewsPaper },
    buttons: { more, less, read },
  } = content;

  const [showFull, setShowFull] = useState(false);

  return (
    <>
      <Hidden mdDown>
        <Grid container spacing={4} justifyContent="center">
          {NoticiasOne.map((e, index) => (
            <NewsCaja
              key={`Newbox_1${e.description}`}
              description={e.description}
              link={e.link}
              backgroundimg={e.backgroundimg}
              buttonText={read}
            />
          ))}
        </Grid>
        {showFull && (
          <Grid container spacing={4} justifyContent="center">
            {NoticiasTwo.map((e, index) => (
              <NewsCaja
                key={`Newbox_2${e.description}`}
                description={e.description}
                link={e.link}
                backgroundimg={e.backgroundimg}
                buttonText={read}
              />
            ))}
          </Grid>
        )}

        {showFull && (
          <Grid container spacing={4} justifyContent="center">
            {NoticiasThree.map((e, index) => (
              <NewsCaja
                key={`Newbox_3${e.description}`}
                description={e.description}
                link={e.link}
                backgroundimg={e.backgroundimg}
                buttonText={read}
              />
            ))}
          </Grid>
        )}
        {showFull && (
          <Grid container spacing={4} justifyContent="center">
            {NoticiasFour.map((e, index) => (
              <NewsCaja
                key={`Newbox_4${e.description}`}
                description={e.description}
                link={e.link}
                backgroundimg={e.backgroundimg}
                buttonText={read}
              />
            ))}
          </Grid>
        )}
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "100px", marginBottom: "25px" }}
        >
          <ButtonComponent
            type="secondary"
            text={showFull ? less : more}
            margin={"10px 0px"}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.blueTan}
            showIconRight
            iconRight={showFull ? arrowUp : arrowDown}
            callToAction={() => setShowFull(!showFull)}
          ></ButtonComponent>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container spacing={4} justifyContent="center">
          <CarouselMobileNews>
            {NewsPaper.map((e, index) => (
              <CarouselNews>
                <NewsCaja
                  key={`NewsCaja_${e.description}`}
                  description={e.description}
                  link={e.link}
                  backgroundimg={e.backgroundimg}
                  buttonText={read}
                />
              </CarouselNews>
            ))}
          </CarouselMobileNews>
        </Grid>
      </Hidden>
    </>
  );
};
