const menuItemsDektop = [
  {
    text: "Home",
    internalLink: "home",
    to: "/",
    available: true,
    footerShow: false,
  },
  {
    text: "WHAT IS PHOTIO?",
    internalLink: "/whatis",
    to: "/whatis",
    available: true,
    footerShow: true,
  },
  {
    text: "ABOUT US",
    internalLink: "/about",
    to: "/about",
    available: true,
    footerShow: true,
  },
  {
    text: "PROJECTS",
    internalLink: "/proyects",
    to: "/proyects",
    available: true,
    footerShow: true,
  },
  {
    text: "NANOFACTORY",
    internalLink: "/applications",
    to: "/applications",
    available: true,
    footerShow: true,
  },
  {
    text: "CONTACT US",
    internalLink: "/contact",
    to: "/contacts",
    available: true,
    footerShow: true,
  },
  {
    text: "FAQ (Frecuently Asked Questions)",
    internalLink: "/questions",
    to: "/questions",
    available: false,
    footerShow: true,
  },
];

export const menuDesktopContent = {
  menuItemsDektop,
};
