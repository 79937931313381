import styled from "styled-components";

const WhatisImageStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (min-width: 960px) {
    justify-content: center;
  }

  img {
    width: 100%;

    @media (min-width: 960px) {
      width: 80%;
      max-height: 400px;

    }
  }
`;

export { WhatisImageStyled };
