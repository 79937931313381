import COLORS from "../../../styles/global/colors";
import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";
import videoHowWork from "../../../constants/assets/whatIsView/videos/PhotioFuncionamiento.mp4";
import videoExplanation from "../../../constants/assets/whatIsView/videos/ExplicacionPhotio.mp4";

const howWorks = {
  positionText: "left",
  textContent: {
    upperTitle: "How does it work?",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    description: (
      <p>
        We created a technology <strong>to transform</strong> facades of
        buildings, roads and murals into huge <strong>air purifiers</strong>,
        generating a tangible environmental benefit and a{" "}
        <strong>positive impact on people’s health</strong>.
      </p>
    ),
  },
  mediaContent: {
    src: videoHowWork,
    type: "video/mp4",
    iframe: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/JHTb23go4ew"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
};

const explanation = {
  positionText: "Right",
  upperTitle: "Animación explicativo",
  iconUpperTitle: <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />,
  textContent: {
    description: (
      <p style={{ color: COLORS.white }}>
        Upon <strong>contact with any radiation source</strong>, whether natural
        as the sun, or artificial as the blisters lit inside your house, the
        nanometric-scale catalysts that make up Photio promote a photochemical
        reaction on the application surface which uses as reagents the moisture
        of the environment and the polluting gases present at the site,
        resulting in the constant degradation and transformation of these
        substances into substances that{" "}
        <strong>no longer pose an environmental or human health hazard</strong>.
      </p>
    ),
    description2: (
      <p style={{ color: COLORS.white }}>
        This process can be understood as a "stabilization" process, since gases
        are oxidized to a less reactive species, and therefore less toxic, which
        can be in a gaseous or solid phase, depending on the conditions of the
        environment.
      </p>
    ),
    description3: (
      <p style={{ color: COLORS.white }}>
        Now, this happens when we talk about gases, but Photio is also able to
        indirectly treat particulate material. But how? Photio does not degrade
        the particulate material, but if it is responsible for removing the
        precursor gases of these suspended particles, which promote their
        stability in the environment. Examples of these gases are Sulphur
        Dioxide (SO2) and Nitrogen Oxides (NOX).
      </p>
    ),
  },
  mediaContent: {
    title: "Now in a technical way:",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    src: videoExplanation,
    type: "video/mp4",
    iframe: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/jW4H7qNXUE8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
};

export const containerContent = {
  howWorks,
  explanation,
};
