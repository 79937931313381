import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { WhatisVideosOneStyled } from "../styles/WhatisVideoOneStyled";

const mediaChildren = ({ iframe }) => {
  return (
    <WhatisVideosOneStyled style={{ justifyContent: "center" }}>
      {iframe}
    </WhatisVideosOneStyled>
  );
};

const textBoxChildren = ({ upperTitle, iconUpperTitle, description }) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      style={{ color: COLORS.white, height: "100%" }}
    >
      <Hidden mdUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid
        item
        xs={10}
        style={{ display: "flex", gap: 20, flexDirection: "column" }}
      >
        <Typography variant="body1">
          {upperTitle}
          {iconUpperTitle}
        </Typography>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    </Grid>
  );
};

export const WhatisVideosOne = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ background: COLORS.greenBackground, padding: "40px 0px" }}
    >
      <Grid item xs={12} md={9}>
        <InformationComponent
          mediaChildren={mediaChildren(mediaContent)}
          textBoxChildren={textBoxChildren(textContent)}
          positionText={positionText}
          backgroundColor={COLORS.greenBackground}
        />
      </Grid>
    </Grid>
  );
};
