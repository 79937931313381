import React from "react";
import COLORS from "../../../styles/global/colors";
import { ButtonComponent } from "../../../components/globals/Button";
import {
  ButtonContainer,
  DescriptionContact,
  TitleSucessContact,
} from "../styles/ContactViewStyles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ContactFormSent = ({
  title,
  description,
  source,
  content,
  cleanStates,
}) => {
  const { newContact, back } = content;
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={10} md={9} style={{ marginTop: 20 }}>
        <img src={source} alt="checkGreen" />
        <TitleSucessContact>{title}</TitleSucessContact>
        <DescriptionContact>{description}</DescriptionContact>
        <ButtonContainer>
          <ButtonComponent
            type="primary"
            text={back}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.white}
            showIconLeft
            callToAction={() => {
              cleanStates();
              history.push("/home");
              window.scrollTo(0, 0);
            }}
          />
          <ButtonComponent
            type="secondary"
            text={newContact}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.blueTan}
            callToAction={() => {
              cleanStates();
              history.push("/contact");
              window.scrollTo(0, 0);
            }}
          />
        </ButtonContainer>
      </Grid>
    </Grid>
  );
};
