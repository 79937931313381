import styled from "styled-components";
import COLORS from "../../../../styles/global/colors";

const ContainerIndicatorComponent = styled.div`
  width: 47px;
  text-align: center;
  margin: 0px 10px;
  border-bottom: 2px solid
    ${(props) => (props.light ? COLORS.blueTan : COLORS.white)};
  padding: 0px 0px 9px;
  opacity: ${(props) => (props.active ? 1 : 0.35)};
`;

const ContainerPaginationComponent = styled.div`
  padding: 0px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: 960px) {
    padding: 0px 8% 40px;
  }
`;

const ValueIndicator = styled.p`
  color: ${(props) => (props.light ? COLORS.blueTan : COLORS.white)};
  font-size: 21px;
  margin: 0px;
  line-height: 19px;
  letter-spacing: 0.763763px;
  text-transform: uppercase;
`;

export {
  ContainerIndicatorComponent as ContainerIndicatorStyled,
  ValueIndicator as ValueStyled,
  ContainerPaginationComponent as ContainerPaginationStyled,
};
