import React, { useState } from "react";
import PreguntasAccordion from "./PreguntasAccordion";
import { PreguntasBody } from "./PreguntasBody";
import PreguntasSearch from "./PreguntasSearch";

export const PreguntasContainer = ({ content = undefined }) => {
  const {
    questionsContent: { faq },
    bodyContent,
  } = content;
  const [busqueda, setBusqueda] = useState("");
  const [resultados, setResultados] = useState(faq);

  if (!content) return null;

  const handleChange = (event) => {
    const busqueda = event.target.value;
    if (busqueda) {
      setBusqueda(busqueda);
    } else {
      setBusqueda(busqueda);
      setResultados(faq);
    }
  };

  const handleBuscarClick = () => {
    const resultados = faq.filter((elemento) =>
      elemento.pregunta.toLowerCase().includes(busqueda.toLowerCase())
    );
    setResultados(resultados);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      handleBuscarClick();
    }
  };

  return (
    <>
      <PreguntasSearch
        handleBuscarClick={handleBuscarClick}
        handleChange={handleChange}
        handleEnterPress={handleEnterPress}
        busqueda={busqueda}
        content={bodyContent}
      />
      <PreguntasBody content={bodyContent} />
      <PreguntasAccordion resultado={resultados} />
    </>
  );
};
