import React from "react";
import { WhatisBenefitsTitle } from "../WhatisBenefitsTitle/WhatisBenefitsTitle";

export const WhatisBenefitsTitleContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { benefits } = content;

  return <WhatisBenefitsTitle content={benefits} />;
};
