import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { ImageStyled } from "../styles/AplicationsView";

const mediaChildren = ({ source, alt }) => {
  return (
    <ImageStyled>
      <img src={source} alt={alt} />
    </ImageStyled>
  );
};

const textBoxChildren = ({
  upperTitle,
  iconUpperTitle,
  description,
  description2,
}) => {
  const colorText = COLORS.blueTan;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{ margin: "40px 0px 20px", height: "100%" }}
    >
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid item xs={10} md={11}>
        <Typography
          variant="body1"
          style={{
            fontSize: "20px",
            color: colorText,
          }}
        >
          {upperTitle}
          {iconUpperTitle}
        </Typography>
      </Grid>
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid
        item
        xs={10}
        md={11}
        style={{
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: colorText,
            fontWeight: 300,
            fontSize: "22px",
            letterSpacing: "3%",
          }}
        >
          {description}
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: colorText,
            fontWeight: 300,
            fontSize: "22px",
            letterSpacing: "3%",
          }}
        >
          {description2}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const AppBody = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
    />
  );
};
