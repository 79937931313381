import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
  ContainerStyle,
  HeaderStyle,
  HeaderActionsStyle,
  ActionStyle,
  BodyStyle,
  ItemStyle,
} from "./style";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@mui/icons-material/Close";
import COLORS from "../../../../styles/global/colors";
import LogoPhotio from "../../../../constants/assets/shared/menu/LogoPhotioMobile.svg";
import LogoPhotioWhite from "../../../../constants/assets/shared/menu/LogoPhotioWhite.svg";
import LanguageSelectorMobile from "../../LanguageSelector/LanguageSelectorMobile";
import { LanguageContext } from "../../../../contexts/LanguageContext";

export const MobileMenuContent = ({ theme = "light" }) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    menuMobileContent: { textContent, menuItemsMobile },
  } = dictionary.sharedContent;
  const [menuOpen, setMenuOpen] = useState(false);
  const [showCerrar, setShowCerrar] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(true);
    setShowCerrar(true);
  };

  const handleCloseClick = () => {
    setMenuOpen(false);
    setShowCerrar(false);
  };

  return (
    <ContainerStyle>
      <HeaderStyle>
        <img
          src={theme === "light" ? LogoPhotio : LogoPhotioWhite}
          alt="Logo Photio"
        />
        <HeaderActionsStyle>
          {!menuOpen && (
            <ActionStyle onClick={handleMenuClick}>
              <MenuIcon
                fontSize="medium"
                style={{ color: COLORS.blueTan, marginRight: "5px" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: COLORS.blueTan,
                }}
              >
                {textContent.menu}
              </Typography>
            </ActionStyle>
          )}
          {menuOpen && (
            <ActionStyle onClick={handleCloseClick}>
              <CloseIcon
                fontSize="medium"
                style={{ color: COLORS.blueTan, marginRight: "5px" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontSize: "20px",
                  fontWeight: 400,
                  fontFamily: "Roboto",
                  color: COLORS.blueTan,
                }}
              >
                {textContent.close}
              </Typography>
            </ActionStyle>
          )}
        </HeaderActionsStyle>
      </HeaderStyle>
      {/* BodyMenu */}
      <BodyStyle>
        {menuOpen && (
          <>
            {menuItemsMobile.map(
              (item, index) =>
                item.available && (
                  <ItemStyle
                    light={theme === "light"}
                    key={`menuItemsMobile_${index}`}
                  >
                    <NavLink
                      key={`menuItemsMobile_${index}`}
                      to={item.internalLink}
                      activeClassName="selectedItem"
                    >
                      <Typography variant="body1">{item.text}</Typography>
                    </NavLink>
                  </ItemStyle>
                )
            )}
            <ItemStyle>
              <LanguageSelectorMobile />
            </ItemStyle>
          </>
        )}
      </BodyStyle>
    </ContainerStyle>
  );
};
