import styled from "styled-components";
import COLORS from "../../../styles/global/colors";

const TitleContact = styled.p`
  color: ${COLORS.blueTan};
  font-size: 38px;
  line-height: 1;
`;

const HighlightContact = styled.p`
  color: ${COLORS.purpleBright};
  font-size: 38px;
  font-weight: 500;
  line-height: 1;
  margin: 0px;

  @media (min-width: 960px) {
    font-size: 48px;
  }
`;

const TitleSucessContact = styled.p`
  color: ${COLORS.blueTan};
  font-weight: 300;
  font-size: 36px;
  letter-spacing: 0.02em;
  line-height: 42px;
`;

const DescriptionContact = styled.p`
  color: ${COLORS.blueTan};
  font-weight: 300;
  font-size: 19px;
  letter-spacing: 0.02em;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 40px;

  @media (min-width: 960px) {
    flex-direction: row;
    gap: 20px;
    margin-top: 0px;
  }
  

`;

export { TitleContact, HighlightContact, DescriptionContact, TitleSucessContact, ButtonContainer };
