import React from "react";
import { ContactBody } from "./ContactBody";

export const ContactContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { contactForm, rrss, contact } = content;

  return (
    <ContactBody contactForm={contactForm} rrss={rrss} contact={contact} />
  );
};
