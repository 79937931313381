const COLORS = {
  greyDark: "#545864",
  greyCool: "#d9d8d6",
  greyPearl: "#eeedee",
  white: "#ffffff",
  blueTan: "#002061",
  purpleBright: "#3040E3",
  lilac: "#B58EF8",
  greenTitle: "#11693D",
  greenDark: "#0A532E",
  greenBackground: "#0A7C53",
  greenLight: "#55AA7F",
  yellow: "#E6B03C",
  orange: "#F96A25",
  proyectColor: "#EEEDEE"
};

export default COLORS
