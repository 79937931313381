import styled from "styled-components";

const ExplanationStyled = styled.div`
width: 100%;

@media (min-width: 960px) {
  width: 96%;
}
`;

export { ExplanationStyled };
