import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Hidden } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export default function WhatisAccordion({ arrayFaq }) {
  return (
    <>
      <Grid container direction="column" justifyContent="center">
        <Hidden mdDown>
          {arrayFaq.map((faq, index) => (
            <Accordion
              key={`WhatIsFaq_${index}`}
              style={{
                color: COLORS.blueTan,
                marginLeft: "150px",
                width: "80%",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{faq.pregunta}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "18px", textAlign: "justify" }}>
                  {faq.respuesta}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Hidden>
        <Hidden mdUp>
          {arrayFaq.map((faq, index) => (
            <Accordion
              key={`WhatIsFaqMobile_${index}`}
              style={{
                color: COLORS.blueTan,
                marginLeft: "20px",
                width: "103%",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{faq.pregunta}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "18px", textAlign: "justify" }}>
                  {faq.respuesta}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Hidden>
      </Grid>
    </>
  );
}
