import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import COLORS from "../../../styles/global/colors";

const textContent = {
  upperTitle: "Our projects",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
};

const buttonContent = {
  nextButton: 'Next Project',
  forwardButton: 'Forward Project',
}

export const titleDetailsContent = {
  textContent,
  buttonContent,
};
