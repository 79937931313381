import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { ButtonComponent } from "../../../components/globals/Button";
import COLORS from "../../../styles/global/colors";
import { ProyectBox } from "../../../components/ProyectBox";

export const ProyectDetailsFunction = ({ noticias, buttonContent }) => {
  const { nextButton, forwardButton } = buttonContent;
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const handlePrev = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? noticias.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % noticias.length);
  };

  return (
    <>
      <ProyectBox
        description={noticias[currentProjectIndex].description}
        title={noticias[currentProjectIndex].title}
        backgroundimg={noticias[currentProjectIndex].backgroundimg}
      />
      <Hidden mdDown>
        <Grid
          container
          justifyContent="flex-end"
          direction="row"
          style={{ marginTop: "24px" }}
        >
          <Grid
            item
            xs={5}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ButtonComponent
              type="secondary"
              text={forwardButton}
              margin={"10px 0px"}
              backgroundColor={COLORS.blueTan}
              colorText={COLORS.blueTan}
              showIconLeft
              callToAction={() => {
                handlePrev(currentProjectIndex);
              }}
            ></ButtonComponent>
            <ButtonComponent
              type="secondary"
              text={nextButton}
              margin={"10px 0px"}
              backgroundColor={COLORS.blueTan}
              colorText={COLORS.blueTan}
              showIconRight
              callToAction={() => {
                handleNext(currentProjectIndex);
              }}
            ></ButtonComponent>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          justifyContent="center"
          direction="row"
          style={{ marginTop: "50px" }}
        >
          <ButtonComponent
            type="secondary"
            text="Proyecto Anterior"
            margin={"10px 0px"}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.blueTan}
            showIconLeft
            callToAction={() => {
              handlePrev(currentProjectIndex);
            }}
          ></ButtonComponent>
          <ButtonComponent
            type="secondary"
            text="Siguiente Proyecto"
            margin={"10px 0px"}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.blueTan}
            showIconRight
            callToAction={() => {
              handleNext(currentProjectIndex);
            }}
          ></ButtonComponent>
        </Grid>
      </Hidden>
    </>
  );
};
