import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";
import photioVideo from "../../../constants/assets/aboutView/video/clipweb.mp4";

const aboutVideo = {
  positionText: "Right",
  textContent: {
    description: (
      <p>
        <strong>
          Agrupando a ingenieros, químicos, investigadores y académicos
          altamente capacitados
        </strong>
        , creamos una tecnología capaz de combatir la contaminación atmosférica.
      </p>
    ),
    description2: (
      <p>
        Utilizando como base la ciencia y la nanotecnología, y motivados por
        generar un impacto real en la vida de las personas, desarrollamos una
        innovación única con el fin de dejar una huella positiva en la sociedad.
      </p>
    ),
    upperTitle: "Nuestra esencia",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  mediaContent: {
    src: photioVideo,
    type: "video/mp4",
  },
};

export const videoContent = {
  aboutVideo,
};
