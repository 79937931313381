import sachet from "../../../constants/assets/contactView/SachetFinal.png";
import checkGreen from "../../../constants/assets/contactView/CheckGreen.png";
import errorRed from "../../../constants/assets/contactView/ErrorRed.png";
import Youtubegris from "../../../constants/assets/contactView/Youtubefinal.svg";
import Twittergris from "../../../constants/assets/contactView/Twitterfinal.svg";
import Instagramgris from "../../../constants/assets/contactView/Instagram3.svg";
import Facebookgris from "../../../constants/assets/contactView/Facebookfinal.svg";
import Photiogris from "../../../constants/assets/contactView/Photiofinal.svg";

const contact = {
  positionText: "left",
  textContent: {
    title:
      "¿Quieres desarrollar un proyecto con Photio o te interesa colaborar con nosotros?",
    bottomTitle: "¡Juntémonos a conversar!",
    description:
      "Completa el formulario con toda la información requerida. Nuestro equipo responderá todas tus dudas e inquietudes.",
  },
  mediaContent: {
    source: sachet,
    alt: "Sachet Photio",
  },
};

const contactForm = {
  reason: {
    title: "¿Qué necesitas?",
    options: [
      "Quiero ocupar el producto en mi empresa",
      "Realizaré un mural y necesito Photio",
      "Necesito una reunión de presentación",
      "Nuevas Aplicaciones",
      "Me gustaría entrevistarlos",
      "Otro asunto",
    ],
    helper: "Elige una opción",
    placeholder: "",
    validators: {
      base: "Seleccione un mótivo",
      require: "Mótivo es requerido",
    },
  },
  name: {
    title: "Nombre Completo",
    validators: {
      base: "Ingrese su nombre",
      require: "Nombre es requerido",
      min: "Ingrese a lo menos 3 carácteres",
    },
  },
  email: {
    title: "Correo electrónico",
    helper: "ej: nombre@gmail.com",
    placeholder: "contacto@photio.com",
    validators: {
      base: "Ingrese su email",
      require: "Email es requerido",
      email: "Ingrese un email válido",
    },
  },
  phone: {
    title: "Número de teléfono",
    helper: "Ejemplo: +56 (9) 1234-5678",
    placeholder: "56912345678",
    validators: {
      base: "Ingrese su número telefónico",
      require: "Número telefónico requerido",
      max: "Número excede el máximo de carácteres permitidos",
      match: "Ingrese un número teléfonico valido",
    },
  },
  text: {
    title: "Mensaje",
    validators: {
      base: "Ingrese su mensaje",
      require: "Mensaje es requerido",
      min: "Ingrese a lo menos 10 carácteres",
    },
  },
  button: {
    valid: "Enviar",
    sending: "Enviando...",
  },
  successView: {
    title: "Tu mensaje se envío con éxito, pronto te contestaremos",
    description: "Recuerda revisar también tu carpeta de SPAM.",
    source: checkGreen,
  },
  errorView: {
    title:
      "Tenemos algunas dificultades actualmente. Vuelve a enviar tu mensaje más tarde.",
    description: "Te pedimos disculpas por las molestias.",
    source: errorRed,
  },
  back: "Volver al inicio",
  newContact: "Enviar otro mensaje",
};

const rrss = [
  {
    src: Photiogris,
    alt: "imgLogo",
    url: "",
  },
  {
    url: "https://www.youtube.com/@photio8182",
    src: Youtubegris,
    alt: "youtubeLogo",
  },
  {
    url: "https://twitter.com/photiocl?s=21&t=UR7RmmRJUJZ-xQtr5O-ZGw",
    src: Twittergris,
    alt: "twitterLogo",
  },
  {
    url: "https://www.instagram.com/photio.cl/",
    src: Instagramgris,
    alt: "instagramLogo",
  },
  {
    url: "https://www.facebook.com/PhotioCL/",
    src: Facebookgris,
    alt: "facebookLogo",
  },
];

export const contactContent = { contact, rrss, contactForm };
