import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import Fotoapp from "../../../constants/assets/applicationView/application/nanofactoria.jpg";

const application = {
  positionText: "left",
  textContent: {
    description: (
      <p>
        <strong>Photio</strong> can be{" "}
        <strong>used in different types of coatings and materials</strong>{" "}
        without changing any of its physical or chemical properties. You can
        apply it directly to walls by painting, mixing it in asphalt or
        concrete, and even dosing it in production processes for fabrics,
        slippers and synthetic leather.
      </p>
    ),
    description2: (
      <p>
        But to do this, we must make certain adjustments to our formulation to
        make it compatible and efficient in each material, for this reason our
        Nanofactory was born.
      </p>
    ),
    upperTitle: "Where can we apply Photio?",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  mediaContent: {
    source: Fotoapp,
    alt: "Foto",
  },
};

export const applicationContent = { application };
