import styled from "styled-components";
import COLORS from "../../../styles/global/colors";

const ContainerStyleComponent = styled.div`
  color: ${(props) => (props.home ? COLORS.white : COLORS.blueTan)};
  margin: 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ButtonStyleComponent = styled.div`
  cursor: pointer;
  background: none;
  border: none;
`;

const ButtonTitleStyleComponent = styled.p`
  color: ${(props) => (props.home ? COLORS.white : COLORS.blueTan)};
  font-size: 16px;
  font-family: Barlow, sans-serif;
  margin: 0px;
  font-weight: ${(props) => (props.selected ? 600 : 200)};
  border-bottom: ${(props) =>
    props.selected
      ? `1px solid ${props.home ? COLORS.white : COLORS.blueTan}`
      : "none"};
`;

const OptionMobileStyledComponent = styled.p`
  color: ${(props) => (props.home ? COLORS.white : COLORS.blueTan)};
  font-size: 16px;
  font-family: Barlow, sans-serif;
  margin: 0px;
`;

export {
  ContainerStyleComponent as ContainerStyle,
  ButtonStyleComponent as ButtonStyle,
  ButtonTitleStyleComponent as ButtonTitleStyle,
  OptionMobileStyledComponent as OptionMobileStyled,
};
