import React from "react";
import { AppNano } from "./AppNano";
import { AppNanoTitle } from "./AppNanoTitle";

export const AppNanoContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { titleContent, descriptionContent } = content;
  return (
    <>
      <AppNanoTitle content={titleContent} />
      <AppNano content={descriptionContent} />
    </>
  );
};
