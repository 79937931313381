import React from "react";
import { WhatisBenefits } from "../WhatisBenefits/WhatisBenefits";

export const WhatisBenefitsContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { benefits } = content;

  return <WhatisBenefits content={benefits} />;
};
