import COLORS from "../../../styles/global/colors";
import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";
import videoHowWork from "../../../constants/assets/whatIsView/videos/PhotioFuncionamiento.mp4";
import videoExplanation from "../../../constants/assets/whatIsView/videos/ExplicacionPhotio.mp4";

const howWorks = {
  positionText: "left",
  textContent: {
    upperTitle: "¿Cómo Funciona?",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    description: (
      <p>
        Creamos una tecnología <strong>para transformar</strong> fachadas de
        edificios, carreteras y murales en enormes{" "}
        <strong>purificadores de aire</strong>, generando un beneficio tangible
        a nivel medioambiental y un{" "}
        <strong>impacto positivo en la salud de las personas.</strong>
      </p>
    ),
  },
  mediaContent: {
    src: videoHowWork,
    type: "video/mp4",
    iframe: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/JHTb23go4ew"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
};

const explanation = {
  positionText: "Right",
  upperTitle: "Animación explicativo",
  iconUpperTitle: <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />,
  textContent: {
    description: (
      <p style={{ color: COLORS.white }}>
        Al <strong>contacto con cualquier fuente de radiación</strong>, ya sea
        natural como el sol, o artificial como las ampolletas encendidas en el
        interior de tu casa, los catalizadores en escala nanométrica que
        componen Photio promueven una reacción fotoquímica en la superficie de
        aplicación la cual utiliza como reactivos la humedad del ambiente y los
        gases contaminantes presentes en el lugar, generando como resultado la
        degradación y transformación constante de estos últimos en sustancias
        que ya
        <strong>
          {" "}
          no representan un peligro a nivel medioambiental o en la salud de las
          personas.{" "}
        </strong>
      </p>
    ),
    description2: (
      <p style={{ color: COLORS.white }}>
        Este proceso se puede entender como un proceso de ¨estabilización¨, ya
        que los gases son oxidados a una especie menos reactiva, y por ende
        menos tóxica, la cual puede estar en fase gaseosa o sólida, dependiendo
        de las condiciones del medio.
      </p>
    ),
    description3: (
      <p style={{ color: COLORS.white }}>
        Ahora bien, esto ocurre cuando hablamos de gases, pero Photio también es
        capaz de tratar de manera indirecta material particulado. Pero, ¿cómo?
        Photio no degrada el material particulado, pero si se encarga de
        eliminar los gases precursores de estas particulas en suspensión, los
        cuales promueven su estabilidad en el ambiente. Algunos ejemplos de
        estos gases son el Dióxido de Azufre (SO2) y los Óxidos de Nitrógeno
        (NOX).
      </p>
    ),
  },
  mediaContent: {
    title: "Ahora técnicamente",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    src: videoExplanation,
    type: "video/mp4",
    iframe: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/jW4H7qNXUE8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
};

export const containerContent = {
  howWorks,
  explanation,
};
