import styled from "styled-components";
import COLORS from "../../../../styles/global/colors";

const ContainerStyleComponent = styled.div`
  display: "flex";
  flex-direction: "column";
  width: 100%;

  span {
    color: ${(props) => (props.light ? COLORS.blueTan : COLORS.white)};
  }

  svg {
    color: ${(props) => (props.light ? COLORS.blueTan : COLORS.white)};
  }
`;

const HeaderStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 16px;

  img {
    height: 15px;
  }
`;

const HeaderActionsStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ActionStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 0px 8px;

  > span {
    margin-left: 8px;
  }
`;

const BodyStyleComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
  }

  span {
    color: ${(props) => (props.light ? COLORS.blueTan : COLORS.blueTan)};
  }
  

  span:focus,
  span:active {
    color:${(props) => (props.disabled ? COLORS.orange : COLORS.orange)}
  
  
  &:hover
    ${(props) => (props.disabled ? "{}" : "{span {font-weight: 700;}}")}
  &:focus 
    ${(props) =>
      props.disabled
        ? "{}"
        : `{span {color: ${COLORS.orange}; font-weight: 700; }}`}
  }
`;

const ItemStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${COLORS.greyCool};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};
  width: 100%;

  p {
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.2em;
    font-size: 14px;
    text-decoration: none;
    color: ${COLORS.white};
  }

  .selectedItem {
    p {
      color: ${COLORS.orange};
      font-weight: 700;
    }
  }
`;

export {
  ContainerStyleComponent as ContainerStyle,
  HeaderStyleComponent as HeaderStyle,
  HeaderActionsStyleComponent as HeaderActionsStyle,
  ActionStyleComponent as ActionStyle,
  BodyStyleComponent as BodyStyle,
  ItemStyleComponent as ItemStyle,
};
