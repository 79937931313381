import React from "react";
import { FirstContent } from "./FirstContent";
import { SecondContent } from "./SecondContent";

export const InformationContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { firstItem, secondItem } = content;
  return (
    <>
      <FirstContent content={firstItem} />
      <SecondContent content={secondItem} />
    </>
  );
};
