import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import COLORS from "../../styles/global/colors";

export const ProyectBox = ({ backgroundimg, title, description }) => {
  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="row"
          style={{ margin: "10px 0px 20px" }}
        >
          <Grid
            item
            xs={5}
            style={{
              marginBottom: "50px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: COLORS.blueTan,
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "36px",
                letterSpacing: "3%",
                width: '90%'
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: COLORS.blueTan,
                fontWeight: 300,
                fontSize: "18px",
                lineHeight: "36px",
                letterSpacing: "3%",
                marginTop: "20px",
                width: '90%'
              }}
            >
              {description}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <img
              src={backgroundimg}
              alt="img1"
              style={{ display: "flex", width: "90%" }}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="center"
          style={{ display: "flex", gap: 30 }}
        >
          <Grid item xs={10}>
            <img src={backgroundimg} alt="img1" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body1"
              style={{
                color: COLORS.blueTan,
                fontWeight: 500,
                fontSize: "28px",
                letterSpacing: "3%",
                width: '90%',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              variant="body1"
              style={{
                color: COLORS.blueTan,
                fontWeight: 300,
                fontSize: "18px",
                lineHeight: "36px",
                letterSpacing: "3%",
              }}
            >
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
