import { Typography } from "@material-ui/core";
import React from "react";
import COLORS from "../../../../styles/global/colors";
import { FirstBackground, SecondBackground, MainBackground } from "./style";

export const TextInformation = ({
  title = undefined,
  description = undefined,
  children = undefined
}) => {
  return (
    <FirstBackground>
      <SecondBackground>
        <MainBackground>
          {title && (
            <Typography variant="h1" style={{ color: COLORS.white}}>
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body1"
              style={{ color: COLORS.white }}
            >
              {description}
            </Typography>
          )}
          {children}
        </MainBackground>
      </SecondBackground>
    </FirstBackground>
  );
};
