import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import COLORS from "../../styles/global/colors";

export const NewsBox = ({ title, backgroundimg }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: 400, width: 250, margin: "0px 16px", borderRadius: 8 }}
    >
      <Grid
        item
        xs={12}
        style={{
          height: 250,
          background: `url(${backgroundimg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        style={{
          height: 150,
          background: COLORS.blueTan,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="space-between"
          style={{ height: "100%", width: "100%", padding: 9 }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body2"
              style={{
                color: COLORS.white,
                fontWeight: 600,
                fontSize: "17px",
                wordBreak: "break-word",
                whiteSpace: 'normal',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <ArrowForwardIcon style={{ color: COLORS.white }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
