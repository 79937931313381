import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";
import photioVideo from "../../../constants/assets/aboutView/video/clipweb.mp4";

const aboutVideo = {
  positionText: "Right",
  textContent: {
    description: (
      <p>
        <strong>
          Bringing together highly trained engineers, chemists, researchers and
          academics
        </strong>
        , we create a technology capable of combating air pollution.
      </p>
    ),
    description2: (
      <p>
        Using science and nanotechnology as a foundation, and motivated by a
        real impact on people’s lives, we develop a unique innovation in order
        to leave a positive footprint in society.
      </p>
    ),
    upperTitle: "Our Essence",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  mediaContent: {
    src: photioVideo,
    type: "video/mp4",
  },
};

export const videoContent = {
  aboutVideo,
};
