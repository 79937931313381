import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import {
  MediaPaintComponentStyled,
  StepsContainerStyled,
} from "../styles/AplicationsView";

const mediaChildren = ({ src, alt, title }) => {
  return (
    <MediaPaintComponentStyled>
      <img src={src} alt={alt} />
      <Typography variant="body1">{title}</Typography>
    </MediaPaintComponentStyled>
  );
};

const textBoxChildren = ({
  upperTitle,
  iconUpperTitle,
  title,
  step1,
  step2,
  step3,
}) => {
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="flex-start"
      style={{ color: COLORS.white }}
    >
      <Grid
        item
        xs={10}
        md={9}
        style={{ display: "flex", flexDirection: "column", gap: 40 }}
      >
        <Typography
          variant="body1"
          style={{
            fontWeight: 300,
            fontSize: "18px",
          }}
        >
          {upperTitle}
          {iconUpperTitle}
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontWeight: 500,
            fontSize: "32px",
            lineHeight: "36px",
            letterSpacing: "1%",
          }}
        >
          {title}
        </Typography>
        <StepsContainerStyled>
          <Typography variant="body1">{step1}</Typography>
          <Typography variant="body1">{step2}</Typography>
          <Typography variant="body1">{step3}</Typography>
        </StepsContainerStyled>
      </Grid>
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
    </Grid>
  );
};

export const AppTutorialBody = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
      backgroundColor={COLORS.greenBackground}
    />
  );
};
