import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { HomeView } from './views/HomeView';
import { AboutView } from './views/AboutView';
import { ApplicationView } from './views/ApplicationView';
import { ContactView } from './views/ContactView';
import { WhatisView } from "./views/WhatisView";
import { FaqView } from "./views/FaqView";
import { ProyectView } from "./views/ProyectView";


export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/questions" component={ FaqView } />
        <Route exact path="/whatis" component={ WhatisView } />
        <Route exact path="/about" component={ AboutView } />
        <Route exact path="/applications" component={ ApplicationView } />
        <Route exact path="/contact" component={ ContactView } /> 
        <Route exact path="/proyects" component={ ProyectView } />
        <Route path="/" component={ HomeView } /> 
      </Switch>
    </Router>
  );
}
