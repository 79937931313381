import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import COLORS from "../../styles/global/colors";

export const AppBox = ({ title, backgroundimg }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: 400, width: 220, margin: "0px 16px 70px" }}
    >
      <Grid item xs={12} style={{ height: "30%" }}>
        <img
          src={backgroundimg}
          alt="ing1"
          style={{
            height: "210%",
            width: "100%",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          height: "30%",
          marginTop: "100px",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          background: COLORS.purpleBright,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          style={{ height: "100%", padding: 9 }}
        >
          <Grid item>
            <Typography
              variant="body2"
              style={{
                color: COLORS.white,
                fontWeight: 600,
                fontSize: "15px",
                margin: "0px 10px",
                wordBreak: "break-word",
                width: "90%",
                whiteSpace: 'normal',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <ArrowForwardIcon style={{ color: COLORS.white }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
