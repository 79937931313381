import Grupo1 from "../../../constants/assets/homeView/project/clientes/Grupo1.png";
import Grupo2 from "../../../constants/assets/homeView/project/clientes/Grupo2.png";
import Grupo3 from "../../../constants/assets/homeView/project/clientes/Grupo3.png";
import Principal from '../../../constants/assets/homeView/project/projects/Principal.png';
import Principalcorredor from '../../../constants/assets/homeView/project/projects/Principalcorredor.png';
import Principalbupa from '../../../constants/assets/homeView/project/projects/Principalbupa.png';
import Principalbullet from '../../../constants/assets/homeView/project/projects/Principalbullet.png'

const titleContent = {
  title: "Muchos se han sumado al cambio, y tú ¿Qué esperas?",
  logos: [
    {
      src: Grupo1,
      alt: "grupo1Companies",
    },
    {
      src: Grupo2,
      alt: "grupo2Companies",
    },
    {
      src: Grupo3,
      alt: "grupo3Companies",
    },
  ],
};

const bodyContent = {
  proyectShow: [
    {
      title:
        "Vías Sustentables | Proyecto desarrollado por Photio para VíasChile",
      backgroundimg: Principal,
    },
    {
      title: "Corredor Ártistico Sustentable | Proyecto desarrollado por Metro",
      backgroundimg: Principalcorredor,
    },
    {
      title: "Centros de Salud | Proyecto desarrollado por Photio para BUPA",
      backgroundimg: Principalbupa,
    },
    {
      title:
        "Circuito de murales | Proyecto desarrollado por Lira Arte Público para Bulleit Bourbon",
      backgroundimg: Principalbullet,
    },
  ],
};

const buttonContent = {
  title: 'Ver más proyectos'
}

export const projectContent = {
  titleContent,
  bodyContent,
  buttonContent,
};
