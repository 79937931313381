import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Hidden } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export default function PreguntasAccordion({ resultado }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      style={{ marginTop: "80px", marginBottom: "80px" }}
    >
      {resultado.map((elemento, index) => (
        <div key={`FAQ_${index}`}>
          <Hidden mdDown>
            <Accordion
              style={{
                color: COLORS.blueTan,
                marginLeft: "200px",
                marginRight: "200px",
                textAlign: "justify",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{elemento.pregunta}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "18px" }}>
                  {elemento.respuesta}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Hidden>
          <Hidden mdUp>
            <Accordion
              style={{
                color: COLORS.blueTan,
                marginLeft: "20px",
                width: "90%",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: "18px" }}>
                  <strong>{elemento.pregunta}</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography style={{ fontSize: "18px" }}>
                  {elemento.respuesta}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Hidden>
        </div>
      ))}
    </Grid>
  );
}
