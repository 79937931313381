const menuItemsDektop = [
  {
    text: "Home",
    internalLink: "home",
    to: "/",
    available: true,
    footerShow: false,
  },
  {
    text: "Qué es Photio",
    internalLink: "/whatis",
    to: "/whatis",
    available: true,
    footerShow: true,
  },
  {
    text: "Quiénes somos",
    internalLink: "/about",
    to: "/about",
    available: true,
    footerShow: true,
  },
  {
    text: "Proyectos",
    internalLink: "/proyects",
    to: "/proyects",
    available: true,
    footerShow: true,
  },
  {
    text: "NanoFactoría",
    internalLink: "/applications",
    to: "/applications",
    available: true,
    footerShow: true,
  },
  {
    text: "Contacto",
    internalLink: "/contact",
    to: "/contacts",
    available: true,
    footerShow: true,
  },
  {
    text: "Preguntas Frecuentes",
    internalLink: "/questions",
    to: "/questions",
    available: false,
    footerShow: true,
  },
];

export const menuDesktopContent = {
  menuItemsDektop,
};
