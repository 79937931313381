import { applyContent } from "./apply";
import { carrouselContent } from "./carrousel";
import { informationContent } from "./information";
import { projectContent } from "./project";
import { contactContent } from "./contact";

export const homeViewContent = {
  carrouselContent,
  informationContent,
  applyContent,
  projectContent,
  contactContent,
}

