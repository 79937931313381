import React from "react";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  ButtonComponent,
  textThirdLightStyle,
  textThirdDarkStyle,
  iconLightStyle,
  textStyle,
} from "./styles";

const ThirdButton = ({
  buttonType,
  theme,
  text,
  callToAction,
  showIconLeft,
  showIconRight,
  disabled,
  margin,
  colorText,
  iconLeft,
  iconRight,
}) => {

  const iconLeftComponent = iconLeft ? <img src={iconLeft} alt='iconLeft' /> : <ArrowBackIcon style={{...iconLightStyle, color: colorText}} />
  const iconRightComponent = iconRight ? <img src={iconRight} alt='iconRight' /> : <ArrowForwardIcon style={{...iconLightStyle, color: colorText}} />

  return (
    <ButtonComponent disabled={disabled} disableElevation onClick={callToAction} light={theme === 'light'} margin={margin} type={buttonType} >
      {showIconLeft && iconLeftComponent}
      {text && (
        <Typography
          variant="button"
          style={colorText ? { ...textStyle, color: colorText } : theme === "light" ? textThirdLightStyle : textThirdDarkStyle}

        >
          {text}
        </Typography>
      )}
      {showIconRight && iconRightComponent}
    </ButtonComponent>
  )
};

export default ThirdButton;
