import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import COLORS from "../../styles/global/colors";

export const NanoBox = ({ title, source, text, description }) => {
  return (
    <>
      <Hidden mdDown>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            margin: "0px 16px 50px",
            background: COLORS.greyPearl,
            borderRadius: 8,
            padding: "20px 10px",
            gap: 15,
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: COLORS.purpleBright,
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            {title}
          </Typography>
          <img
            src={source}
            alt="ing1"
            style={{ height: 100, }}
          />
          <Typography
            variant="body2"
            style={{ color: COLORS.blueTan, fontWeight: 600, fontSize: "17px" }}
          >
            {text}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: COLORS.blueTan,
              fontWeight: 300,
              fontSize: "17px",
              lineHeight: "26px",
              textAlign: 'center'
            }}
          >
            {description}
          </Typography>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          justifyContent="center"
          style={{ width: "100%", height: "100%", marginBottom: "20px" }}
        >
          <Grid
            item
            xs={10}
            style={{
              background: COLORS.greyPearl,
              borderRadius: "8px",
              padding: "16px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: '20%'
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: COLORS.purpleBright,
                  fontWeight: 600,
                  marginBottom: "8px",
                }}
              >
                {title}
              </Typography>
              <img src={source} alt="ing1" style={{ height: 30 }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: '75%'
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: COLORS.blueTan,
                  fontWeight: 600,
                  marginBottom: "8px",
                  alignSelf: "flex-start",
                }}
              >
                {text}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: COLORS.blueTan,
                  fontWeight: 300,
                  alignSelf: "flex-start",
                  wordBreak: 'break-word'
                }}
              >
                {description}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
