import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { AppApplyBody } from "./AppApplyBody";
import { AppTutorialBody } from "../AppTutorial/AppTutorialBody";
import { AppApplyHormigonBody } from "../AppApplyHormigon/AppApplyHormigonBody";
import COLORS from "../../../styles/global/colors";

export const AppApplyContainer = ({ content = undefined }) => {
  const [showDetails, setShowDetails] = useState(false);
  if (!content) return null;

  const { textContent, items, paintTutorial, otherTutorial } = content;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={9}>
        <AppApplyBody
          textContent={textContent}
          items={items}
          setShowDetails={setShowDetails}
        />
      </Grid>
      {showDetails && (
        <Grid
          item
          xs={12}
          style={{ padding: "58px 0px", background: COLORS.greenBackground }}
        >
          {showDetails === "paint" && (
            <AppTutorialBody content={paintTutorial} />
          )}
          {showDetails === "block" && (
            <AppApplyHormigonBody content={otherTutorial} />
          )}
        </Grid>
      )}
    </Grid>
  );
};
