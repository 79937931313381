import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import BCV10 from "../../../constants/assets/aboutView/valores/BCV10.svg";
import LogoVersatilidad from "../../../constants/assets/aboutView/valores/LogoVersatilidad.png";
import LogoColaboracion from "../../../constants/assets/aboutView/valores/LogoColaboracion.png";

const aboutValues = {
  textContent: {
    upperTitle: "Nuestros Valores",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  items: [
    {
      source: BCV10,
      alt: "Base científica",
      title: "Base científica",
      description:
        "A través de la ciencia y la tecnología, diariamente nuestro equipo desarrolla nuevas investigaciones aplicadas y mejoras para nuestra tecnología.",
    },
    {
      source: LogoVersatilidad,
      alt: "Versatilidad",
      title: "Versatilidad",
      description:
        " La adaptabilidad y versatilidad son la base de nuestros desarrollos, y lo que nos ha permitido transformar a Photio en una plataforma de descontaminación.",
    },
    {
      source: LogoColaboracion,
      alt: "Colaboración",
      title: "Colaboración",
      description:
        "En Photio creemos que a través de la colaboración y el trabajo en equipo, somos capaces de abordar cualquier desafío. La colaboración es el corazón de Photio.",
    },
  ],
};

export const valoresContent = { aboutValues };
