import COLORS from '../../../styles/global/colors';

import { ReactComponent as ArrowCornerBottom } from '../../../constants/assets/shared/arrowCornerBottomWhite.svg';

import { ReactComponent as AsfaltoRosa } from '../../../constants/assets/shared/asfaltoblanco.svg';
import { ReactComponent as CueroAmarillo } from '../../../constants/assets/homeView/apply/cueroblanco3.svg';
import { ReactComponent as GotaVerde } from '../../../constants/assets/homeView/apply/gotablanca.svg';
import { ReactComponent as HormigonBlanco } from '../../../constants/assets/homeView/apply/hormigonBlancoo.svg';
import { ReactComponent as TelaVerde } from '../../../constants/assets/homeView/apply/telablanca2.svg';

const textContent = {
  upperTitle: "Where does Photio apply?",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
  secondaryButtonText: "Learn More",
};

const items = [
  {
    source: <GotaVerde />,
    alt: "Painting",
    title: "Painting",
  },
  {
    source: <HormigonBlanco />,
    alt: "Concrete",
    title: "Concrete",
  },
  {
    source: <CueroAmarillo />,
    alt: "Leather",
    title: "Synthetic Leather",
  },
  {
    source: <TelaVerde />,
    alt: "Fabric",
    title: "Fabric",
  },
  {
    source: <AsfaltoRosa />,
    alt: "Asphalt",
    title: "Asphalt",
  },
];

export const applyContent = {
  textContent,
  items,
};
