import ZapatillaKruza from "../../../constants/assets/applicationView/partners/ZapatillaKruza.png";
import Asfalto from "../../../constants/assets/applicationView/partners/asfalto.png";
import Fotodecueros from "../../../constants/assets/applicationView/partners/Fotodecueros.png";
import Siding from "../../../constants/assets/applicationView/partners/siding.png";
import Hormigon from "../../../constants/assets/applicationView/partners/hormigon.png";

const titleContent = {
  title: "Photio applications that we have developed with our partners",
  description:
    "Here are some of the applications that we have already managed to develop and validate, and that will soon be on sale!",
};

const descriptionContent = {
  proyectShow: [
    {
      title: "Asphalt mixture",
      backgroundimg: Asfalto,
    },
    {
      title: "Slippers",
      backgroundimg: ZapatillaKruza,
    },
    {
      title: "Artificial Leather and Technical Coatings",
      backgroundimg: Fotodecueros,
    },
    {
      title: "Fiber cement Siding",
      backgroundimg: Siding,
    },
    {
      title: "Concrete and Mortar",
      backgroundimg: Hormigon,
    },
  ],
};

export const partnersContent = {
  titleContent,
  descriptionContent,
};
