import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { UpperTitleImageContainerStyled } from "../styles/InformationContainerStyles";
import { ButtonComponent } from "../../../components/globals/Button";
import { WhatisButon } from "../styles/WhatisButon";

const mediaChildren = ({ textContent }) => {
  const colorTitle = COLORS.blueTan;
  const {
    primaryButtonText,
    primaryButtonUrl,
    secondaryButtonText,
    secondaryButtonUrl,
    logoPUC,
    logoUCR,
  } = textContent;

  return (
    <Grid container justifyContent="center" alignItems="space-between" style={{height: '100%'}}>
      <Grid item xs={12}>
        <WhatisButon>
          <a
            href={primaryButtonUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <ButtonComponent
              type="secondary"
              text={primaryButtonText}
              backgroundColor={colorTitle}
            />
          </a>
          <a
            href={secondaryButtonUrl}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <ButtonComponent
              type="secondary"
              text={secondaryButtonText}
              backgroundColor={colorTitle}
              colorText={colorTitle}
            />
          </a>
        </WhatisButon>
      </Grid>
      <Grid item xs={12}>
        <UpperTitleImageContainerStyled>
          <img src={logoUCR.src} alt={logoUCR.alt} />
          <img src={logoPUC.src} alt={logoPUC.alt} />
        </UpperTitleImageContainerStyled>
      </Grid>
    </Grid>
  );
};

const textBoxChildren = ({ title, description, description2 }) => {
  const colorText = COLORS.blueTan;

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{
              color: colorText,
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              color: colorText,
              marginTop: "20px",
            }}
          >
            {description}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: "20px",
              color: colorText,
            }}
          >
            {description2}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export const WhatsIsCertifications = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
    />
  );
};
