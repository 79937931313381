const menuItemsMobile = [
  {
    text: "Home",
    internalLink: "/home",
    to: "home/",
    available: true,
  },
  {
    text: "WHAT IS PHOTIO?",
    internalLink: "/whatis",
    to: "/whatis",
    available: true,
  },
  {
    text: "ABOUT US",
    internalLink: "/about",
    to: "/about",
    available: true,
  },
  {
    text: "PROJECTS",
    internalLink: "/proyects",
    to: "/proyects",
    available: true,
  },
  {
    text: "NANOFACTORY",
    internalLink: "/applications",
    to: "/applications",
    available: true,
  },
  {
    text: "CONTACT US",
    internalLink: "/contact",
    to: "/contact",
    available: true,
  },
  {
    text: "FAQ (Frecuently Asked Questions)",
    internalLink: "/questions",
    to: "/questions",
    available: false,
  },
];

const textContent = {
  menu: "MENU",
  close: "CLOSE",
};

export const menuMobileContent = {
  menuItemsMobile,
  textContent,
};
