import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { MobileMenuContent } from "../components/globals/Menu/MobileMenuContent";
import { DesktopMenuContent } from "../components/globals/Menu/DesktopMenuContent";
import { FooterComponent } from "../components/globals/FooterComponent";
import { AppContainer } from "../containers/HomeContainers/AppContainer/AppContainer";
import { AppNanoContainer } from "../containers/HomeContainers/AppNano/AppNanoContainer";
import { AppApplyContainer } from "../containers/HomeContainers/AppApply/AppApplyContainer";
import { AppPartnersContainer } from "../containers/HomeContainers/AppPartners/AppPartnersContainer";
import COLORS from "../styles/global/colors";
import { LanguageContext } from "../contexts/LanguageContext";

export const ApplicationView = () => {
  const theme = "light";

  const { dictionary } = useContext(LanguageContext);
  const { applicationContent, appNanoContent, applyContent, partnersContent } =
    dictionary.applicationViewContent;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <Hidden mdUp>
          <MobileMenuContent theme={theme} />
        </Hidden>
        <Hidden smDown>
          <DesktopMenuContent theme={theme} />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <AppContainer content={applicationContent} />
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <AppNanoContainer content={appNanoContent} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ width: "100%", background: COLORS.greenTitle }}
      >
        <AppApplyContainer content={applyContent} />
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <AppPartnersContainer content={partnersContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
};
