import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import COLORS from "../../../styles/global/colors";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Create, MailOutlineOutlined } from "@material-ui/icons";
import { InputLabel, TextField } from "@material-ui/core";
import { ButtonComponent } from "../../../components/globals/Button";
import { sendFormValues } from "../../../services/ContactService";
import * as yup from "yup";
import { useFormik } from "formik";
import PublicIcon from "@material-ui/icons/Public";

const styles = {
  input: {
    width: "100%",
    color: COLORS.blueTan,
    colorText: COLORS.blueTan,
  },
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;



export function ContactForm({
  content,
  loading,
  setLoading,
  setSuccessSend,
  setErrorSend,
}) {
  const { reason, name, email, phone, text, button } = content;
  const sendForm = (values) => {
    setLoading(true);
    sendFormValues(values)
      .then(() => {
        setSuccessSend(true);
      })
      .catch(() => {
        setErrorSend(true);
      })
      .finally(() => setLoading(false));
  };

  const validationSchema = yup.object({
    reason: yup.string(reason.validators.base).required(reason.validators.require),
    names: yup
      .string(name.validators.base)
      .min(3, name.validators.min)
      .required(name.validators.require),
    phone: yup
      .string(phone.validators.base)
      .matches(phoneRegExp, phone.validators.match)
      .max(11, phone.validators.max)
      .required(phone.validators.require),
    text: yup
      .string(text.validators.base)
      .min(10, text.validators.min)
      .required(text.validators.require),
    email: yup
      .string(email.validators.base)
      .email(email.validators.email)
      .required(email.validators.require),
  });

  const formik = useFormik({
    initialValues: {
      reason: "",
      names: "",
      email: "",
      phone: "",
      text: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendForm(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          display: "flex",
          gap: 30,
          width: "100%",
          colorText: COLORS.blueTan,
          marginTop: 30,
        }}
      >
        <Grid item xs={12} style={{ width: "100%" }}>
          <InputLabel style={{ colorText: COLORS.blueTan }}>
            {reason.title}
          </InputLabel>
          <Select
            id="reason"
            name="reason"
            label={reason.title}
            value={formik.values.reason}
            style={styles.input}
            onChange={formik.handleChange}
            error={formik.touched.reason && formik.errors.reason}
            helperText={formik.touched.reason && formik.errors.reason}
          >
            {reason.options.map((reasonInternal, index) => (
              <MenuItem key={`menuFormItem_${index}`} value={reasonInternal}>
                {reasonInternal}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <TextField
            id="names"
            name="names"
            label={name.title}
            placeholder={name.placeholder}
            style={styles.input}
            onChange={formik.handleChange}
            value={formik.values.names}
            error={formik.touched.names && formik.errors.names}
            helperText={formik.touched.names && formik.errors.names}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label={email.title}
            placeholder={email.placeholder}
            style={styles.input}
            onChange={formik.handleChange}
            value={formik.values.email}
            error={formik.touched.email && formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineOutlined />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="phone"
            name="phone"
            label={phone.title}
            placeholder={phone.placeholder}
            style={styles.input}
            onChange={formik.handleChange}
            value={formik.values.phone}
            error={formik.touched.phone && formik.errors.phone}
            helperText={formik.touched.phone && formik.errors.phone}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PublicIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="text"
            name="text"
            label={text.title}
            placeholder={text.placeholder}
            style={styles.input}
            onChange={formik.handleChange}
            value={formik.values.text}
            error={formik.touched.text && formik.errors.text}
            helperText={formik.touched.text && formik.errors.text}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Create />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonComponent
            buttonType="submit"
            type="primary"
            disabled={loading}
            text={loading ? button.sending : button.valid}
            margin={"10px 0px 40px"}
            backgroundColor={COLORS.blueTan}
            colorText={COLORS.white}
            showIconRight
          ></ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}

export default ContactForm;
