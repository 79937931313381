import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { WhatisVideoTwoStyled } from "../styles/WhatisVideoTwoStyled";

const mediaChildren = ({ src, type }) => {
  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-end"
          direction="row"
          style={{ height: "100%" }}
        >
          <Grid item xs={9} style={{ height: "100%" }}>
            <WhatisVideoTwoStyled>
              <video controls>
                <source src={src} type={type} />
              </video>
            </WhatisVideoTwoStyled>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <WhatisVideoTwoStyled>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            direction="row"
          >
            <Grid item style={{ margin: "20px" }}>
              <video width="100%" controls>
                <source src={src} type={type} />
              </video>
            </Grid>
          </Grid>
        </WhatisVideoTwoStyled>
      </Hidden>
    </>
  );
};

const textBoxChildren = ({
  upperTitle,
  iconUpperTitle,
  description,
  description2,
}) => {
  const colorText = COLORS.white;
  const marginBottom = 20;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      style={{ display: "flex" }}
    >
      <Grid item xs={10} md={8} style={{ marginBottom }}>
        <Hidden mdDown>
          <Typography
            variant="body1"
            style={{
              fontSize: "20px",
              color: colorText,
              margin: "40px 0px 30px",
            }}
          >
            {upperTitle}
            {iconUpperTitle}
          </Typography>
        </Hidden>
        <Hidden mdUp>
          <Typography
            variant="body1"
            style={{
              fontSize: "20px",
              color: colorText,
              marginBottom: "20px",
            }}
          >
            {upperTitle}
            {iconUpperTitle}
          </Typography>
        </Hidden>
        <Grid container direction="row">
          <Grid item xs={12} md={10} style={{ fontStyle: "normal" }}>
            <Hidden mdDown>
              <Typography
                variant="body1"
                style={{
                  color: colorText,
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26px",
                  letterSpacing: "0.03em",
                }}
              >
                {description}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: colorText,
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26px",
                  letterSpacing: "0.03em",
                }}
              >
                {description2}
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography
                variant="body1"
                style={{
                  color: colorText,
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26px",
                  letterSpacing: "0.03em",
                }}
              >
                {description}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: colorText,
                  fontWeight: 300,
                  fontSize: "20px",
                  lineHeight: "26px",
                  letterSpacing: "0.03em",
                  marginTop: "20px",
                }}
              >
                {description2}
              </Typography>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AboutVideo = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
      backgroundColor={COLORS.purpleBright}
    />
  );
};
