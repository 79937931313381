import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { MobileMenuContent } from "../components/globals/Menu/MobileMenuContent";
import { DesktopMenuContent } from "../components/globals/Menu/DesktopMenuContent";
import { AboutContainer } from "../containers/HomeContainers/AboutContainer/AboutContainer";
import { AboutVideoContainer } from "../containers/HomeContainers/AboutVideo/AboutVideoContainer";
import { FooterComponent } from "../components/globals/FooterComponent";
import { AboutValores } from "../containers/HomeContainers/AboutValores/AboutValoresContainer";
import { Rrss } from "../components/RRSS";
import { PremiosContainerMobile } from "../containers/HomeContainers/PremiosMobile";
import { PremiosContainer } from "../containers/HomeContainers/PremiosContainer";
import { aboutViewContent } from "../languages/es/aboutView";
import { NewsContainer } from "../containers/HomeContainers/NewsContainer/NewsContainer";
import { LanguageContext } from "../contexts/LanguageContext";

export const AboutView = () => {
  const theme = "light";

  const { dictionary } = useContext(LanguageContext);
  const { aboutContent, videoContent, valoresContent } =
    dictionary.aboutViewContent;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <Hidden mdUp>
          <MobileMenuContent theme={theme} />
        </Hidden>
        <Hidden smDown>
          <DesktopMenuContent theme={theme} />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%", marginBottom: 50 }}>
        <AboutContainer content={aboutContent} />
      </Grid>

      <Grid item xs={12} style={{ width: "100%" }}>
        <AboutVideoContainer content={videoContent} />
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <AboutValores content={valoresContent} />
      </Grid>
      <Hidden mdDown>
        <Grid item xs={12} style={{ width: "100%" }}>
          <PremiosContainer />
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid item xs={12} style={{ width: "100%" }}>
          <PremiosContainerMobile />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <NewsContainer />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <Rrss />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
};
