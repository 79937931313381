import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import logoUC from "../../../constants/assets/whatIsView/container/logonewuc.png";
import sachet from "../../../constants/assets/shared/sachetfrontal2.png";
import logoUCRiverside from "../../../constants/assets/shared/logoUCRiverside.png";

const title = {
  upperTitle: "¿Qué es Photio?",
  iconUpperTitle: <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />,
};

const description = {
  positionText: "left",
  textContent: {
    description: (
      <p>
        De manera similar a la fotosíntesis, la tecnología{" "}
        <strong>Photio</strong> al ser aplicada en pinturas, recubrimientos u
        otros materiales, reacciona con la luz promoviendo un{" "}
        <strong>proceso de descontaminación único</strong>, que es responsable
        de degradar gran parte de los gases de efecto invernadero y
        contaminantes del aire.
      </p>
    ),

    description2: (
      <p>
        Photio es la <strong>única tecnología</strong> en el mundo validada y
        certificada en entornos reales y a nivel de laboratorio por las más
        prestigiosas universidades y laboratorios del mundo. ¡Conoce más
        detalles sobre nuestras validaciones!
      </p>
    ),
  },
  mediaContent: {
    source: sachet,
    alt: "Sachet Photio",
  },
};

const certifications = {
  positionText: "left",
  textContent: {
    title: "¡Conoce más detalles sobre nuestras certificaciones!",
    description:
      "Photio fue validado en Chile por la Pontificia Universidad Católica de Chile, y en EEUU  por el Centro de Investigación Atmosférica CE-CERT en la University of California  Riverside (UCR).",
    description2:
      "Revisa nuestra Ficha Técnica y Hoja de Seguridad homologada.",
  },
  mediaContent: {
    textContent: {
      logoPUC: {
        src: logoUC,
        alt: "Logo PUC",
      },
      logoUCR: {
        src: logoUCRiverside,
        alt: "Logo UC Riverside",
      },
      primaryButtonText: "Ver Ficha Técnica",
      primaryButtonUrl:
        "https://drive.google.com/file/d/1Z92hcUR9R7YFnfucZyc4Afo4OmIZtzKV/view?usp=share_link",
      secondaryButtonText: "Ver Hoja de Seguridad",
      secondaryButtonUrl:
        "https://drive.google.com/file/d/1ArbxoJjvDdk3oqNcKDhHiJhSbHkLOmI5/view?usp=share_link",
    },
  },
};

export const whatIsContent = {
  title,
  description,
  certifications,
};
