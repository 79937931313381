import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const AppNanoTitle = ({ content }) => {
  const { textContent, description } = content;

  return (
    <Grid
      container
      justifyContent="flex-star"
      alignItems="center"
      style={{ background: COLORS.white, width: "100%", margin: "40px 0px" }}
    >
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid
        item
        xs={10}
        md={12}
        style={{ color: COLORS.blueTan, width: "100%" }}
      >
        <Typography variant="body1" style={{ fontSize: "18px" }}>
          {textContent.upperTitle}
          {textContent.iconUpperTitle}
        </Typography>
      </Grid>

      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
      <Grid item xs={10} md={12}>
        {description}
      </Grid>
    </Grid>
  );
};
