import { aboutViewContent } from "./aboutView";
import { applicationViewContent } from "./applicationView";
import { contactViewContent } from "./contactView";
import { faqViewContent } from "./faqView";
import { homeViewContent } from "./homeView";
import { projectViewContent } from "./projectView";
import { sharedContent } from "./shared";
import { whatIsViewContent } from "./whatIsView";

export const en = {
  aboutViewContent,
  applicationViewContent,
  contactViewContent,
  faqViewContent,
  homeViewContent,
  projectViewContent,
  sharedContent,
  whatIsViewContent,
};
