import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import FotoPaisCircular from "../../../constants/assets/aboutView/about/teamphotio.jpg";

const about = {
  positionText: "left",
  textContent: {
    description: (
      <p>
        Somos una <strong>startup disruptiva</strong> que utiliza la{" "}
        <strong>nanotecnología</strong> como herramienta para aportar en la{" "}
        <strong>
          resolución de los problemas climáticos que nos aquejan hoy en día
        </strong>
        . Somos un grupo de personas preocupadas por nuestro planeta, la cual
        entiende que la contaminación es un peligro para todos. Basamos
        nuestros desarrollos en la <strong>colaboración</strong>, ya que
        creemos que es el pilar fundamental para enfrentar este y otros
        problemas medioambientales.
      </p>
    ),
    upperTitle: "¿Quiénes somos?",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  mediaContent: {
    source: FotoPaisCircular,
    alt: "Foto",
  },
};

export const aboutContent = {
  about,
}
