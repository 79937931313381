import React from "react";
import { PhotioApplyBody } from "./PhotioApplyBody";

export const PhotioApplyContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { apply } = content;

  return <PhotioApplyBody content={apply} />;
};
