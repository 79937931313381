import React, { useContext, useState } from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { OptionMobileStyled } from "./styles";

export default function LanguageSelectorMobile({ home = false }) {
  const options = {
    es: "Change To English",
    en: "Cambia a Español",
  };
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = (value) => {
    userLanguageChange(value);
  };

  return (
    <OptionMobileStyled
      home={home}
      onClick={(e) => handleLanguageChange(userLanguage === "en" ? "es" : "en")}
    >
      {options[userLanguage]}
    </OptionMobileStyled>
  );
}
