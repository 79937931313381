import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ApplyItem } from "../styles/AplicationsView";
import COLORS from "../../../styles/global/colors";

export const AppApplyBody = ({ textContent, items, setShowDetails }) => {

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        background: COLORS.greenTitle,
        width: "100%",
        gap: 16,
        padding: "40px 0px",
      }}
    >
      <Grid item xs={10} md={12} style={{ width: "100%", textAlign: "center" }}>
        <Typography
          style={{
            color: COLORS.white,
            fontSize: "24px",
            marginBottom: "16px",
            fontWeight: 600,
          }}
        >
          {textContent.upperTitle}
        </Typography>
        <Typography
          style={{
            color: COLORS.white,
            fontSize: "18px",
          }}
        >
          {textContent.description}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ width: "100%", padding: "38px 0px 0px" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={5}
        >
          {items.map((particularItem, index) => (
            <Grid item xs={5} md={3} key={`Applyitem_${index}`}>
              <ApplyItem
                onClick={() => {
                  setShowDetails(particularItem.state);
                }}
              >
                {particularItem.source}
                <Typography variant="body1">{particularItem.title}</Typography>
              </ApplyItem>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
