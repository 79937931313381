import { applicationContent } from "./application";
import { appNanoContent } from "./appNano";
import { applyContent } from "./apply";
import { partnersContent } from "./partners";

export const applicationViewContent = {
  applicationContent,
  appNanoContent,
  applyContent,
  partnersContent
};
