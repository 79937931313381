import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { WhatisImageStyled } from "../styles/WhatisBodyStyles";
import { WhatisText } from "../styles/WhatisStyled";

const mediaChildren = ({ source, alt }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
    >
      <Grid item>
        <WhatisImageStyled>
          <img src={source} alt={alt} />
        </WhatisImageStyled>
      </Grid>
    </Grid>
  );
};

const textBoxChildren = ({ description, description2 }) => {
  const colorText = COLORS.blueTan;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      direction="column"
    >
      <WhatisText>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{
              color: colorText,
            }}
          >
            {description}
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: colorText,
              fontSize: "20px",
            }}
          >
            {description2}
          </Typography>
        </Grid>
      </WhatisText>
    </Grid>
  );
};

export const WhatisDescription = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
    />
  );
};
