import { footerContent } from "./footer";
import { premiosContent } from "./premios";
import { newsContent } from "./news";
import { rrssContent } from "./rrss";
import { photomContent } from "./photom";
import { menuDesktopContent } from "./menuListDesktop";
import { menuMobileContent } from "./menuListMobile";

export const sharedContent = {
  footerContent,
  premiosContent,
  newsContent,
  rrssContent,
  photomContent,
  menuDesktopContent,
  menuMobileContent,
};
