import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import COLORS from "../../styles/global/colors";

import LogoPhotioBlanco from "../../constants/assets/shared/footer/LogoPhotioBlanco.png";
import { LanguageContext } from "../../contexts/LanguageContext";

export const FooterComponent = () => {
  const history = useHistory();

  const { dictionary } = useContext(LanguageContext);
  const {
    footerContent,
    menuDesktopContent: { menuItemsDektop },
  } = dictionary.sharedContent;

  if (!footerContent) return null;
  const { contact, companyInformation, rrss, credits } = footerContent;

  return (
    <div style={{ background: COLORS.blueTan, paddingTop: 60 }}>
      <Grid
        container
        direction="row-reverse"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={11} md={3} style={{ marginBottom: 50 }}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={11} md={8}>
              <img
                src={LogoPhotioBlanco}
                alt="imgLogo"
                style={{ width: "100%" }}
              ></img>
            </Grid>
            <Grid item xs={11} md={8}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                {rrss.map((rs, index) => (
                  <a
                    href={rs.link}
                    target="_blank"
                    rel="noreferrer"
                    key={`rrssFooter_${index}`}
                  >
                    <img
                      src={rs.icon}
                      alt={rs.alt}
                      style={{
                        width: 20,
                        height: 20,
                      }}
                    />
                  </a>
                ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} md={6}>
          <Grid
            container
            direction="row-reverse"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={3} style={{ marginBottom: 40 }}>
              <Typography
                variant="body1"
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                <strong>{contact.title}</strong>
              </Typography>

              {contact.information.map((infoContact, index) => (
                <div
                  key={`informationFooter_${index}`}
                  style={{ margin: "20px 0px", width: "90%" }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {infoContact.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    {infoContact.value}
                  </Typography>
                </div>
              ))}
            </Grid>
            <Grid item xs={12} md={2} style={{ marginBottom: 40 }}>
              <Typography
                variant="body2"
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  marginBottom: 15,
                }}
              >
                {companyInformation.title}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  marginBottom: 15,
                }}
              >
                {companyInformation.id}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "rgba(255, 255, 255, 0.8)",
                  marginBottom: 15,
                }}
              >
                {companyInformation.name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              {menuItemsDektop.map((item, index) => {
                return (
                  item.footerShow && (
                    <Typography
                      key={`itemFooter_${index}`}
                      variant="body2"
                      style={{
                        marginBottom:
                          menuItemsDektop.length - 2 === index
                            ? "40px"
                            : "15px",
                        color: "rgba(255, 255, 255, 0.8)",
                        textDecoration: "none",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.textDecoration = "underline";
                      }}
                      onMouseOut={(e) => {
                        e.target.style.textDecoration = "none";
                      }}
                      onClick={() => {
                        history.push(item.to);
                        window.scrollTo(0, 0);
                      }}
                    >
                      {item.text}
                    </Typography>
                  )
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={11}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ color: "#FFFFFF", opacity: 0.4 }}>
            <span style={{ color: COLORS.gray_3 }}> {credits.property} </span>
            &copy; | {credits.design}
          </p>
        </Grid>
      </Grid>
    </div>
  );
};
