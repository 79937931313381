import CENTRODEOPERACIONESPRINCIPAL from "../../../constants/assets/projectView/projects/CENTRODEOPERACIONESPRINCIPAL.png";
import METROSALVADORPRINCIPAL from "../../../constants/assets/projectView/projects/METROSALVADORPRINCIPAL.png";
import BUPASPORTPRINCIPAL from "../../../constants/assets/projectView/projects/BUPASPORTPRINCIPAL.png";
import BULLETITPRINCIPAL from "../../../constants/assets/projectView/projects/BULLETITPRINCIPAL.png";
import CANCHASPRINCIPAL from "../../../constants/assets/projectView/projects/CANCHASPRINCIPAL.png";
import ONCOLOGIAPRINCIPAL from "../../../constants/assets/projectView/projects/ONCOLOGIAPRINCIPAL.png";
import VIASSUSTENTABLESPRINCIPAL from "../../../constants/assets/projectView/projects/VIASSUSTENTABLESPRINCIPAL.png";

const proyects = [
  {
    title: "SUSTAINABLE PATHWAYS - VíasChile",
    description:
      "Project carried out for VíasChile, Chilean subsidiary of Abertis, a world-leading company in the management of toll roads and infrastructure. Thanks to Photio’s implementation, 3,200 square meters of Central Highway side walls in the Metropolitan Region were transformed into a huge forest in the middle of the city. This project was attended by the renowned Chilean muralist, Trinidad Guzmán (@holaleon), who was in charge of intervening with other artists, one of the busiest roads in the capital of Chile. Currently, this artistic intervention is located on the west wall of the exit to Agustinas Street of Central Highway.",
    backgroundimg: VIASSUSTENTABLESPRINCIPAL,
  },
  {
    title: "SUSTAINABLE ARTISTIC CORRIDOR - Santiago Metro",
    description:
      "While other projects had already been developed with Photio inside the Metro stations, this intervention corresponds to one of the largest implemented by that institution to date. This floor mural, made by the Cekis, a national artist eradicated in the United States, and Lira Arte Público, was executed in the upper esplanade of the El Salvador station, which thanks to our technology was transformed into a huge sink of pollution with a decontaminating potential equivalent to 2,000 mature trees.",
    backgroundimg: METROSALVADORPRINCIPAL,
  },
  {
    title: "DECONTAMINATING HEALTH CENTERS - Bupa Health Center Network",
    description:
      "Together with the muralist Constanza Larenas (@conilars), more than 1,600 square meters were intervened in the facilities of the network of Bupa clinics. These interventions have a decontaminating potential equivalent to that of more than 3,200 mature trees, so in the period of one year, the capture due to the implementation of Photio will be about 370,000 kg of CO2eq. Bupa is currently applying Photio to all facilities in its network, directly collaborating with maintenance and infrastructure teams.",
    backgroundimg: BUPASPORTPRINCIPAL,
  },
  {
    title: "CIRCUIT OF DECONTAMINATING MURALS - Bulleit Bourbon",
    description:
      "This mural is part of the Circuit 'Abrir Fronteras,' made by renowned national artist Rodrigo Estoy. This was a project developed in the framework of an artistic collaboration between Lira Arte Público and Bulleit Bourbon, one of the most awarded brands of American whiskey, recognized for connecting urban art with neighborhoods, communities, bars, restaurants and enterprises. The mural extends over six floors, in an area equivalent to 102 m2 intervened with Photio. The mural is located in Avenida Salvador with Providencia.",
    backgroundimg: BULLETITPRINCIPAL,
  },
  {
    title: "TRIPLE IMPACT COURTS - Adidas | Social Impact",
    description:
      "This project consisted of the embellishment of 6 courts of different neighborhoods of the Metropolitan Region, which were intervened along with collaborators of Adidas, prominent athletes, influencers, neighbors and officials of the different municipalities. During the activity, attendees participated in the community painting of decontaminating sports courts. Each court generates an environmental impact equivalent to that of more than 1,000 mature trees, which will improve the air quality of the 6 communities benefited by this initiative.",
    backgroundimg: CANCHASPRINCIPAL,
  },
  {
    title: "VOLUNTEER AT SAN BORJA HOSPITAL",
    description:
      "In 2021, the San Borja Hospital burned down and several units were affected, including the Children’s Oncology area. Thanks to the invitation of the Luchadores de Colores Foundation, we participated in the intervention of the Children’s Oncology Center of San Borja Hospital. Together with hospital officials and various artists who supported the initiative, we were able to refurbish the new facilities, developing a massive implementation of Photio through murals that embellished this place for the children who attend to it daily.",
    backgroundimg: ONCOLOGIAPRINCIPAL,
  },
  {
    title: "SUSTAINABLE PATHWAYS | OPERACIONS CENTER - VíasChile",
    description:
      "Together with national artist Trinidad Guzmán (@holaleon), the Central Highway Operations Center was intervened, where the activities of the motorway are monitored day by day, located in the commune of San Bernardo. This project has a direct positive impact on the health of workers at the Operations Center and its surrounding communities. The design was led by the muralist and her team of assistants, who focused on showing different flores endemic to Chile, giving a visual appeal to the facade of the enclosure.",
    backgroundimg: CENTRODEOPERACIONESPRINCIPAL,
  },
];

export const projectContent = {
  proyects,
};
