import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ButtonComponent } from "../../components/globals/Button";
import COLORS from "../../styles/global/colors";
import { ApplyItem } from "./styles/ApplyContainerStyles";

export const ApplyContainer = ({ content = undefined }) => {
  const history = useHistory();

  if (!content) return null;

  const { textContent, items } = content;

  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            background: COLORS.purpleBright,
            width: "100%",
            padding: "40px 0px",
          }}
        >
          <Grid
            item
            xs={10}
            style={{ width: "100%", marginBottom: "50px", marginLeft: "-6%" }}
          >
            <Typography style={{ color: COLORS.white, fontSize: "18px" }}>
              {textContent.upperTitle}
              {textContent.iconUpperTitle}
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ width: "100%", marginBottom: "20px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              {items.map((particularItem, index) => (
                <Grid item xs={5} md={1} key={`Applyitem_${index}`}>
                  <ApplyItem style={{ color: COLORS.white }}>
                    {particularItem.source}
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      {particularItem.title}
                    </Typography>
                  </ApplyItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={10} style={{ marginTop: "40px" }}>
            <ButtonComponent
              type="secondary"
              text={textContent.secondaryButtonText}
              margin={"10px 0px"}
              backgroundColor={COLORS.white}
              colorText={COLORS.white}
              showIconRight={COLORS.blueTan}
              callToAction={() => {
                history.push("/applications");
                window.scrollTo(0, 0);
              }}
            />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{
            background: COLORS.purpleBright,
            width: "100%",
            padding: "40px 0px",
          }}
        >
          <Grid
            item
            xs={8}
            style={{ width: "100%", marginBottom: "50px", marginRight: "70px" }}
          >
            <Typography style={{ color: COLORS.white, fontSize: "18px" }}>
              {textContent.upperTitle}
              {textContent.iconUpperTitle}
            </Typography>
          </Grid>
          <Grid item xs={8} style={{ width: "100%", marginBottom: "20px" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              {items.map((particularItem, index) => (
                <Grid item xs={5} md={1} key={`Applyitem_${index}`}>
                  <ApplyItem style={{ color: COLORS.white, marginTop: "30px" }}>
                    {particularItem.source}
                    <Typography
                      variant="body2"
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                        width: "200px",
                      }}
                    >
                      {particularItem.title}
                    </Typography>
                  </ApplyItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={10} style={{ marginTop: "40px" }}>
            <ButtonComponent
              type="secondary"
              text={textContent.secondaryButtonText}
              margin={"10px 0px"}
              backgroundColor={COLORS.white}
              colorText={COLORS.white}
              showIconRight={COLORS.blueTan}
              callToAction={() => {
                history.push("/applications");
                window.scrollTo(0, 0);
              }}
            />
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
