import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import logoUC from "../../../constants/assets/whatIsView/container/logonewuc.png";
import sachet from "../../../constants/assets/shared/sachetfrontal2.png";
import logoUCRiverside from "../../../constants/assets/shared/logoUCRiverside.png";

const title = {
  upperTitle: "What is Photio?",
  iconUpperTitle: <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />,
};

const description = {
  positionText: "left",
  textContent: {
    description: (
      <p>
        Similar to photosynthesis, <strong>Photio technology</strong>, when
        applied to paints, coatings or other materials, reacts with light
        promoting a <strong>unique decontamination process</strong>, which is
        responsible for degrading much of the greenhouse gases and air
        pollutants.
      </p>
    ),

    description2: (
      <p>
        Photio is the <strong>only technology</strong> in the world validated
        and certified in real world and laboratory-level environments by the
        world’s most prestigious universities and laboratories. Learn more about
        our validations!
      </p>
    ),
  },
  mediaContent: {
    source: sachet,
    alt: "Sachet Photio",
  },
};

const certifications = {
  positionText: "left",
  textContent: {
    title: "¡Learn more about our Certifications!",
    description:
      "Photio was validated in Chile by the Pontifical Catholic University of Chile, and in the USA by the Center for Atmospheric Research CE-CERT at the University of California Riverside (UCR).",
    description2:
      "Check our Technical Data Sheet and approved Safety Data Sheet.",
  },
  mediaContent: {
    textContent: {
      logoPUC: {
        src: logoUC,
        alt: "Logo PUC",
      },
      logoUCR: {
        src: logoUCRiverside,
        alt: "Logo UC Riverside",
      },
      primaryButtonText: "See Data Sheet",
      primaryButtonUrl:
        "https://drive.google.com/file/d/1Z92hcUR9R7YFnfucZyc4Afo4OmIZtzKV/view?usp=share_link",
      secondaryButtonText: "See Safety Data Sheet",
      secondaryButtonUrl:
        "https://drive.google.com/file/d/1ArbxoJjvDdk3oqNcKDhHiJhSbHkLOmI5/view?usp=share_link",
    },
  },
};

export const whatIsContent = {
  title,
  description,
  certifications,
};
