import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { AppBox } from "../../../components/AppBox";

import { ContainerStyled } from "../styles/ProyectStyled";
import CarouselPartner, {
  CarouselCelPartner,
} from "../../../components/CarouselPartner/CarouselPartner";

export const AppPartnersBody = ({content}) => {
  const {proyectShow} = content;
  return (
    <>
      <Hidden mdDown>
        <ContainerStyled>
          {proyectShow.map((e, index) => (
            <AppBox
              key={`NewsCaja_${index}`}
              title={e.title}
              backgroundimg={e.backgroundimg}
            />
          ))}
        </ContainerStyled>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          justifyContent="flex-start"
          display="flex"
          direction="row"
          style={{ width: "100%" }}
        >
          <CarouselPartner>
            {proyectShow.map((e, index) => (
              <CarouselCelPartner>
                <AppBox
                  key={`NewsCaja_${index}`}
                  title={e.title}
                  backgroundimg={e.backgroundimg}
                />
              </CarouselCelPartner>
            ))}
          </CarouselPartner>
        </Grid>
      </Hidden>
    </>
  );
};
