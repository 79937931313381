import imageDesktop from '../../../constants/assets/homeView/photom/desktopEn.svg';
import imageMobile from '../../../constants/assets/homeView/photom/mobileEn.svg'

const photomDesktopContent = {
  src: imageDesktop,
  alt: 'photom2'
}

const photomMobileContent = {
  src: imageMobile,
  alt: 'photom2'
}

export const photomContent = {
  photomDesktopContent,
  photomMobileContent,
}
