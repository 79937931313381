import styled from "styled-components";
import COLORS from "../../../styles/global/colors";

const ButtonStyled = styled.button`
  align-items: center;
  background: transparent;
  border: 0px;
  display: flex;
  flex-direction: row;
  gap: 4.73px;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : '0px')};
  padding: 8px 20px;
  opacity: ${(props) => (props.disabled ? (props.light ? 0.3 : 0.6) : 1)};
`;

const PrimaryButtonStyled = styled(ButtonStyled)`
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : props.light ? COLORS.blueTan : COLORS.white)};
  border: 0.8px solid
    ${(props) => (props.backgroundColor ? props.backgroundColor : props.light ? COLORS.blueTan : COLORS.white)};
  border-radius: 47px;
`;

const SecondaryButtonStyled = styled(ButtonStyled)`
  border: 1.5px solid
    ${(props) => (props.backgroundColor ? props.backgroundColor : props.light ? COLORS.blueTan : COLORS.white)};
  border-radius: 47px;
`;

const textStyle = {
  margin: "4px 6px",
};

const textPrimaryLightStyle = {
  ...textStyle,
  color: COLORS.white,
};

const textPrimaryDarkStyle = {
  ...textStyle,
  color: COLORS.blueTan,
};

const textCustomStyle = {
  ...textStyle,
  color: COLORS.blueTan,
};

const textSecondaryLightStyle = {
  ...textStyle,
  color: COLORS.blueTan,
};

const textSecondaryDarkStyle = {
  ...textStyle,
  color: COLORS.white,
};

const textThirdLightStyle = {
  ...textStyle,
  borderBottom: `1px solid ${COLORS.blueTan}`,
};

const textThirdDarkStyle = {
  ...textStyle,
  borderBottom: `1px solid ${COLORS.white}`,
  color: COLORS.white,
};

const iconLightStyle = {
  color: COLORS.white,
  margin: "4px",
};

const iconDarkStyle = {
  color: COLORS.blueTan,
  margin: "4px",
};

export {
  ButtonStyled as ButtonComponent,
  PrimaryButtonStyled as PrimaryButtonComponent,
  SecondaryButtonStyled as SecondaryButtonComponent,
  textStyle,
  textCustomStyle,
  textPrimaryLightStyle,
  textPrimaryDarkStyle,
  textSecondaryLightStyle,
  textSecondaryDarkStyle,
  textThirdLightStyle,
  textThirdDarkStyle,
  iconLightStyle,
  iconDarkStyle,
};
