import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import COLORS from "../../styles/global/colors";

export const NewsCaja = ({ buttonText, description, link, backgroundimg }) => {
  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ height: 200, width: 270, margin: "40px 24px 130px" }}
        >
          <Grid item xs={12} style={{ height: "200px", marginBottom: "0px" }}>
            <img
              src={backgroundimg}
              alt="ing1"
              style={{
                height: "200px",
                width: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: "200px",
              background: COLORS.white,
              borderBottomRightRadius: "8px",
              borderBottomLeftRadius: "8px",
            }}
          >
            <Grid
              container
              direction="column"
              line-height="normal"
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ padding: 10 }}
            >
              <Grid item xs={8} style={{ height: "100%" }}>
                <Typography
                  variant="body1"
                  style={{
                    color: COLORS.blueTan,
                    fontWeight: "300",
                    width: "260px",
                    lineHeight: "26px",
                  }}
                >
                  {description}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    margin: "14px 0px",
                    textDecoration: "underline",
                    height: "150px",
                    color: COLORS.blueTan,
                  }}
                >
                  <a href={link} target="_blank" rel="noreferrer">
                    <strong>{buttonText}</strong>
                  </a>
                  <ArrowForwardIcon
                    style={{
                      color: COLORS.blueTan,
                      height: "16px",
                      width: "20px",
                      display: "inline-flex",
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ margin: "20px", marginLeft: "30px" }}
        >
          <Grid item xs={12} style={{ height: "200px", marginBottom: "0px" }}>
            <img
              src={backgroundimg}
              alt="ing1"
              style={{
                height: "200px",
                width: "80%",
                maxHeight: "100%",
                objectFit: "cover",
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                marginLeft: "2%",
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "0px" }}>
            <Typography
              variant="body2"
              style={{
                color: COLORS.blueTan,
                fontWeight: "300",
                lineHeight: "26px",
                marginTop: "20px",
                width: "100px",
                marginLeft: "2%",
              }}
            >
              {description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              variant="body2"
              style={{
                margin: "10px 0px",
                textDecoration: "underline",
                color: COLORS.blueTan,
                marginRight: "335px",
              }}
            >
              <a href={link} target="_blank" rel="noreferrer">
                <strong>{buttonText}</strong>
              </a>
              <ArrowForwardIcon
                style={{
                  color: COLORS.blueTan,
                  height: "16px",
                  width: "20px",
                  display: "inline-flex",
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
