import React, { useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import {
  ImageStyled,
  LogosRRSSContactStyled,
} from "../styles/InformationContainerStyles";
import sachet2 from "../../../constants/assets/shared/sachetfrontal2.png";
import ContactForm from "./ContactForm";
import {
  DescriptionContact,
  HighlightContact,
  TitleContact,
} from "../styles/ContactViewStyles";
import { ContactFormSent } from "./ContactFormSent";

const mediaChildren = ({ source, alt }, rrss) => {
  return (
    <div style={{ marginBottom: 20 }}>
      <Hidden mdDown>
        <ImageStyled style={{ display: "inherit" }}>
          <img src={source} alt={alt} />
        </ImageStyled>
      </Hidden>
      <Hidden mdUp>
        <ImageStyled style={{ display: "inherit" }}>
          <img src={sachet2} alt={alt} />
        </ImageStyled>
      </Hidden>
      <LogosRRSSContactStyled>
        {rrss.map((redSocial, index) => (
          <a
            href={redSocial.url}
            target="_blank"
            rel="noreferrer"
            key={`rrssContact_${index}`}
          >
            <img src={redSocial.src} alt={redSocial.alt} />
          </a>
        ))}
      </LogosRRSSContactStyled>
    </div>
  );
};

export const ContactBody = ({ contactForm, rrss, contact }) => {
  const [loading, setLoading] = useState(false);
  const [successSend, setSuccessSend] = useState(false);
  const [errorSend, setErrorSend] = useState(false);

  const { positionText, textContent, mediaContent } = contact;
  const { successView, errorView, newContact, back } = contactForm;

  const cleanStates = () => {
    setLoading(false);
    setSuccessSend(false);
    setErrorSend(false);
  };

  if (successSend) {
    const { title, description, source } = successView;
    return (
      <InformationComponent
        mediaChildren={mediaChildren(mediaContent, rrss)}
        textBoxChildren={
          <ContactFormSent
            content={{ newContact, back }}
            title={title}
            description={description}
            source={source}
            cleanStates={cleanStates}
          />
        }
        positionText={positionText}
      />
    );
  }

  if (errorSend) {
    const { title, description, source } = errorView;
    return (
      <InformationComponent
        mediaChildren={mediaChildren(mediaContent, rrss)}
        textBoxChildren={
          <ContactFormSent
            content={{ newContact, back }}
            title={title}
            description={description}
            source={source}
            cleanStates={cleanStates}
          />
        }
        positionText={positionText}
      />
    );
  }

  if (!errorSend && !successSend) {
    const { title, bottomTitle, description } = textContent;
    return (
      <InformationComponent
        mediaChildren={mediaChildren(mediaContent, rrss)}
        textBoxChildren={
          <Grid
            container
            alignItems="flex-start"
            justifyContent="center"
            style={{ marginTop: 20 }}
          >
            <Grid item xs={11} md={10}>
              <TitleContact>{title}</TitleContact>
            </Grid>
            <Grid item xs={11} md={10}>
              <HighlightContact>{bottomTitle}</HighlightContact>
            </Grid>
            <Grid item xs={11} md={10}>
              <DescriptionContact>{description}</DescriptionContact>
            </Grid>
            <Grid item xs={10}>
              <ContactForm
                content={contactForm}
                loading={loading}
                setLoading={setLoading}
                setSuccessSend={setSuccessSend}
                setErrorSend={setErrorSend}
              />
            </Grid>
          </Grid>
        }
        positionText={positionText}
      />
    );
  }
};
