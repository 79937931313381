import React from "react";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  PrimaryButtonComponent,
  textPrimaryLightStyle,
  textPrimaryDarkStyle,
  iconLightStyle,
  textStyle
} from "./styles";

const PrimaryButton = ({
  buttonType,
  theme,
  text,
  callToAction,
  showIconLeft,
  showIconRight,
  disabled,
  margin,
  backgroundColor,
  colorText,
  iconLeft,
  iconRight,
}) => {

  const iconLeftComponent = iconLeft ? <img src={iconLeft} alt='iconLeft' /> : <ArrowBackIcon style={{...iconLightStyle, color: colorText}} />
  const iconRightComponent = iconRight ? <img src={iconRight} alt='iconRight' /> : <ArrowForwardIcon style={{...iconLightStyle, color: colorText}} />

  return (
    <PrimaryButtonComponent
      disabled={disabled}
      onClick={callToAction}
      margin={margin}
      light={theme === "light"}
      backgroundColor={backgroundColor}
      type={buttonType}
    >
      {showIconLeft && iconLeftComponent}
      {text && (
        <Typography
          variant="button"
          style={colorText ? { ...textStyle, color: colorText } : theme === "light" ? textPrimaryLightStyle : textPrimaryDarkStyle}
        >
          {text}
        </Typography>
      )}
      {showIconRight && iconRightComponent}
    </PrimaryButtonComponent>
  )
}

export default PrimaryButton;
