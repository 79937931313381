import youtubeLogo from "../../../constants/assets/shared/footer/youtube.svg";
import twitterLogo from "../../../constants/assets/shared/footer/twitter.svg";
import instagramLogo from "../../../constants/assets/shared/footer/Instaprueba1.svg";
import facebookLogo from "../../../constants/assets/shared/footer/facebook.svg";

const contact = {
  title: "Contact Us",
  information: [
    {
      title: "Address:",
      value: "Avenida del Valle Norte 937, Of 555. Huechuraba",
    },
    // {
    //   title: "Llámanos al:",
    //   value: "+56 9 7663 1959",
    // },
    {
      title: "Email us at",
      value: "contacto@photio.cl",
    },
  ],
};

const companyInformation = {
  title: "Business Name: PHOTIO SPA",
  id: "TIN: 77.053.415-1",
  name: "Trade: Engineering, Technology, Export and Construction Services Company",
  address: "ADDRESS: Del Valle Norte 937 oficina 555, Huechuraba",
};

const rrss = [
  {
    icon: youtubeLogo,
    link: "https://www.youtube.com/@photio8182",
    alt: "youtubeLogo",
  },
  {
    icon: twitterLogo,
    link: "https://twitter.com/photiocl",
    alt: "twitterLogo",
  },
  {
    icon: instagramLogo,
    link: "https://www.instagram.com/photio.cl",
    alt: "instagramLogo",
  },
  {
    icon: facebookLogo,
    link: "https://www.facebook.com/PhotioCL",
    alt: "facebookLogo",
  },
];

const credits = {
  property: "Site owned by Photio",
  design: "Design by YohTapia",
};

export const footerContent = {
  contact,
  companyInformation,
  rrss,
  credits,
};
