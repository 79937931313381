import COLORS from "../../../styles/global/colors";
import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import Recurso18 from "../../../constants/assets/whatIsView/benefits/Recurso18.svg";
import Recurso19 from "../../../constants/assets/whatIsView/benefits/Recurso19.svg";
import Recurso20 from "../../../constants/assets/whatIsView/benefits/Recurso20.svg";
import Recurso5 from "../../../constants/assets/whatIsView/benefits/Recurso5.svg";
import Recurso6 from "../../../constants/assets/whatIsView/benefits/Recurso6.svg";

const title = {
  benefits: {
    textContent: {
      upperTitle: "¿Qué beneficios tiene?",
      iconUpperTitle: (
        <ArrowCornerBottom
          style={{ height: 12, marginLeft: 10, color: COLORS.blueTan }}
        />
      ),
      description: (
        <p>
          Los beneficios de la implementación de Photio{" "}
          <strong>dependen en gran medida del lugar de aplicación</strong>, pero
          de forma general son los siguientes.
        </p>
      ),
    },
  },
};

const body = {
  benefits: {
    textContent: {
      upperTitle: "Beneficios",
      iconUpperTitle: (
        <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
      ),
    },
    items: [
      {
        source: Recurso18,
        alt: "Aire puro",
        title: "Aire puro",
        description:
          "Respira un aire libre de contaminantes debido a la alta eficiencia para la degradación de  gases nocivos.",
      },
      {
        source: Recurso19,
        alt: "No más material en suspensión",
        title: "No más material en suspensión",
        description:
          "Disminuye la concentración de material particulado en tu hogar.",
      },
      {
        source: Recurso20,
        alt: "Mejora tu salud",
        title: "Mejora tu salud",
        description:
          "Eliminamos hongos y bacterias en las superficies de aplicación.",
      },
      {
        source: Recurso5,
        alt: "Sustentabilidad a toda prueba",
        title: "Sustentabilidad a toda prueba",
        description:
          "Genera un impacto ambiental positivo compensando tu huella de carbono.",
      },
      {
        source: Recurso6,
        alt: "Visibilidad",
        title: "Visibilidad",
        description:
          "Consigue sellos y certificaciones medioambientales para tu empresa o proyecto.",
      },
    ],
  },
};

export const benefitsContent = { title, body };
