import styled from "styled-components";


const ImageStylesCarousel = styled.div`
    align-items: center;
    flex-direction: column;
    display: flex;
    width: 500px;
    padding: 14px;
    height: 334px;

  
  @media (min-width: 960px) {
    justify-content: flex-end;
    display: flex;

  }

  img {
    width: 100%;
    display: flex;

  }
`;

const ImageStylesTitle = styled.div`
    align-items: flex-start;
    width: 300px;
    height: 144px;
    font-size: 34px;
    margin: 20px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.01em;
    display: flex;
    margin-bottom: 30%;
  
  @media (min-width: 960px) {
    width: 600px; 
    height: 72px;
    font-size: 40px; 
    font-height: 500; 
    line-height: 50px; 
    margin-left: -2%;
    font-weight: 500;
    margin-bottom: 70px;
    justify-content: center;
  }
}
`;


export {
    ImageStylesCarousel,
    ImageStylesTitle,
};
