import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { LanguageContext } from "../../contexts/LanguageContext";

export const Photom2Container = () => {
  const { dictionary } = useContext(LanguageContext);
  const { photomContent } = dictionary.sharedContent;

  if (!photomContent) return null;

  const { photomDesktopContent, photomMobileContent } = photomContent;

  return (
    <Grid container direction="row">
      <Grid item xs={12} style={{ width: "100%", marginTop: "30px" }}>
        <Hidden mdDown>
          <img
            src={photomDesktopContent.src}
            alt={photomDesktopContent.alt}
            style={{ marginTop: "30px", marginLeft: "5%", width: "90%" }}
          ></img>
        </Hidden>
        <Hidden mdUp>
          <img
            src={photomMobileContent.src}
            alt={photomMobileContent.alt}
            style={{ width: "90%", marginLeft: "10px", marginTop: "30px" }}
          ></img>
        </Hidden>
      </Grid>
    </Grid>
  );
};
