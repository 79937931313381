import React from "react";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {
  SecondaryButtonComponent,
  textSecondaryLightStyle,
  textSecondaryDarkStyle,
  iconLightStyle,
  textStyle,
} from "./styles";

const SecondaryButton = ({
  buttonType,
  theme,
  text,
  callToAction,
  showIconLeft,
  showIconRight,
  disabled,
  margin,
  backgroundColor,
  colorText,
  iconLeft,
  iconRight,
}) => {

  const iconLeftComponent = iconLeft ? <img src={iconLeft} alt='iconLeft' /> : <ArrowBackIcon style={{...iconLightStyle, color: colorText}} />
  const iconRightComponent = iconRight ? <img src={iconRight} alt='iconRight' /> : <ArrowForwardIcon style={{...iconLightStyle, color: colorText}} />

  return (
    <SecondaryButtonComponent
      disabled={disabled}
      onClick={callToAction}
      light={theme === 'light'}
      margin={margin}
      backgroundColor={backgroundColor}
      type={buttonType}
    >
      {showIconLeft && iconLeftComponent}
      {text && (
        <Typography variant="button" style={colorText ? { ...textStyle, color: colorText } : theme === "light" ? textSecondaryLightStyle : textSecondaryDarkStyle}>
          {text}
        </Typography>
      )}
      {showIconRight && iconRightComponent}
    </SecondaryButtonComponent>
  )
};

export default SecondaryButton;
