import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { MobileMenuContent } from "../components/globals/Menu/MobileMenuContent";
import { DesktopMenuContent } from "../components/globals/Menu/DesktopMenuContent";
import { ContactContainer } from "../containers/HomeContainers/ContactContainer/ContactContainer";
import { contactViewContent } from "../languages/es/contactView";
import { LanguageContext } from "../contexts/LanguageContext";

export const ContactView = () => {
  const theme = "light";

  const { dictionary } = useContext(LanguageContext);
  const {
    contactContent
  } = dictionary.contactViewContent;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <Hidden mdUp>
          <MobileMenuContent theme={theme} />
        </Hidden>
        <Hidden smDown>
          <DesktopMenuContent theme={theme} />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <ContactContainer content={contactContent} />
      </Grid>
    </Grid>
  );
};
