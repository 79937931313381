import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ApplyItem } from "../styles/ApplyContainerStyles";
import COLORS from "../../../styles/global/colors";

export const PhotioApplyBody = ({ content }) => {
  const { textContent, items } = content;

  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ background: COLORS.white, width: "100%" }}
        >
          <Grid
            item
            xs={12}
            style={{ width: '100%', textAlign: 'center', marginBottom: "40px", marginTop: "60px" }}
          >
            <Typography
              style={{
                color: COLORS.blueTan,
                fontSize: "24px",
                marginBottom: "20px",
                fontWeight: 600,
                marginTop: "40px",
              }}
            >
              {textContent.upperTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: "100%", marginBottom: "40px" }}>
            <Grid container direction="row" justifyContent="space-evenly">
              {items.map((particularItem, index) => (
                <Grid item xs={2} key={`Applyitem_${index}`}>
                  <ApplyItem
                    style={{ color: COLORS.blueTan, marginTop: "20px", }}
                  >
                    {particularItem.source}
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                        marginTop: "30px",
                        lineHeight: "32px",
                        letterSpacing: "0.02em",
                      }}
                    >
                      {particularItem.title}
                    </Typography>
                  </ApplyItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            background: COLORS.white,
            width: "100%",
            padding: "40px 0px",
          }}
        >
          <Grid item xs={10} style={{ marginBottom: "40px" }}>
            <Typography
              style={{
                color: COLORS.blueTan,
                fontSize: "24px",
                marginBottom: "20px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              {textContent.upperTitle}
            </Typography>
          </Grid>
          <Grid item xs={10} style={{ width: "100%", marginBottom: "20px" }}>
            <Grid
              container
              direction="row"
              justifyContent="center"
            >
              {items.map((particularItem, index) => (
                <Grid item xs={10} key={`Applyitem_${index}`}>
                  <ApplyItem
                    style={{
                      color: COLORS.blueTan,
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {particularItem.source}
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "24px",
                        textAlign: "center",
                        width: "160px",
                        marginTop: "30px",
                        lineHeight: "30px",
                        letterSpacing: "0.02em",
                      }}
                    >
                      {particularItem.mobiletext}
                    </Typography>
                  </ApplyItem>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};
