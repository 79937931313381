import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import {
  DescriptionHormigonStyled,
  MediaHormigonComponentStyled,
} from "../styles/AplicationsView";

const mediaChildren = ({ src, alt }) => {
  return (
    <MediaHormigonComponentStyled>
      <img
        src={src}
        alt={alt}
      />
    </MediaHormigonComponentStyled>
  );
};

const textBoxChildren = ({
  upperTitle,
  iconUpperTitle,
  title,
  description,
}) => {
  return (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="flex-start"
      style={{ color: COLORS.white }}
    >
      <Grid
        item
        xs={10}
        md={9}
        style={{ display: "flex", flexDirection: "column", gap: 40 }}
      >
        <Typography
          variant="body1"
          style={{
            fontWeight: 300,
            fontSize: "18px",
          }}
        >
          {upperTitle}
          {iconUpperTitle}
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontWeight: 500,
            fontSize: "32px",
            lineHeight: "36px",
            letterSpacing: "1%",
          }}
        >
          {title}
        </Typography>
        <DescriptionHormigonStyled>
          <Typography variant="body1">{description}</Typography>
        </DescriptionHormigonStyled>
      </Grid>
      <Hidden smUp>
        <Grid item xs={1} />
      </Hidden>
    </Grid>
  );
};

export const AppApplyHormigonBody = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
      backgroundColor={COLORS.greenBackground}
    />
  );
};
