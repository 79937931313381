import React, { useContext } from "react";
import { NewsTitle } from "./NewsTitle";
import { NewsBody } from "./NewsBody";
import { LanguageContext } from "../../../contexts/LanguageContext";

export const NewsContainer = () => {
  const { dictionary } = useContext(LanguageContext);
  const { newsContent } = dictionary.sharedContent;

  if (!newsContent) return null;

  const { newsTitle, news, buttons } = newsContent;
  return (
    <>
      <NewsTitle content={newsTitle} />
      <NewsBody content={{ news, buttons }} />
    </>
  );
};
