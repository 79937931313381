import React from "react";
import { Hidden } from "@material-ui/core";
import { NewsBox } from "../../../components/NewsBox";
import {
  ContainerStyled,
  ContainerStyledMobile,
} from "../styles/ProyectStyled";
import Carousel, { CarouselItem } from "../../../components/Carousel/Carousel";

export const ProyectBody = ({ content }) => {
  const { proyectShow } = content;

  return (
    <>
      <Hidden mdDown>
        <ContainerStyled>
          {proyectShow.map((proyect, index) => (
            <NewsBox
              key={`NewsCaja_${index}`}
              title={proyect.title}
              backgroundimg={proyect.backgroundimg}
            />
          ))}
        </ContainerStyled>
      </Hidden>
      <Hidden mdUp>
        <ContainerStyledMobile>
          <Carousel>
            {proyectShow.map((proyect, index) => (
              <CarouselItem key={`ArrayItemsCarousel_${index}`}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <NewsBox
                    title={proyect.title}
                    backgroundimg={proyect.backgroundimg}
                  />
                </div>
              </CarouselItem>
            ))}
          </Carousel>
        </ContainerStyledMobile>
      </Hidden>
    </>
  );
};
