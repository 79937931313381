import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ButtonComponent } from "../../../components/globals/Button";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import {
  UpperTitleImageContainerStyled,
  VideoStyled,
} from "../styles/InformationContainerStyles";
import { TextMobile } from "../styles/TextMobile";

const mediaChildren = ({ iframe }) => <VideoStyled>{iframe}</VideoStyled>;

const textBoxChildren = ({
  logoPUC,
  logoUCR,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  secondaryButtonIconRight,
  linkPrimaryButton,
  linkSecondaryButton,
}) => {
  const colorText = COLORS.blueTan;
  const colorTitle = COLORS.blueTan;
  const marginBottom = 20;

  return (
    <>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
      >
        <Hidden mdDown>
          <UpperTitleImageContainerStyled
            style={{
              alignItems: "flex-start",
              direction: "row",
              marginBottom: "20px",
              justifyContent: "space-evenly",
              marginLeft: "-10%",
            }}
          >
            <img src={logoUCR.src} alt={logoUCR.alt} />
            <img src={logoPUC.src} alt={logoPUC.alt} />
          </UpperTitleImageContainerStyled>
        </Hidden>
        <Hidden mdUp>
          <UpperTitleImageContainerStyled
            style={{
              alignItems: "center",
              direction: "row",
              marginBottom: "20px",
              justifyContent: "space-evenly",
              marginLeft: "1%",
            }}
          >
            <img src={logoUCR.src} alt={logoUCR.alt} style={{ height: 45 }} />
            <img src={logoPUC.src} alt={logoPUC.alt} style={{ height: 45 }} />
          </UpperTitleImageContainerStyled>
        </Hidden>
        <Hidden mdDown>
          <Grid item style={{ marginBottom }}>
            <TextMobile
              style={{
                color: colorTitle,
                marginTop: "10px",
                lineHeight: "36px",
                marginLeft: "-2%",
              }}
            >
              {title}
            </TextMobile>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item style={{ marginBottom }}>
            <TextMobile
              style={{
                color: colorTitle,
                marginTop: "10px",
                lineHeight: "36px",
                marginLeft: "4%",
              }}
            >
              {title}
            </TextMobile>
          </Grid>
        </Hidden>
        <Hidden mdDown>
          <Grid
            item
            xs={12}
            style={{
              marginBottom,
              fontStyle: "normal",
              width: "550px",
              height: "104px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: colorText,
                fontWeight: 300,
                fontSize: 20,
                lineHeight: "26px",
                letterSpacing: "3%",
                marginLeft: "-2%",
              }}
            >
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid item xs={12} style={{ marginLeft: "-1%" }}>
                <a
                  href={linkPrimaryButton}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <ButtonComponent
                    type="primary"
                    text={primaryButtonText}
                    margin={"10px 0px"}
                    backgroundColor={colorTitle}
                  />
                </a>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: "-1%" }}>
                <a
                  href={linkSecondaryButton}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <ButtonComponent
                    type="secondary"
                    text={secondaryButtonText}
                    margin={"10px 0px 40px"}
                    showIconRight
                    iconRight={secondaryButtonIconRight}
                    backgroundColor={colorTitle}
                    colorText={colorTitle}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid
            item
            xs={12}
            style={{
              marginBottom,
              fontStyle: "normal",
              width: "550px",
              height: "104px",
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: colorText,
                fontWeight: 300,
                fontSize: 20,
                lineHeight: "26px",
                letterSpacing: "3%",
                marginLeft: "4%",
              }}
            >
              {description}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ width: "100%" }}>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Grid item xs={12} style={{ marginLeft: "4%" }}>
                <a
                  href={linkPrimaryButton}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <ButtonComponent
                    type="primary"
                    text={primaryButtonText}
                    margin={"10px 0px"}
                    backgroundColor={colorTitle}
                  />
                </a>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: "4%" }}>
                <a
                  href={linkSecondaryButton}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <ButtonComponent
                    type="secondary"
                    text={secondaryButtonText}
                    margin={"10px 0px 40px"}
                    showIconRight
                    iconRight={secondaryButtonIconRight}
                    backgroundColor={colorTitle}
                    colorText={colorTitle}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export const SecondContent = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent)}
      positionText={positionText}
    />
  );
};
