import COLORS from '../../../styles/global/colors';

import { ReactComponent as ArrowCornerBottom } from '../../../constants/assets/shared/arrowCornerBottomWhite.svg';

import { ReactComponent as AsfaltoRosa } from '../../../constants/assets/shared/asfaltoblanco.svg';
import { ReactComponent as CueroAmarillo } from '../../../constants/assets/homeView/apply/cueroblanco3.svg';
import { ReactComponent as GotaVerde } from '../../../constants/assets/homeView/apply/gotablanca.svg';
import { ReactComponent as HormigonBlanco } from '../../../constants/assets/homeView/apply/hormigonBlancoo.svg';
import { ReactComponent as TelaVerde } from '../../../constants/assets/homeView/apply/telablanca2.svg';

const textContent = {
  upperTitle: "Dónde aplicar Photio",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
  secondaryButtonText: "Conoce más detalles",
};

const items = [
  {
    source: <GotaVerde />,
    alt: "pintura",
    title: "Pintura",
  },
  {
    source: <HormigonBlanco />,
    alt: "hormigón",
    title: "Hormigón",
  },
  {
    source: <CueroAmarillo />,
    alt: "cuero",
    title: "Cuero sintético",
  },
  {
    source: <TelaVerde />,
    alt: "tela",
    title: "Tela",
  },
  {
    source: <AsfaltoRosa />,
    alt: "asfalto",
    title: "Asfalto",
  },
];

export const applyContent = {
  textContent,
  items,
};
