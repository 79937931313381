import React, { useContext, useState } from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ReactComponent as ArrowCornerBottom } from "../../constants/assets/shared/arrowCornerBottomBlue.svg";
import {
  PremiosItems,
  PremiosItemsMobile,
} from "../HomeContainers/styles/PremiosContainerStyles";
import COLORS from "../../styles/global/colors";
import { ButtonComponent } from "../../components/globals/Button";
import { orderObjectsArrayByNumber } from "../../utils/arrays";
import { LanguageContext } from "../../contexts/LanguageContext";

export const PremiosContainerMobile = () => {
  const { dictionary } = useContext(LanguageContext);
  const {
    premiosContent
  } = dictionary.sharedContent;
  const [currentArrayIndex, setCurrentArrayIndex] = useState(0);

  if (!premiosContent) return null;

  const { textContent, items } = premiosContent;

  const handleNextArrayClick = () => {
    setCurrentArrayIndex((currentArrayIndex + 1) % Math.ceil(items.length / 2));
  };

  const startItemIndex = currentArrayIndex * 2;
  const endItemIndex = Math.min(startItemIndex + 2, items.length);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      style={{ background: COLORS.white, width: "100%", padding: "40px 0px" }}
    >
      <Hidden mdDown>
        <Grid
          item
          xs={10}
          style={{
            color: COLORS.blueTan,
            height: "78px",
            width: "100%",
            marginLeft: "210px",
          }}
        >
          <Typography style={{ fontSize: "18px" }}>
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          item
          xs={10}
          style={{ color: COLORS.blueTan, height: "78px", width: "100%" }}
        >
          <Typography style={{ fontSize: "18px" }}>
            {textContent.upperTitle}
            {textContent.iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          marginTop: "20px",
        }}
      >
        {orderObjectsArrayByNumber(items, "position")
          .slice(startItemIndex, endItemIndex)
          .map((particularItem) => (
            <Grid
              item
              xs={6}
              md={6}
              key={`Premios_${startItemIndex + particularItem.position}`}
              style={{ width: "80%", height: "60px", margin: "20px" }}
            >
              <a
                href={particularItem.url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <PremiosItems>
                  <img
                    src={particularItem.source}
                    alt={particularItem.alt}
                  ></img>
                </PremiosItems>
                <PremiosItems variant="body1" style={{ color: COLORS.blueTan }}>
                  {particularItem.title}
                </PremiosItems>
                <PremiosItemsMobile
                  variant="body1"
                  style={{ color: COLORS.blueTan }}
                >
                  {particularItem.description}
                </PremiosItemsMobile>
              </a>
            </Grid>
          ))}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "250px",
          marginBottom: "20px",
        }}
      >
        <ButtonComponent
          type="secondary"
          margin="10px"
          colorText={COLORS.blueTan}
          showIconRight
          callToAction={() => {
            handleNextArrayClick(currentArrayIndex);
          }}
        >
          <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
        </ButtonComponent>
      </Grid>
    </Grid>
  );
};
