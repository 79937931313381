import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
  ContainerStyle,
  HeaderStyle,
  BodyStyle,
  ItemStyle,
  HeaderActionsStyle,
} from "./style";
import LogoPhotio from "../../../../constants/assets/shared/menu/LogoPhotio.svg";
import LogoPhotioWhite from "../../../../constants/assets/shared/menu/LogoPhotioWhite.svg";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { LanguageContext } from "../../../../contexts/LanguageContext";

export const DesktopMenuContent = ({ theme = "light" }) => {
  /*   const [showMenu, setShowMenu] = useState(false); */

  const { dictionary } = useContext(LanguageContext);
  const {
    menuDesktopContent: { menuItemsDektop },
  } = dictionary.sharedContent;


  return (
    <ContainerStyle light={theme === "light"}>
      {/* HeaderMenu */}
      <HeaderStyle>
        <img
          src={theme === "light" ? LogoPhotio : LogoPhotioWhite}
          alt="Logo Photio"
          style={{ marginBottom: "10px" }}
        />
        <HeaderActionsStyle>
          <LanguageSelector />
        </HeaderActionsStyle>
      </HeaderStyle>
      {/* BodyMenu */}
      <BodyStyle>
        {menuItemsDektop.map(
          (item, index) =>
            item.available && (
              <ItemStyle light={theme === "light"}>
                <NavLink
                  key={`menuItem_${index}`}
                  to={item.internalLink}
                  activeClassName="selectedItem"
                >
                  <Typography variant="body1">{item.text}</Typography>
                </NavLink>
              </ItemStyle>
            )
        )}
      </BodyStyle>
    </ContainerStyle>
  );
};
