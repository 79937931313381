import React from "react";
import { AppPartnersBody } from "./AppPartnersBody";
import { AppPartnersTitle } from "./AppPartnersTitle";
import { Grid } from "@material-ui/core";

export const AppPartnersContainer = ({ content = undefined }) => {
  if (!content) return null;

  const { titleContent, descriptionContent } = content;
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={10} md={12}>
        <AppPartnersTitle content={titleContent} />
      </Grid>
      <Grid item xs={10} md={12} style={{ padding: "40px 0px" }}>
        <AppPartnersBody content={descriptionContent} />
      </Grid>
    </Grid>
  );
};
