import styled from "styled-components";

const WhatisVideoOneStyled = styled.div`
  display: flex-start;
  justify-content: center;
  direction: column;
  align-items: flex-start;

  p {
    color: ${(props) => props.colorText || "#000"};
    width: 100%;
    word-break: break-word;
    white-space: normal;

    @media (min-width: 960px) {
      word-break: normal;
      width: 500px;
      font-size: 22px;
      margin-top: 5%;
    }

    strong {
      font-weight: 800;
    }
  }
`;

const WhatisVideosOneStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0px 20px;
  width: 100%;


  iframe {
    border-radius: 8px;
    width: 100%;
    @media (min-width: 960px) {
      width: 80%;
    }
  }
`;

export { WhatisVideoOneStyled, WhatisVideosOneStyled };
