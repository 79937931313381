import { Hidden } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { Pagination } from "./Pagination";
import { ContainerStyle } from "./style";
import { TextInformation } from "./TextInformation";
import { LanguageContext } from "../../../contexts/LanguageContext";

export const Slider = ({ theme = "light", secondsSlider = 3 }) => {
  const { dictionary } = useContext(LanguageContext);
  const { carrouselContent } = dictionary.homeViewContent;
  const { carrouselDesktopContent } = carrouselContent;

  const [slideActive, setSlideActive] = useState({
    index: 0,
    data: carrouselDesktopContent[0],
  });
  const [listIndexElement, setListIndexElement] = useState([]);

  const getCountElement = () => {
    let listIndex = [];
    carrouselDesktopContent.forEach((element, index) =>
      listIndex.push(`0${index + 1}`)
    );
    setListIndexElement(listIndex);
  };

  const handleChangeSlide = (index) => {
    setSlideActive({ index, data: carrouselDesktopContent[index] });
  };

  const changeSlider = () => {
    const newIndex = slideActive.index;
    setSlideActive({
      index: newIndex,
      data: carrouselDesktopContent[newIndex],
    });
  };

  useEffect(() => {
    getCountElement();
    changeSlider();
  }, [carrouselDesktopContent]);


  return (
    <ContainerStyle backgroundImage={slideActive.data.backgroundImage}>
      <TextInformation
        title={slideActive.data.title}
        description={slideActive.data.description}
      >
        <Hidden mdUp>
          <Pagination
            handleChangeSlide={handleChangeSlide}
            listIndex={listIndexElement}
            indexActive={slideActive.index}
            theme={theme}
          />
        </Hidden>
      </TextInformation>
      <Hidden smDown>
        <Pagination
          handleChangeSlide={handleChangeSlide}
          listIndex={listIndexElement}
          indexActive={slideActive.index}
          theme={theme}
        />
      </Hidden>
    </ContainerStyle>
  );
};
