import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { WhatisBenefitsItems } from "../styles/WhatisBenefitsContainerStyles";
import COLORS from "../../../styles/global/colors";

export const WhatisBenefits = ({ content }) => {
  const { textContent, items } = content;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ background: COLORS.white }}
    >
      <Grid
        item
        xs={10}
        md={12}
        style={{
          color: COLORS.blueTan,
          width: "100%",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: "20px", marginBottom: 50 }}>
          {textContent.upperTitle}
          {textContent.iconUpperTitle}
        </Typography>
      </Grid>
      <Grid item xs={10} md={10} style={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%" }}
        >
          {items.map((logoItem, index) => (
            <Grid
              item
              xs={12}
              md={4}
              key={`WhatisBenefitsItems_${index}`}
              style={{ width: "100%", margin: '0px 0px 100px' }}
            >
              <WhatisBenefitsItems>
                <img src={logoItem.source} alt="img" />
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "32px",
                    fontWeight: 600,
                    color: COLORS.blueTan,
                    wordBreak: "break-word",
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {logoItem.title}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "24px",
                    fontWeight: 300,
                    marginTop: "15px",
                    color: COLORS.blueTan,
                  }}
                >
                  {logoItem.description}
                </Typography>
              </WhatisBenefitsItems>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
