import styled from "styled-components";

const ContactStyled = styled.div`
    flex-direction: column;
    width: 100%;
    
    

  
  @media (min-width: 960px) {
    width: 500; 
    margin-bottom: 10px; 
    align-items: flex-start; 
    margin-left: 100px;
    
  }

`;

const ContactStyledTitle = styled.div`
 width: 100%;
 margin-left: 30px;
 margin-bottom: 50px;

    


    @media(min-width: 960px) {
        margin-left: 130px;
        width: 100%;

    }


`;


const ContactButonStyled = styled.div`

width: 100%;
margin-top: 20px;
margin-left: 30px;



    @media(min-width: 960px) {
        display: flex; 
        left: 50%; 
        right: 23%; 
        bottom: 0px; 
        height: 10%; 
        display: inline-block; 
        margin-left: 800; 
        font-size: 18px; 
        margin-left: 50%;
    }



`;


export {
    ContactStyled,
    ContactStyledTitle,
    ContactButonStyled,
};