import sumate from "../../../constants/assets/homeView/carrousel/sumate2.jpg";
import background1 from "../../../constants/assets/homeView/carrousel/background1.svg";
import m2 from "../../../constants/assets/homeView/carrousel/Imagen1.png";

import Foto1 from '../../../constants/assets/homeView/carrousel/Foto1.png';
import Foto2 from '../../../constants/assets/homeView/carrousel/Foto2.png';
import Foto3 from '../../../constants/assets/homeView/carrousel/Foto3.png';

const carrouselDesktopContent = [
  {
    backgroundImage: sumate,
    title: "Utilizando nanotecnología simulamos el proceso de fotosíntesis",
  },
  {
    backgroundImage: background1,
    title: "Photio, el único aditivo descontaminante en el mundo certificado y validado",
  },
  {
    backgroundImage: m2,
    title: "Creamos una herramienta para combatir el cambio climático ",
  },
];

const carrouselMobileContentent = [
  {
    backgroundImage: Foto1,
    title: "Utilizando nanotecnología simulamos el proceso de fotosíntesis",
  },
  {
    backgroundImage: Foto2,
    title: "Photio, el único aditivo descontaminante en el mundo certificado y validado",
  },
  {
    backgroundImage: Foto3,
    title: "Creamos una herramienta para combatir el cambio climático ",
  },
];

export const carrouselContent = {
  carrouselDesktopContent,
  carrouselMobileContentent,
}
