import CENTRODEOPERACIONESPRINCIPAL from "../../../constants/assets/projectView/projects/CENTRODEOPERACIONESPRINCIPAL.png";
import METROSALVADORPRINCIPAL from "../../../constants/assets/projectView/projects/METROSALVADORPRINCIPAL.png";
import BUPASPORTPRINCIPAL from "../../../constants/assets/projectView/projects/BUPASPORTPRINCIPAL.png";
import BULLETITPRINCIPAL from "../../../constants/assets/projectView/projects/BULLETITPRINCIPAL.png";
import CANCHASPRINCIPAL from "../../../constants/assets/projectView/projects/CANCHASPRINCIPAL.png";
import ONCOLOGIAPRINCIPAL from "../../../constants/assets/projectView/projects/ONCOLOGIAPRINCIPAL.png";
import VIASSUSTENTABLESPRINCIPAL from "../../../constants/assets/projectView/projects/VIASSUSTENTABLESPRINCIPAL.png";

const proyects = [
  {
    title: "VÍAS SUSTENTABLES - VíasChile",
    description:
      "Proyecto realizado para VíasChile, filial chilena de Abertis, empresa líder a nivel mundial en la gestión de autopistas de peaje e infraestructuras. Gracias a la implementación de Photio, se transformaron 3.200 metros cuadrados de muros laterales de Autopista Central en la Región Metropolitana, en un enorme bosque en medio de la ciudad. Este proyecto contó con la participación de la reconocida muralista chilena, Trinidad Guzmán (@holaleon), quien estuvo a cargo de intervenir junto a otras artistas, una de las vías más transitadas de la capital de Chile. Actualmente, esta intervención artística está ubicada en el muro poniente de la salida a calle Agustinas de Autopista Central.",
    backgroundimg: VIASSUSTENTABLESPRINCIPAL,
  },
  {
    title: "CORREDOR ÁRTISTICO SUSTENTABLE - Metro de Santiago",
    description:
      "Si bien ya se habían desarrollado otros proyectos con Photio en el interior de las estaciones de Metro, esta intervención corresponde a una de las más grandes ejecutadas por dicha institución hasta la fecha. Este mural de piso, realizado por el Cekis, artista nacional radicado en Estados Unidos, y Lira Arte Público, fue ejecutado en la explanada superior de la estación El Salvador, la cual gracias a nuestra tecnología se transformó en un enorme sumidero de contaminación con un potencial descontaminante equivalente a 2.000 árboles maduros ",
    backgroundimg: METROSALVADORPRINCIPAL,
  },
  {
    title: "CENTROS DE SALUD DESCONTAMINANTES - Red de Centros de Salud Bupa",
    description:
      "Junto a la muralista Constanza Larenas (@conilars), se intervinieron más de 1.600 metros cuadrados en las instalaciones de la red de clínicas Bupa. Estas intervenciones poseen un potencial descontaminante equivalente al de más de 3.200 árboles maduros, por lo que en el periodo de un año, la captura debido a la implementación de Photio será de cerca de 370.000 kg de CO2eq. Actualmente Bupa está aplicando Photio en todas las instalaciones de su red, colaborando directamente con los equipos de mantención e infraestructura.",
    backgroundimg: BUPASPORTPRINCIPAL,
  },
  {
    title: "CIRCUITO DE MURALES DESCONTAMINANTES - Bulleit Bourbon",
    description:
      "Mural parte del 'Circuito Abrir Fronteras', realizado por el reconocido artista nacional Rodrigo Estoy. Este fue un proyecto desarrollado en el marco de una colaboración artística entre Lira Arte Público y Bulleit Bourbon, una de las marcas más premiadas de whiskey americano, reconocida por conectar el arte urbano con barrios, comunidades, bares, restaurantes y emprendimientos. El mural se extiende a lo largo de seis pisos, en un área equivalente a 102 m2 intervenidos con Photio. El mural está ubicado en Avenida Salvador con Providencia",
    backgroundimg: BULLETITPRINCIPAL,
  },
  {
    title: "CANCHAS CON TRIPLE IMPACTO - Adidas | Social Impact",
    description:
      "Este proyecto consistió en el embellecimiento de 6 canchas de distintos barrios de la Región Metropolitana, las cuales fueron intervenidas junto a colaboradores de Adidas, destacados deportistas, influencers, vecinos y funcionarios de las distintas Municipalidades. Durante la actividad, los asistentes participaron en la pintura comunitaria de canchas deportivas descontaminantes. Cada cancha genera un impacto medioambiental equivalente al de más de 1.000 árboles maduros, lo que mejorará la calidad del aire que respiran las 6 comunidades beneficiadas por esta iniciativa .",
    backgroundimg: CANCHASPRINCIPAL,
  },
  {
    title: "VOLUNTARIADO HOSPITAL SAN BORJA",
    description:
      "En el año 2021, el Hospital San Borja se quemó y diversas unidades se vieron afectadas, entre ellas el área de Oncología Infantil. Gracias a la invitación de la Fundación Luchadores de Colores, fuimos partícipes de la intervención del Centro de Oncología Infantil del Hospital San Borja. Junto a funcionarios del Hospital y a diversos artistas que apoyaron la iniciativa, pudimos reacondicionar las nuevas instalaciones, desarrollando una implementación masiva de Photio a través de murales que embellecieron este lugar para las niñas y niños que se atienden en él diariamente.",
    backgroundimg: ONCOLOGIAPRINCIPAL,
  },
  {
    title: "VÍAS SUSTENTABLES | CENTRO DE OPERACIONES - VíasChile",
    description:
      "Junto a la artista nacional Trinidad Guzmán (@holaleon), se intervino el Centro de Operaciones de Autopista Central, lugar donde se monitorean las actividades de la autopista día a día, ubicado en la comuna de San Bernardo. Este proyecto impacta directamente de manera positiva la salud de los trabajadores del Centro de Operaciones y a sus comunidades aledañas. El diseño estuvo a cargo de la muralista y su equipo de  ayudantes, el cual se enfocó en mostrar diferentes flores endémicas de Chile, otorgándole un atractivo visual a la fachada del recinto.",
    backgroundimg: CENTRODEOPERACIONESPRINCIPAL,
  },
];

export const projectContent = {
  proyects,
};
