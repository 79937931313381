import youtubeLogo from "../../../constants/assets/shared/footer/youtube.svg";
import twitterLogo from "../../../constants/assets/shared/footer/twitter.svg";
import instagramLogo from "../../../constants/assets/shared/footer/Instaprueba1.svg";
import facebookLogo from "../../../constants/assets/shared/footer/facebook.svg";

const contact = {
  title: "Contáctanos",
  information: [
    {
      title: "Dirección:",
      value: "Avenida del Valle Norte 937, Of 555. Huechuraba",
    },
    // {
    //   title: "Llámanos al:",
    //   value: "+56 9 7663 1959",
    // },
    {
      title: "Escríbenos a:",
      value: "contacto@photio.cl",
    },
  ],
};

const companyInformation = {
  title: "Razón Social: PHOTIO SPA",
  id: "RUT: 77.053.415-1",
  name: "Giro: Empresa de Ingeniería, Tecnología, Exportación y Serv. de Construcción.",
  address: "DIRECCIÓN: Del Valle Norte 937 oficina 555, Huechuraba",
};

const rrss = [
  {
    icon: youtubeLogo,
    link: "https://www.youtube.com/@photio8182",
    alt: "youtubeLogo",
  },
  {
    icon: twitterLogo,
    link: "https://twitter.com/photiocl",
    alt: "twitterLogo",
  },
  {
    icon: instagramLogo,
    link: "https://www.instagram.com/photio.cl",
    alt: "instagramLogo",
  },
  {
    icon: facebookLogo,
    link: "https://www.facebook.com/PhotioCL",
    alt: "facebookLogo",
  },
];

const credits = {
  property: "Sitio propiedad de Photio",
  design: "Diseño por YohTapia",
};

export const footerContent = {
  contact,
  companyInformation,
  rrss,
  credits,
};
