import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import sachet from "../../../constants/assets/homeView/information/Imagen1sachet.png";
import downloadIconGreen from "../../../constants/assets/homeView/information/downloadIconGreen.svg";
import logoUC from "../../../constants/assets/homeView/information/logoUC.png";
import logoUCRiverside from "../../../constants/assets/shared/logoUCRiverside.png";

const firstItem = {
  positionText: "left",
  textContent: {
    title: "The sustainable Photiorevolution",
    description:
      "Photio is a nanotechnological additive that allows any person, company or institution to transform surfaces into air purifiers, and thus collaborate in the fight against climate change. Join us too!",
    upperTitle: "What is Photio?",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    primaryButtonText: "What is Photio?",
  },
  mediaContent: {
    source: sachet,
    alt: "Sachet Photio",
  },
};

const secondItem = {
  positionText: "right",
  textContent: {
    logoPUC: {
      src: logoUC,
      alt: "Logo PUC",
    },
    logoUCR: {
      src: logoUCRiverside,
      alt: "Logo UC Riverside",
    },
    title: "This is chemistry, not magic",
    description:
      "Photio has been validated and certified by universities and laboratories in Chile, USA and Europe. Do you want to know how? Press the buttons below and you can review our validation process and download our Technical Data Sheet and Material Safety Data Sheet.",
    primaryButtonText: "Learn more about our validation",
    linkPrimaryButton: "",
    secondaryButtonText: "Download MSDS & TDS",
    linkSecondaryButton:
      "https://drive.google.com/drive/folders/1wvm_eTVD8-hRBGvqpigfeCD5bmZPVleY?usp=sharing",
    secondaryButtonIconRight: downloadIconGreen,
  },
  mediaContent: {
    iframe: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/8Qm-vFi1GSs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
};

export const informationContent = {
  firstItem,
  secondItem,
};
