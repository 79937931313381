import Grupo1 from "../../../constants/assets/homeView/project/clientes/Grupo1.png";
import Grupo2 from "../../../constants/assets/homeView/project/clientes/Grupo2.png";
import Grupo3 from "../../../constants/assets/homeView/project/clientes/Grupo3.png";
import Principal from '../../../constants/assets/homeView/project/projects/Principal.png';
import Principalcorredor from '../../../constants/assets/homeView/project/projects/Principalcorredor.png';
import Principalbupa from '../../../constants/assets/homeView/project/projects/Principalbupa.png';
import Principalbullet from '../../../constants/assets/homeView/project/projects/Principalbullet.png'

const titleContent = {
  title: "Many have joined the change, and you?",
  logos: [
    {
      src: Grupo1,
      alt: "grupo1Companies",
    },
    {
      src: Grupo2,
      alt: "grupo2Companies",
    },
    {
      src: Grupo3,
      alt: "grupo3Companies",
    },
  ],
};

const bodyContent = {
  proyectShow: [
    {
      title:
        "Sustainable Pathways | Project developed by Photio for VíasChile",
      backgroundimg: Principal,
    },
    {
      title: "Sustainable Artistic Corridor | Project developed by Metro Health",
      backgroundimg: Principalcorredor,
    },
    {
      title: "Centers | Project developed by Photio for BUPA",
      backgroundimg: Principalbupa,
    },
    {
      title:
        "Mural Circuit | Project developed by Lira Arte Público for Bulleit Bourbon",
      backgroundimg: Principalbullet,
    },
  ],
};

const buttonContent = {
  title: 'See More Projects'
}

export const projectContent = {
  titleContent,
  bodyContent,
  buttonContent,
};
