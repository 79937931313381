import { ReactComponent as GotaAmarilla } from "../../../constants/assets/projectView/apply/Pintura.svg";
// import { ReactComponent as Hormigon } from "../../../constants/assets/projectView/apply/IconoTela.svg";
// import { ReactComponent as Asfalto } from "../../../constants/assets/shared/asfaltoblanco.svg";

const apply = {
  textContent: {
    upperTitle: "Photio en este proyecto se aplicó en",
  },
  items: [
    {
      source: <GotaAmarilla />,
      alt: "pintura",
      title: "Pinturas",
      mobiletext: "Pinturas",
    },
    // {
    //   source: <Hormigon />,
    //   alt: "hormigón",
    //   title: "Hormigón",
    //   mobiletext: "Hormigón Asfalto Revestimientos y Telas",
    // },
    // {
    //   source: <Asfalto />,
    //   alt: "asfalto",
    //   title: "Asfalto",
    //   mobiletext: "Hormigón Asfalto Revestimientos y Telas",
    // },
  ],
};

export const applyContent = { apply };
