import React from "react";
import { Indicator } from "./indicator";
import { ContainerPaginationStyled } from "./style";

export const Pagination = ({ handleChangeSlide, listIndex, indexActive, theme }) => {
  return (
    <ContainerPaginationStyled>
      {listIndex.map((value, index) => (
        <Indicator 
          key={`Pagination_${index}`}
          handleChangeSlide={handleChangeSlide}
          value={value}
          index={index}
          active={indexActive === index}
          theme={theme}
        />
      ))}
    </ContainerPaginationStyled>
  );
};
