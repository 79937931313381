import React from "react";
import { Hidden } from "@material-ui/core";
import { Slider } from "../../components/globals/Slider";
import { MobileMenuHome } from "../../components/globals/Menu/MobileMenuHome";
import { DesktopMenuHome } from "../../components/globals/Menu/DesktopMenuHome";
import { SliderMobile } from "../../components/globals/Slider/mobileindex";

export const CarrouselContainer = ({ theme = "light" }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "95vh" }}>
      <Hidden mdUp>
        <div style={{ width: "100%", position: "absolute" }}>
          <MobileMenuHome theme={theme} />
        </div>
        <SliderMobile theme={theme}/>
      </Hidden>
      <Hidden mdDown>
        <div style={{ width: "100%", position: "absolute" }}>
          <DesktopMenuHome theme={theme} />
        </div>
        <Slider theme={theme} />
      </Hidden>
    </div>
  );
};
