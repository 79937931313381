import styled from "styled-components";

const TextMobile = styled.div`
  font-size: 32px;
  font-weight: 500;
  width: 90%;

  @media (min-width: 960px) {
    justify-content: flex-end;
    margin-left: 50px;
    line-height: 36px;
    width: 100%;
  }

  img {
    width: 100%;

    @media (min-width: 960px) {
       width:  100%;
       font-size: 32px;
       font-weight: 500;
       margin-left: 50px;

      }
  }
`;

export {
    TextMobile,
  };
  