import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";
import { ReactComponent as GotaAmarilla } from "../../../constants/assets/applicationView/apply/gotaAmarilla.svg";
import { ReactComponent as Hormigon } from "../../../constants/assets/applicationView/apply/Telafinal.svg";
import Aplicapintura from "../../../constants/assets/applicationView/apply/Aplicapintura1.svg";
import otherApplications from "../../../constants/assets/applicationView/apply/otherApplications.svg";

const textContent = {
  upperTitle: "¿Cómo se aplica?",
  description: "Haz click en una opción y conoce más detalles",
  text: "Haz click y conoce más detalles",
};

const items = [
  {
    source: <GotaAmarilla style={{ height: 100 }} />,
    alt: "pintura",
    title: "Pinturas",
    mobiletext: "Pinturas",
    state: "paint",
  },
  {
    source: <Hormigon style={{ height: 100 }} />,
    alt: "hormigón",
    title: "Otras aplicaciones",
    mobiletext: "Otras aplicaciones",
    state: "block",
  },
];

const paintTutorial = {
  positionText: "left",
  textContent: {
    upperTitle: "Tutorial para pinturas",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    title: "¿Cómo se aplica Photio a las pinturas?",
    step1: "PASO 1 Aplica el contenido del sobre en la pintura",
    step2: "PASO 2 Revuelve enérgicamente por 1 a 3 minutos",
    step3:
      "PASO 3 Observa como Photio desapareció en tu pintura, y aplícala donde quieras",
  },
  mediaContent: {
    title: "Aplica Photio en interiores y exteriores",
    src: Aplicapintura,
    alt: "img",
  },
};

const otherTutorial = {
  positionText: "left",
  textContent: {
    title: "¿Cómo se aplica Photio en hormigón, asfalto u otros materiales?",
    upperTitle: "Tutorial para otras aplicaciones",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    description:
      "De momento esta es nuestra Receta Secreta (como la CocaCola, pero buena para el medioambiente). Sin embargo, si quieres desarrollar una nueva aplicación con nosotros, o tienes en mente algún proyecto con impacto, toma contacto con nuestro equipo enviando un mensaje a contacto@photio.cl",
  },
  mediaContent: {
    src: otherApplications,
    alt: "img",
  },
};

export const applyContent = {
  textContent,
  items,
  paintTutorial,
  otherTutorial,
};
