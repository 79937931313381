import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { ButtonComponent } from "../../../components/globals/Button";
import { InformationComponent } from "../../../components/globals/Information";
import COLORS from "../../../styles/global/colors";
import { ImageStyleds } from "../styles/InformationContainerStyles";

const mediaChildren = ({ source, alt }) => {
  return (
    <ImageStyleds>
      <img src={source} alt={alt} />
    </ImageStyleds>
  );
};

const textBoxChildren = (
  { upperTitle, title, description, primaryButtonText, iconUpperTitle },
  history
) => {
  const color = COLORS.blueTan;

  return (
    <Grid
      container
      justifyContent="center"
      style={{ gap: 20, margin: "auto 0px" }}
    >
      <Grid item xs={9} style={{ marginBottom: 24 }}>
        <Typography variant="body1" style={{ color }}>
          {upperTitle}
          {iconUpperTitle}
        </Typography>
      </Grid>

      <Grid item xs={9}>
        <Typography variant="h1" style={{ color }}>
          {title}
        </Typography>
      </Grid>

      <Grid item xs={9}>
        <Typography variant="body1" style={{ color }}>
          {description}
        </Typography>
      </Grid>

      <Grid item xs={9}>
        <ButtonComponent
          type="primary"
          text={primaryButtonText}
          callToAction={() => {
            history.push("/whatis");
            window.scrollTo(0, 0);
          }}
        />
      </Grid>
    </Grid>
  );
};

export const FirstContent = ({ content }) => {
  const { positionText, textContent, mediaContent } = content;
  const history = useHistory();

  return (
    <InformationComponent
      mediaChildren={mediaChildren(mediaContent)}
      textBoxChildren={textBoxChildren(textContent, history)}
      positionText={positionText}
    />
  );
};
