import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";
import Carousel, { CarouselItem } from "../../../components/Carousel/Carousel";
import {
  ImageStylesCarousel,
  ImageStylesTitle,
} from "../styles/ImageStylesCarousel";

export const ProyectTitle = ({ content }) => {
  const { title, logos } = content;
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      style={{ marginTop: "30px" }}
    >
      <Grid item xs={7}>
        <ImageStylesTitle style={{ color: COLORS.blueTan, marginLeft: "9%" }}>
          {title}
        </ImageStylesTitle>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={4}>
          <Carousel>
            {logos.map((logo, index) => (
              <CarouselItem key={`logoCompanies_${index}`}>
                <img src={logo.src} alt={logo.alt} style={{ width: "80%" }} />
              </CarouselItem>
            ))}
          </Carousel>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <ImageStylesCarousel>
          <Carousel>
            {logos.map((logo, index) => (
              <CarouselItem key={`logoCompanies_${index}`}>
                <img src={logo.src} alt={logo.alt} style={{ width: "80%" }} />
              </CarouselItem>
            ))}
          </Carousel>
        </ImageStylesCarousel>
      </Hidden>
    </Grid>
  );
};
