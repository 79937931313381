import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { MobileMenuContent } from "../components/globals/Menu/MobileMenuContent";
import { DesktopMenuContent } from "../components/globals/Menu/DesktopMenuContent";
import { FooterComponent } from "../components/globals/FooterComponent";
import { PreguntasContainer } from "../containers/HomeContainers/PreguntasFrecuentes/PreguntasContainer";
import { LanguageContext } from "../contexts/LanguageContext";

export const FaqView = () => {
  const theme = "light";
  const { dictionary } = useContext(LanguageContext);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <Hidden mdUp>
          <MobileMenuContent theme={theme} />
        </Hidden>
        <Hidden smDown>
          <DesktopMenuContent theme={theme} />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={12} style={{ width: "100%" }}>
        <PreguntasContainer content={dictionary.faqViewContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
};
