const faq = [
  {
    pregunta: "How does Photio work?",
    respuesta:
      "Photio is an additive that is able to transform the application site into an air purifier, simulating the process of photosynthesis carried out by plants and trees. Using any kind of light as an energy source, we transform polluting gases into substances that no longer pose a danger to people’s health or the environment. Our technology is a blend of nanomaterials that together promote a series of photochemical reactions that are responsible for the capture of polluting gases.",
  },
  // {
  //   pregunta: "How much does the product cost?",
  //   respuesta:
  //     "We are currently marketing the product for companies and public institutions for application in paints. In this case, Photio has two formats. The format for 1 gallon, which has a value of $7,990 + VAT (Chilean pesos), and the format equivalent to 4 gallons, whose value is $39,500 + VAT (Chilean pesos). If you are thinking of a large project, send us an email to get you our price table, where we apply preferential discounts from 80 units.",
  // },
  {
    pregunta: "How and where can I purchase the product?",
    respuesta:
      "To quote and buy our product you must send us an email with the details of your request to the email contacto@photio.cl or fill out the contact form by selecting the corresponding option on the button you need. We ship all over Chile.",
  },
  {
    pregunta: "What format is the product sold in?",
    respuesta:
      "Our technology is marketed in two formats, we have the format to be applied directly into 1 gallon of paint and we have the format to be applied directly into 4 gallon buckets of paint. Our technology does not modify the normal performance of the paint, it only adds the decontaminating property to the product.",
  },
  {
    pregunta: "How to use Photio?",
    respuesta:
      "Open the Photio sachet, pour its contents into a gallon or gallons depending on the selected format, and stir vigorously for 1 to 3 minutes. If you are using a mechanical shaker, do the mixing process for 30 to 60 seconds.",
  },
  {
    pregunta: "Do you provide any document or certification for the application of the technology?",
    respuesta:
      "In the case of projects for companies, public institutions or large-scale implementations, our startup will send you a Traceability and Proper Use Certificate. In this document they will find excellent eco-equivalences in terms of the application, and its impact metrics (kilograms of captured gases or kilograms of compensated equivalent CO2). Depending on the format, Photio yields for 1 gallon or 4 gallons of paint. 1 gallon corresponds to 3.78 liters of paint. For more information on prices, delivery times and other relevant aspects of this topic, please write to us at contacto@photio.cl.",
  },
  {
    pregunta: "Can Photio be applied to any type of paint?",
    respuesta:
      "Yes! To date Photio has been validated in paints such as latex, water enamel and synthetic, oils, lacquers, epoxies, polyurethane base, polyester, textures (martelin, covertine and others) and in different types of industrial coatings.",
  },
  {
    pregunta: "What gases degrade and what about them?",
    respuesta:
      "Our technology degrades pollutant, it does not absorb or store them. This is very important, since the gas treated by the facade of your house will not be fixed to the surface, but will be returned to the environment, but this time as an inert substance. Specifically, our technology promotes the degradation of gases such as: carbon monoxide (CO), carbon oxides (Nox), volatile organic compounds (VOCs), methane (CH4), sulfur dioxide (SO2), ammonia (NH3), bacteria and fungi.",
  },
  {
    pregunta:
      "How long does the decontaminating effect of Photio last? How long do you have to reapply the technology?",
    respuesta:
      "The durability of Photio’s decontaminating effect is directly related to the useful life of the material where it is incorporated. Due to the quality of the paints that are mostly marketed in Latam, the useful life of the technology extends for 3 to 4 years for that application, time in which it is recommended to reapply paint with Photio on the surface. For the product to work optimally, the painted surface must be kept clean and in direct contact with the air.",
  },
  {
    pregunta: "What studies support your technology?",
    respuesta:
      "After almost 3 years of research and development, and thanks to our partnerships with national and international universities and laboratories, we managed to develop tests with which we determined Photio’s decontaminating potential for various gases in different contexts. Initially, we closed a strategic alliance with the Pontifical Catholic University of Chile, with which, through the use of specialized equipment, such as gas chromatographs and photoreactors, we managed to obtain the maximum potential Photio decontamination for gases such as carbon monoxide, methane, ammonia, among others. Currently, we continue to work with them validating all our applications! We then developed a process of technology revalidation at CE-CERT in the United States, one of the most prestigious air quality institutes in the world. With them we were able to validate the impact of Photio again, and we were able to calculate the impact of our technology indoors (yeah! That’s right, Photio does not just apply outdoors). This is in addition to the many revalidations we have made in Europe. On the other hand, we have developed a series of pilots where we have cuantified the impact of Photio in parking lots or directly on murals, through the use of gas sensors, which have also allowed us to verify the indirect impact of Photio for the treatment of particulate material.",
  },
  {
    pregunta: "Do you work with invoice?",
    respuesta:
      "Of course! At the time of purchase we will ask you for your details and your invoice will arrive at your email directly.",
  },
];

export const questionsContent = {
  faq,
};
