import React from "react";
import { Grid } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const InformationComponent = ({
  textBoxChildren,
  mediaChildren,
  positionText = "left",
  backgroundColor = COLORS.white
}) => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    style={{ backgroundColor}}
  >
    <Grid
      item
      xs={12}
      md={6}
      style={{ width: "100%" }}
    >
      {positionText === "left" ? textBoxChildren : mediaChildren}
    </Grid>
    <Grid
      item
      xs={12}
      md={6}
      style={{ width: "100%" }}
    >
      {positionText === "left" ? mediaChildren : textBoxChildren}
    </Grid>
  </Grid>
);
