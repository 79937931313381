import styled from "styled-components";

const ImageStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
  width: 100%;

  @media (min-width: 960px) {
  }

  img {
    width: 100%;

    @media (min-width: 960px) {
      width: 150%;
      margin-top: 20px;
      margin-left: -20%;
    }
  }
`;

const ImageStyleda = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;
  width: 100%;

  @media (min-width: 960px) {
  }

  img {
    width: 100%;

    @media (min-width: 960px) {
      width: 150%;
      margin-top: 20px;
      margin-left: -20%;
    }
  }
`;

const LogosRRSSContactStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    height: 13px;
    margin: 0px 10px;

    @media (min-width: 960px) {
      height: 25px;
      margin: 0px 15px;
    }
  }
`;

const ImageStyleds = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0px;
  height: 100%;

  img {
    width: 100%;
    @media (min-width: 960px) {
      max-height: 500px;
    }
  }
`;

const VideoStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 40px 0px 20px;
  width: 100%;

  @media (min-width: 960px) {
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 50px;
    margin-left: 13%;
    margin-top: 150px;
  }

  iframe {
    width: 80%;

    @media (min-width: 960px) {
      border-radius: 8px;
      height: 300px;
      width: 540px;
    }
  }
`;

const UpperTitleImageContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  margin-top: 10%;

  @media (min-width: 960px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 0px;
  }

  img {
    max-height: 50px;
    @media (min-width: 960px) {
      max-height: 60px;
    }
  }
`;

const TextContainerStyled = styled.div`
  display: flex-start;
  justify-content: center;
  direction: column;
  align-items: flex-start;
  margin: 40px 20px 20px;
  width: 100%;

  p {
    color: ${(props) => props.colorText || "#000"};
    width: 100%;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 3%;
    word-break: break-word;
    white-space: normal;
    font-weight: 300;

    @media (min-width: 960px) {
      word-break: normal;
      width: 500px;
      align-items: center;
      justify-content: center;
      direction: column;
    }

    strong {
      font-weight: 800;
    }
  }
`;

export {
  ImageStyled,
  VideoStyled,
  UpperTitleImageContainerStyled,
  TextContainerStyled,
  ImageStyleds,
  ImageStyleda,
  LogosRRSSContactStyled,
};
