import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import Fotoapp from "../../../constants/assets/applicationView/application/nanofactoria.jpg";

const application = {
  positionText: "left",
  textContent: {
    description: (
      <p>
        <strong>Photio</strong> puede ser{" "}
        <strong>
          aplicado sobre distintos tipos de recubrimientos y superficies
        </strong>{" "}
        sin cambiar ninguna de sus propiedades físicas o químicas. Puedes
        aplicarlo directamente en paredes mediante pintura, mezclarlo en asfalto
        o concreto, e incluso, dosificarlo en procesos productivos para la
        fabricación de telas, zapatillas y cuero sintético.
      </p>
    ),
    description2: (
      <p>
        Pero para hacer esto, debemos realizar ciertos ajustes a nuestra
        formulación para hacerla compatible y eficiente en cada material, por
        este motivo es que nació nuestra NanoFactoría.
      </p>
    ),
    upperTitle: "¿Dónde podemos aplicar Photio?",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
  },
  mediaContent: {
    source: Fotoapp,
    alt: "Foto",
  },
};

export const applicationContent = { application };
