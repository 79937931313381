import styled from "styled-components";
import COLORS from "../../../styles/global/colors";

const ApplyItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    height: 100px;
    @media (min-width: 960px) {
      height: 150px;
    }
  }
  span {
    color: ${COLORS.white};
  }

  p {
    font-weight: 300;
  }
`;

export { ApplyItem };
