import COLORS from "../../../styles/global/colors";
import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import Recurso18 from "../../../constants/assets/whatIsView/benefits/Recurso18.svg";
import Recurso19 from "../../../constants/assets/whatIsView/benefits/Recurso19.svg";
import Recurso20 from "../../../constants/assets/whatIsView/benefits/Recurso20.svg";
import Recurso5 from "../../../constants/assets/whatIsView/benefits/Recurso5.svg";
import Recurso6 from "../../../constants/assets/whatIsView/benefits/Recurso6.svg";

const title = {
  benefits: {
    textContent: {
      upperTitle: "What are its benefits?",
      iconUpperTitle: (
        <ArrowCornerBottom
          style={{ height: 12, marginLeft: 10, color: COLORS.blueTan }}
        />
      ),
      description: (
        <p>
          The benefits of Photio deployment{" "}
          <strong>depend largely on the location of application</strong>, but
          generally they are as follows.
        </p>
      ),
    },
  },
};

const body = {
  benefits: {
    textContent: {
      upperTitle: "Benefits",
      iconUpperTitle: (
        <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
      ),
    },
    items: [
      {
        source: Recurso18,
        alt: "Pure air",
        title: "Pure air",
        description:
          "Breathe air free of pollutants due to high efficiency for degradation of harmful gases.",
      },
      {
        source: Recurso19,
        alt: "No more suspended material",
        title: "No more suspended material",
        description:
          "Decreases the concentration of particulate matter in your home.",
      },
      {
        source: Recurso20,
        alt: "Improve your health",
        title: "Improve your health",
        description:
          "We eliminate fungi and bacteria on application surfaces.",
      },
      {
        source: Recurso5,
        alt: "Sustainability at all costs",
        title: "Sustainability at all costs",
        description:
          "Generate a positive environmental impact by offsetting your carbón footprint.",
      },
      {
        source: Recurso6,
        alt: "Visibility",
        title: "Visibility",
        description:
          "Get environmental seals and certifications for your Company or Project.",
      },
    ],
  },
};

export const benefitsContent = { title, body };
