import React from "react";
import { Grid } from "@mui/material";
import { WhatisDescription } from "./WhatisDescription";
import { WhatsIsCertifications } from "./WhatsIsCertifications";
import { Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const WhatisBodyContainer = ({content = undefined}) => {

  if(!content) return null;

  const { upperTitle, iconUpperTitle } = content.title;
  const colorText = COLORS.blueTan;

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ margin: "30px 0px 60px" }}>
      <Grid item xs={10} md={9} >
        <Typography
          variant="body1"
          style={{
            color: colorText,
          }}
        >
          {upperTitle}
          {iconUpperTitle}
        </Typography>
      </Grid>
      <Grid item xs={10} md={9}>
        <WhatisDescription content={content.description} />
      </Grid>
      <Grid item xs={10} md={9} style={{marginTop: 40}}>
        <WhatsIsCertifications content={content.certifications} />
      </Grid>
    </Grid>
  );
};
