import React from "react";
import PrimaryButton from "./PrimaryButton";
import SecondaryButton from "./SecondaryButton";
import ThirdButton from "./ThirdButton";

export const ButtonComponent = ({
  type = "primary",
  text = null,
  theme = "light",
  callToAction = () => {},
  showIconLeft = false,
  showIconRight = false,
  iconLeft = undefined,
  iconRight = undefined,
  disabled = false,
  margin = undefined,
  backgroundColor = undefined,
  colorText = undefined,
  buttonType = undefined,
}) => {
  switch (type) {
    case "primary":
      return (
        <PrimaryButton
          buttonType={buttonType}
          theme={theme}
          text={text}
          callToAction={callToAction}
          showIconLeft={showIconLeft}
          showIconRight={showIconRight}
          disabled={disabled}
          margin={margin}
          backgroundColor={backgroundColor}
          colorText={colorText}
          iconLeft={iconLeft}
          iconRight={iconRight}
        />
      );
    case "secondary":
      return (
        <SecondaryButton
          buttonType={buttonType}
          theme={theme}
          text={text}
          callToAction={callToAction}
          showIconLeft={showIconLeft}
          showIconRight={showIconRight}
          disabled={disabled}
          margin={margin}
          backgroundColor={backgroundColor}
          colorText={colorText}
          iconLeft={iconLeft}
          iconRight={iconRight}
        />
      );
    case "third":
      return (
        <ThirdButton
          buttonType={buttonType}
          theme={theme}
          text={text}
          callToAction={callToAction}
          showIconLeft={showIconLeft}
          showIconRight={showIconRight}
          disabled={disabled}
          margin={margin}
          colorText={colorText}
          iconLeft={iconLeft}
          iconRight={iconRight}
        />
      );
    default:
      return (
        <PrimaryButton
          buttonType={buttonType}
          text={text}
          callToAction={callToAction}
          showIconLeft={showIconLeft}
          showIconRight={showIconRight}
          disabled={disabled}
        />
      );
  }
};
