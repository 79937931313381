import COLORS from "../../../styles/global/colors";
import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";

const textContent = {
  upperTitle: "Conoce nuestras Redes Sociales",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
};

export const rrssContent = {
  textContent,
};
