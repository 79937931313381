import styled from "styled-components";

const ContainerStyled = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: 960px) {
    width: 100%;
  }
`;

const ContainerStyledMobile = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  @media (min-width: 960px) {
  }
`;

const ContainerStyledApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: strech;

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

export { ContainerStyled, ContainerStyledMobile, ContainerStyledApp };
