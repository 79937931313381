import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";

export const WhatisQuestionBody = ({ content }) => {
  
  const { upperTitle, iconUpperTitle } = content;

  return (
    <>
      <Hidden mdDown>
        <Grid
          style={{
            width: "100%",
            height: "0px",
            margin: "50px",
            color: "#002061",
            marginLeft: "150px",
          }}
        >
          <Typography style={{ fontSize: "20px" }}>
            {upperTitle}
            {iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          style={{
            width: "100%",
            height: "0px",
            margin: "50px",
            color: "#002061",
            marginLeft: "20px",
          }}
        >
          <Typography style={{ fontSize: "20px" }}>
            {upperTitle}
            {iconUpperTitle}
          </Typography>
        </Grid>
      </Hidden>
    </>
  );
};
