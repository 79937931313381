import React from "react";
import { NanoBox } from "../../../components/NanoBox";
import { ContainerStyledApp } from "../styles/ProyectStyled";

export const AppNano = ({ content }) => {
  const { appShow } = content;
  return (
    <ContainerStyledApp>
      {appShow.map((e, index) => (
        <NanoBox
          key={`NanoBox_${index}`}
          title={e.title}
          source={e.source}
          text={e.text}
          description={e.description}
        />
      ))}
    </ContainerStyledApp>
  );
};
