import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { WhatisContainer } from "../containers/HomeContainers/WhatisContainer/WhatisContainer";
import { DesktopMenuContent } from "../components/globals/Menu/DesktopMenuContent";
import { Hidden } from "@material-ui/core";
import { MobileMenuContent } from "../components/globals/Menu/MobileMenuContent";
import { FooterComponent } from "../components/globals/FooterComponent";
import { WhatisBodyContainer } from "../containers/HomeContainers/WhatisBody/WhaisBodyContainer";
import { WhatisQuestionContainer } from "../containers/HomeContainers/WhatisQuestionContainer/WhatisQuestionContainer";
import { WhatisBenefitsTitleContainer } from "../containers/HomeContainers/WhatisBenefitsTitle/WhatisBenefitsTitleContainer";
import { WhatisBenefitsContainer } from "../containers/HomeContainers/WhatisBenefits/WhatisBenefitsContainer";
import { Photom2Container } from "../containers/HomeContainers/Photom2";
import { LanguageContext } from "../contexts/LanguageContext";

export const WhatisView = () => {
  const theme = "light";

  const { dictionary } = useContext(LanguageContext);
  const {
    whatIsContent,
    containerContent,
    benefitsContent: { title: titleContent, body: bodyContent },
  } = dictionary.whatIsViewContent;
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <Hidden mdUp>
          <MobileMenuContent theme={theme} />
        </Hidden>
        <Hidden smDown>
          <DesktopMenuContent theme={theme} />
        </Hidden>
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WhatisBodyContainer content={whatIsContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <WhatisContainer content={containerContent} />
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <WhatisBenefitsTitleContainer content={titleContent} />
      </Grid>
      <Grid item xs={12} md={9} style={{ width: "100%" }}>
        <WhatisBenefitsContainer content={bodyContent} />
      </Grid>
      <Grid item xs={12} md={11} style={{ width: "100%", height: "95hv" }}>
        <Photom2Container />
      </Grid>
      <Grid item xs={12} style={{ width: "100%", marginRight: "50px" }}>
        <WhatisQuestionContainer />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
};
