import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ButtonComponent } from "../../components/globals/Button";
import COLORS from "../../styles/global/colors";
import {
  ContactButonStyled,
  ContactStyled,
  ContactStyledTitle,
} from "./styles/ContactStyled";
import { useHistory } from "react-router-dom";

export const ContactContainer = ({ content = undefined }) => {
  const history = useHistory();

  if (!content) return null;

  const { textContent } = content;

  return (
    <>
      <Hidden mdDown>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          style={{
            background: COLORS.purpleBright,
            width: "100%",
            padding: "40px 0px",
          }}
        >
          <ContactStyledTitle>
            <Grid item xs={10}>
              <Typography
                style={{
                  color: COLORS.white,
                  fontSize: "18px",
                  marginLeft: "-2%",
                }}
              >
                {textContent.upperTitle}
                {textContent.iconUpperTitle}
              </Typography>
            </Grid>
          </ContactStyledTitle>
          <ContactStyled>
            <Grid
              item
              xs={12}
              style={{
                width: 600,
                marginBottom: "10px",
                alignItems: "flex-start",
                marginLeft: "110px",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: "42px",
                  fontWeight: "300",
                  lineHeight: "42px",
                  letterSpacing: "0.01em",
                  color: COLORS.white,
                  marginLeft: "25px",
                }}
              >
                {textContent.description}
              </Typography>
            </Grid>
          </ContactStyled>
          <ContactButonStyled>
            <Grid item xs={12} style={{ marginLeft: "110px" }}>
              <ButtonComponent
                type="primary"
                text={textContent.secondaryButtonText}
                margin={"10px 0px"}
                backgroundColor={COLORS.white}
                colorText={COLORS.purpleBright}
                showIconRight
                callToAction={() => {
                  history.push("/contact");
                  window.scrollTo(0, 0);
                }}
              />
            </Grid>
          </ContactButonStyled>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          style={{
            background: COLORS.purpleBright,
            width: "100%",
            padding: "40px 0px",
          }}
        >
          <ContactStyledTitle>
            <Grid item xs={12}>
              <Typography style={{ color: COLORS.white, fontSize: "18px" }}>
                {textContent.upperTitle}
                {textContent.iconUpperTitle}
              </Typography>
            </Grid>
          </ContactStyledTitle>
          <ContactStyled>
            <Grid
              item
              xs={12}
              style={{
                width: 600,
                marginBottom: "10px",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: "42px",
                  fontWeight: "300",
                  lineHeight: "42px",
                  letterSpacing: "0.01em",
                  color: COLORS.white,
                  margin: "0px 25px",
                }}
              >
                {textContent.description}
              </Typography>
            </Grid>
          </ContactStyled>
          <ContactButonStyled>
            <Grid item xs={12}>
              <ButtonComponent
                type="primary"
                text={textContent.secondaryButtonText}
                margin={"10px 0px"}
                backgroundColor={COLORS.white}
                colorText={COLORS.purpleBright}
                showIconRight
                callToAction={() => {
                  history.push("/contact");
                  window.scrollTo(0, 0);
                }}
              />
            </Grid>
          </ContactButonStyled>
        </Grid>
      </Hidden>
    </>
  );
};
