import sachet from "../../../constants/assets/contactView/SachetFinal.png";
import checkGreen from "../../../constants/assets/contactView/CheckGreen.png";
import errorRed from "../../../constants/assets/contactView/ErrorRed.png";
import Youtubegris from "../../../constants/assets/contactView/Youtubefinal.svg";
import Twittergris from "../../../constants/assets/contactView/Twitterfinal.svg";
import Instagramgris from "../../../constants/assets/contactView/Instagram3.svg";
import Facebookgris from "../../../constants/assets/contactView/Facebookfinal.svg";
import Photiogris from "../../../constants/assets/contactView/Photiofinal.svg";

const contact = {
  positionText: "left",
  textContent: {
    title:
      "Do you want to develop a project with Photio or are you interested in collaborating with us?",
    bottomTitle: "Let’s get together and talk!",
    description:
      "Complete the form with all required information. Our team will answer all your questions and concerns.",
  },
  mediaContent: {
    source: sachet,
    alt: "Sachet Photio",
  },
};

const contactForm = {
  reason: {
    title: "What do you need?",
    options: [
      "I want to use the product in my company",
      "I will make a mural and I need Photio",
      "I need a product presentation meeting",
      "New Applications",
      "I would like to interview them",
      "Another issue",
    ],
    helper: "Elige una opción",
    placeholder: "",
    validators: {
      base: "Seleccione un mótivo",
      require: "Reason is required",
    },
  },
  name: {
    title: "Full name",
    validators: {
      base: "Ingrese su nombre",
      require: "Name is required",
      min: "Enter at least 3 characters",
    },
  },
  email: {
    title: "Email",
    helper: "ej: nombre@gmail.com",
    placeholder: "contacto@photio.com",
    validators: {
      base: "Ingrese su email",
      require: "Email is required",
      email: "Enter a valid email address",
    },
  },
  phone: {
    title: "Phone",
    helper: "Example: +56 (9) 1234-5678",
    placeholder: "56912345678",
    validators: {
      base: "Ingrese su número telefónico",
      require: "Phone number is required",
      max: "Número excede el máximo de carácteres permitidos",
      match: "Please enter a valid phone number",
    },
  },
  text: {
    title: "Mensaje",
    validators: {
      base: "Ingrese su mensaje",
      require: "Message is required",
      min: "Enter at least 10 characters",
    },
  },
  button: {
    valid: "Send",
    sending: "Sending...",
  },
  successView: {
    title: "Your message was successfully sent. We’ll contact you soon.",
    description: "Remember to also check your spam folder.",
    source: checkGreen,
  },
  errorView: {
    title:
      "We are having some difficulties at the moment. Please send your message again later.",
    description: "We apologize for the inconvenience.",
    source: errorRed,
  },
  back: "Return to the home page",
  newContact: "Send another message",
};

const rrss = [
  {
    src: Photiogris,
    alt: "imgLogo",
    url: "",
  },
  {
    url: "https://www.youtube.com/@photio8182",
    src: Youtubegris,
    alt: "youtubeLogo",
  },
  {
    url: "https://twitter.com/photiocl?s=21&t=UR7RmmRJUJZ-xQtr5O-ZGw",
    src: Twittergris,
    alt: "twitterLogo",
  },
  {
    url: "https://www.instagram.com/photio.cl/",
    src: Instagramgris,
    alt: "instagramLogo",
  },
  {
    url: "https://www.facebook.com/PhotioCL/",
    src: Facebookgris,
    alt: "facebookLogo",
  },
];

export const contactContent = { contact, rrss, contactForm };
