import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomBlue.svg";
import sachet from "../../../constants/assets/homeView/information/Imagen1sachet.png";
import downloadIconGreen from "../../../constants/assets/homeView/information/downloadIconGreen.svg";
import logoUC from "../../../constants/assets/homeView/information/logoUC.png";
import logoUCRiverside from "../../../constants/assets/shared/logoUCRiverside.png";

const firstItem = {
  positionText: "left",
  textContent: {
    title: "La Photiorevolución sustentable",
    description:
      "Photio es un aditivo nanotecnológico que permite a cualquier persona, empresa o  institución, transformar superficies en purificadores de aire, y así colaborar en la lucha  contra el cambio climático. ¡Súmate tú también!",
    upperTitle: "Qué es Photio",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    primaryButtonText: "¿Qué es Photio?",
  },
  mediaContent: {
    source: sachet,
    alt: "Sachet Photio",
  },
};

const secondItem = {
  positionText: "right",
  textContent: {
    logoPUC: {
      src: logoUC,
      alt: "Logo PUC",
    },
    logoUCR: {
      src: logoUCRiverside,
      alt: "Logo UC Riverside",
    },
    title: "Esto es química, no magia",
    description:
      "Photio ha sido validado y certificado por universidades y laboratorios en Chile, EE.UU. y Europa. ¿Quieres saber cómo? Presiona los botones ubicados a continuación y podrás revisar nuestro proceso de validación y descargar nuestra Ficha Técnica y Ficha de Seguridad.",
    primaryButtonText: "Conoce nuestro proceso de validación",
    linkPrimaryButton: "",
    secondaryButtonText: "Descargar MSDS y TDS",
    linkSecondaryButton:
      "https://drive.google.com/drive/folders/1wvm_eTVD8-hRBGvqpigfeCD5bmZPVleY?usp=sharing",
    secondaryButtonIconRight: downloadIconGreen,
  },
  mediaContent: {
    iframe: (
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/8Qm-vFi1GSs"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    ),
  },
};

export const informationContent = {
  firstItem,
  secondItem,
};
