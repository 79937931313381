import React from "react";
import { AppBody } from "./AppBody";


export const AppContainer = ({content = undefined}) => {
  if(!content) return null;

  const {application} = content;
  return <AppBody content={application} />
};
