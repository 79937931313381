import React, { useEffect, useState } from "react";
import COLORS from "../../styles/global/colors";
import { ButtonComponent } from "../globals/Button";
/* import { useSwipeable } from "react-swipeable";
 */
import "./CarouselNews.css";

export const CarouselNews = ({ children, width }) => {
  return (
    <div className="carouselNews-item" style={{ width: width}}>
      {children}
    </div>
  );
};

const CarouselMobileNews = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

 /*  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });
 */
  return (
    <div
    /*   {...handlers} */
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="innern"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className="indicatorss">
        <ButtonComponent 
          type='primary'
          margin={'10px 0px'}
          backgroundColor={COLORS.blueTan}
          colorText={COLORS.white}
          showIconLeft
          callToAction={() => {
            updateIndex(activeIndex - 1);
          }}
        >
         
        </ButtonComponent>
        <ButtonComponent
          type='primary'
          margin={'10px 0px'}
          backgroundColor={COLORS.blueTan}
          colorText={COLORS.white}
          showIconRight
          callToAction={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          
        </ButtonComponent>
      </div>
    </div>
  );
};

export default CarouselMobileNews;
