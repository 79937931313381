import React from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import COLORS from '../../../styles/global/colors';

export const NewsTitle = ({content}) => {

  const { textContent } = content;

  return (
    <>
    <Hidden mdDown>
    <Grid container direction="column" justifyContent='center' alignItems="center" style={{ background: COLORS.white, width: '100%', padding: '40px 0px' }}>
      <Grid item xs={10} style={{ color: COLORS.blueTan, height: '78px', width: '100%', marginLeft: '-6%' }}>
        <Typography variant='body1' style={{ fontSize: '18px' }}>
          {textContent.upperTitle}
          {textContent.iconUpperTitle}
        </Typography>
      </Grid>
    </Grid>
    </Hidden>
    <Hidden mdUp>
    <Grid container direction="column" justifyContent='center' alignItems="center" style={{ background: COLORS.white, width: '100%', padding: '40px 0px'}}>
      <Grid item xs={10} style={{ color: COLORS.blueTan, height: '78px', width: '100%' }}>
        <Typography variant='body1' style={{ fontSize: '18px' }}>
          {textContent.upperTitle}
          {textContent.iconUpperTitle}
        </Typography>
      </Grid>
    </Grid>
    </Hidden>
    </>
  )
}
