import styled from "styled-components";
import COLORS from "../../../../styles/global/colors";

const ContainerStyleComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  padding: 32px 0px 16px;
  width: 80%;

  span {
    color: ${(props) => (props.light ? COLORS.blueTan : COLORS.white)};
  }

  svg {
    color: ${(props) => (props.light ? COLORS.white : COLORS.blueTan)};
  }

  img {
    height: 30px;
  }
`;

const HeaderStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 10px;
  padding: 0px 5%;
`;

const HeaderActionsStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ActionStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 13px;

  > span {
    margin-left: 8px;
  }
`;

const BodyStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px 5% 16px;
  border-top: 0.6px solid ${COLORS.greyCool};

  a {
    text-decoration: none;
  }

  span {
    color: ${(props) => (props.light ? COLORS.blueTan : COLORS.blueTan)};
  }
  
  span:active,
  span:focus,
  span:active {
    color:${(props) => (props.disabled ? COLORS.orange : COLORS.orange)}
  
  
  &:hover
    ${(props) => (props.disabled ? "{}" : "{span {font-weight: 700;}}")}
  &:focus 
    ${(props) =>
      props.disabled
        ? "{}"
        : `{span {color: ${COLORS.orange}; font-weight: 700;}}`}
  }
`;

const ItemStyleComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};

  p {
    font-family: Roboto;
    text-transform: uppercase;
    line-height: 16px;
    letter-spacing: 0.2em;
    font-size: 14px;
    text-decoration: none;
    color: ${COLORS.white};
  }

  .selectedItem {
    p {
      color: ${COLORS.orange};
      font-weight: 700;
    }
  }
`;

export {
  ContainerStyleComponent as ContainerStyle,
  HeaderStyleComponent as HeaderStyle,
  HeaderActionsStyleComponent as HeaderActionsStyle,
  ActionStyleComponent as ActionStyle,
  BodyStyleComponent as BodyStyle,
  ItemStyleComponent as ItemStyle,
};
