import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";
import { ReactComponent as GotaAmarilla } from "../../../constants/assets/applicationView/apply/gotaAmarilla.svg";
import { ReactComponent as Hormigon } from "../../../constants/assets/applicationView/apply/Telafinal.svg";
import Aplicapintura from "../../../constants/assets/applicationView/apply/Aplicapintura1.svg";
import otherApplications from "../../../constants/assets/applicationView/apply/otherApplications.svg";

const textContent = {
  upperTitle: "How does it apply?",
  description: "Click on an option and learn more",
  text: "Click on and learn more",
};

const items = [
  {
    source: <GotaAmarilla style={{ height: 100 }} />,
    alt: "Paintings",
    title: "Paintings",
    mobiletext: "Paintings",
    state: "paint",
  },
  {
    source: <Hormigon style={{ height: 100 }} />,
    alt: "hormigón",
    title: "Other Applications",
    mobiletext: "Other Applications",
    state: "block",
  },
];

const paintTutorial = {
  positionText: "left",
  textContent: {
    upperTitle: "Tutorial for paintings",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    title: "How does Photio apply in paintings?",
    step1: "STEP 1 Apply the contents of the envelope to the paint.",
    step2: "STEP 2 Stir vigorously for 1 to 3 minutes.",
    step3:
      "STEP 3 Watch as Photio disappeared in your painting and apply it wherever you like.",
  },
  mediaContent: {
    title: "Apply Photio indoors and outdoors",
    src: Aplicapintura,
    alt: "img",
  },
};

const otherTutorial = {
  positionText: "left",
  textContent: {
    title: "How does Photio apply to concrete, asphalt or other materials?",
    upperTitle: "Tutorial for others aplications",
    iconUpperTitle: (
      <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />
    ),
    description:
      "At the moment this is our Secret Recipe (like the Cola, but good for the environment). However, if you want to develop a new application with us, or have an impact project in mind, contact our team by sending a message to contacto@photio.cl.",
  },
  mediaContent: {
    src: otherApplications,
    alt: "img",
  },
};

export const applyContent = {
  textContent,
  items,
  paintTutorial,
  otherTutorial,
};
