import { ReactComponent as ArrowCornerBottom } from "../../../constants/assets/shared/arrowCornerBottomWhite.svg";

const textContent = {
  upperTitle: "Contact Us",
  iconUpperTitle: <ArrowCornerBottom style={{ height: 12, marginLeft: 10 }} />,
  secondaryButtonText: "Go to contact form",
  description:
    "Complete our form to develop a joint project or explore new applications of our product.",
};

export const contactContent = {
  textContent,
};
