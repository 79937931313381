import COLORS from '../../../styles/global/colors';
import { ReactComponent as ArrowCornerBottom } from '../../../constants/assets/shared/arrowCornerBottomBlue.svg';

const textContent = {
  upperTitle: "What are you looking for?",
  iconUpperTitle: (
    <ArrowCornerBottom
      style={{ height: 12, marginLeft: 10, color: COLORS.white }}
    />
  ),
};

const buttonContent = {
  textButton: "Show more questions",
}

const inputSearch = 'What do you need?';

export const bodyContent = {
  textContent,
  buttonContent,
  inputSearch,
};

