import styled from "styled-components";
import COLORS from "../../../styles/global/colors";


const PremiosItems = styled.div`
    margin-right: 20px;
    width: 170px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    font-size: 20px;
    font-weight: 200;
    text-align: center;

  img {
    height: 100px;
    margin-bottom: 25px;
  }
  span {
    color: ${COLORS.blueTan};
    text-align: center;
    justify-content: center;
    width: 249px;
    height: 204px;
  }

  @media (min-width: 960px) {
    margin-right: 20px;
    width: 130px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    font-weight: 200;
    text-align: center;
    margin-top: 40%;
    margin-left: '30%'

  }

`;

const PremiosItemss = styled.div`
    width: 150px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    font-size: 18px;
    font-weight: 200;
    text-align: center;
    margin-left: 10px;


    

  img {
    height: 100%;
    width: 100%
  }
  span {
    color: ${COLORS.blueTan};
    text-align: center;
    justify-content: center;
    width: 249px;
    height: 204px;
  }

  @media (min-width: 960px) {
    width: 150px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    font-weight: 200;
    text-align: center;
    margin-top: -3%;
    margin-left: -16%;

  }

`;

const PremiosItemsMobile = styled.div`
    width: 150px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
    margin-left: 10px;


    

  img {
    height: 100%;
    width: 100%
  }
  span {
    color: ${COLORS.blueTan};
    text-align: center;
    justify-content: center;
    width: 249px;
    height: 204px;
  }

  @media (min-width: 960px) {
    width: 150px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    font-size: 24px;
    font-weight: 200;
    text-align: center;
    margin-top: 5%;
    margin-left: -10px;

  }

`;


export {
  PremiosItems,
  PremiosItemss,
  PremiosItemsMobile
};
