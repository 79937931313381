import React from "react";
import { ProyectDetailsFunction } from "./ProyectDetailsFunction";

export const ProyectDetailsContainer = ({ content = undefined, buttonContent }) => {
  if (!content) return null;

  const { proyects } = content;

  return <ProyectDetailsFunction noticias={proyects} buttonContent={buttonContent} />;
};
