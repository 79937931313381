import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { CarrouselContainer } from "../containers/HomeContainers/CarrouselContainer";
import { InformationContainer } from "../containers/HomeContainers/InformationContainer/InformationContainer";
import { ApplyContainer } from "../containers/HomeContainers/ApplyContainer";
import { ProyectContainer } from "../containers/HomeContainers/ProyectContainer/ProyectContainer";
import { PremiosContainer } from "../containers/HomeContainers/PremiosContainer";
import { NewsContainer } from "../containers/HomeContainers/NewsContainer/NewsContainer";
import { ContactContainer } from "../containers/HomeContainers/ContactContainer";
import { Photom2Container } from "../containers/HomeContainers/Photom2";
import { FooterComponent } from "../components/globals/FooterComponent";
import { ProyectosButton } from "../containers/HomeContainers/ProyectContainer/ProyectButton";
import { PremiosContainerMobile } from "../containers/HomeContainers/PremiosMobile";
import { LanguageContext } from "../contexts/LanguageContext";

export const HomeView = () => {
  const { dictionary } = useContext(LanguageContext);
  const { informationContent, applyContent, projectContent, contactContent } =
    dictionary.homeViewContent;
  const theme = "dark";

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} style={{ width: "100%", height: "95vh" }}>
        <CarrouselContainer theme={theme} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%", height: "100hv" }}>
        <Photom2Container />
      </Grid>
      <Grid item xs={12} md={12} style={{ width: "100%" }}>
        <InformationContainer content={informationContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <ApplyContainer content={applyContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%", background: "#EEEDEE" }}>
        <ProyectContainer content={projectContent} />
        <ProyectosButton content={projectContent.buttonContent} />
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12} md={12} style={{ width: "100%" }}>
          <PremiosContainerMobile />
        </Grid>
      </Hidden>
      <Hidden mdDown>
        <Grid item xs={12} md={12} style={{ width: "100%" }}>
          <PremiosContainer />
        </Grid>
      </Hidden>
      <Grid item xs={12} style={{ width: "100%" }}>
        <NewsContainer />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <ContactContainer content={contactContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
};
