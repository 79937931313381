import React from "react";
import COLORS from "../../../styles/global/colors";
import {
  DescriptionPartnersStyled,
  PartnersStyled,
  TitlePartnersStyled,
} from "../styles/AplicationsView";

export const AppPartnersTitle = ({ content }) => {
  const { title, description } = content;

  return (
    <PartnersStyled style={{ color: COLORS.blueTan }}>
      <TitlePartnersStyled>{title}</TitlePartnersStyled>
      <DescriptionPartnersStyled>{description}</DescriptionPartnersStyled>
    </PartnersStyled>
  );
};
