import React, { useContext } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { FooterComponent } from "../components/globals/FooterComponent";
import { DesktopMenuContent } from "../components/globals/Menu/DesktopMenuContent";
import { MobileMenuContent } from "../components/globals/Menu/MobileMenuContent";
import { PhotioApplyContainer } from "../containers/HomeContainers/PhotioApply/PhotioApplyContainer";
import { ProyectDetailsContainer } from "../containers/HomeContainers/Proyect/ProyectDetailsContainer";
import { ProyectDetailsTitle } from "../containers/HomeContainers/Proyect/ProyectDetailsTitle";
import { Rrss } from "../components/RRSS";
import { LanguageContext } from "../contexts/LanguageContext";

export const ProyectView = () => {
  const theme = "light";
  const { dictionary } = useContext(LanguageContext);
  const { titleDetailsContent, projectContent, applyContent } =
    dictionary.projectViewContent;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item xs={12} style={{ width: "100%" }}>
        <Hidden mdUp>
          <MobileMenuContent theme={theme} />
        </Hidden>
        <Hidden smDown>
          <DesktopMenuContent theme={theme} />
        </Hidden>
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <ProyectDetailsTitle content={titleDetailsContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <ProyectDetailsContainer content={projectContent} buttonContent={titleDetailsContent.buttonContent} />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <PhotioApplyContainer content={applyContent} />
      </Grid>
      <Grid item xs={12} md={12} style={{ width: "100%" }}>
        <Rrss />
      </Grid>
      <Grid item xs={12} style={{ width: "100%" }}>
        <FooterComponent />
      </Grid>
    </Grid>
  );
};
