import React from "react";
import { Grid, Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const ProyectDetailsTitle = ({ content = undefined }) => {
  if (!content) return null;

  const { textContent } = content;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ background: COLORS.white, width: "100%", padding: "40px 0px" }}
    >
      <Grid
        item
        xs={10}
        md={9}
        style={{
          color: COLORS.blueTan,
          width: "100%",
        }}
      >
        <Typography variant="body1" style={{ fontSize: "18px" }}>
          {textContent.upperTitle}
          {textContent.iconUpperTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};
