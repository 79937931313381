import React from "react";
import { ContainerIndicatorStyled, ValueStyled } from "./style";

export const Indicator = ({
  handleChangeSlide,
  value,
  index,
  active,
  theme,
}) => {
  return (
    <ContainerIndicatorStyled light={theme === "light"} active={active}>
      <ValueStyled
        light={theme === "light"}
        onClick={() => handleChangeSlide(index)}
        theme={theme}
      >
        {value}
      </ValueStyled>
    </ContainerIndicatorStyled>
  );
};
