import styled from "styled-components";

const WhatisText = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 3%;
`;

const WhatisTextStyled = styled.div`
  width: 100%;
  height: 200px;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 3%;

  @media (min-width: 960px) {
    margin-top: 50px;
    width: 100%;
    margin-bottom: 50px;
  }
`;

export { WhatisText, WhatisTextStyled };
