import styled from "styled-components";

const WhatisButon = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  flex-direction: column;

  @media (min-width: 960px) {
    gap: 30px;
    margin-top: 0px;
    flex-direction: row;
  }

  button{
    width: 100%;
  }
`;

export { WhatisButon };
