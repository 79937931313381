import React from "react";
import { Grid, Hidden, Typography } from "@material-ui/core";
import COLORS from "../../../styles/global/colors";

export const WhatisBenefitsTitle = ({ content }) => {
  const {textContent: {upperTitle, iconUpperTitle, description}} = content;
  const colorText = COLORS.blueTan;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ margin: "40px 0px 20px" }}
    >
      <Grid item xs={10} md={12} style={{ width: "100%" }}>
        <Typography
          variant="body1"
          style={{ color: colorText, marginBottom: "20px" }}
        >
          {upperTitle}
          {iconUpperTitle}
        </Typography>
      </Grid>

      <Grid
        item
        xs={10}
        md={4}
        style={{
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: colorText,
            fontWeight: 300,
            fontSize: "32px",
            lineHeight: "32px",
            letterSpacing: "1px",
          }}
        >
          {description}
        </Typography>
      </Grid>
      <Hidden mdDown>
        <Grid item md={8} />
      </Hidden>
    </Grid>
  );
};
