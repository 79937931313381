import React, { useContext } from "react";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { ButtonStyle, ButtonTitleStyle, ContainerStyle } from "./styles";

export default function LanguageSelector({ home = false }) {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = (value) => {
    userLanguageChange(value);
  };

  return (
    <ContainerStyle home={home}>
      <ButtonStyle onClick={(e) => handleLanguageChange("es")}>
        <ButtonTitleStyle home={home} selected={userLanguage === "es"}>
          ESP
        </ButtonTitleStyle>
      </ButtonStyle>
      |
      <ButtonStyle onClick={(e) => handleLanguageChange("en")}>
        <ButtonTitleStyle home={home} selected={userLanguage === "en"}>
          ING
        </ButtonTitleStyle>
      </ButtonStyle>
    </ContainerStyle>
  );
}
